import {
    IonContent, IonLabel,
    IonLoading,
    IonPage,
} from '@ionic/react';

import React, { useState} from "react";
import {add, star, logIn} from "ionicons/icons";
import {Link} from 'react-router-dom';
import {registerUser,toast} from "../../services/firebase-functions";
import TextInput from "../../components/ControllerInput/TextInput";
import InputButton from "../../components/ControllerInput/InputButton";
import AuthenticationHeader from "../../components/AuthenticationHeader";

const AuthRegisterPage: React.FC = () => {
    const [busy,setBusy] = useState<boolean>(false);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [fullName, setFullName] = useState('');
    const [phone, setPhone] = useState('');
    const [doneRegister,setDoneRegister] =useState(false)

    let props={
        fullName: "GUEST USER",
        moduleTitle:"ACTIVE8 Register",
        openMenu: ()=>alert("openMenu"),
        openNotification:()=>alert("openNotification"),
        showLogout:false,
    };


    const submitRegister = async () => {
        setBusy(true);
        if (password !== confirmPassword) {
            setBusy(false);
            return toast('Passwords do not match!')
        }
        if (password.trim() === '' || username.trim() === '' ||
            phone.trim() === '' || fullName.trim() === '') {
            setBusy(false);
            return toast("Veuillez remplir les champs obligatoires!")
        }

        let userName =username.toLowerCase()

        const res = await registerUser(userName, password, fullName, phone)
        if(res){
            await toast('Vous vous êtes enregsitré avec succès!');
            setDoneRegister(true)

        }else{

        }
        setBusy(false);
    }
    // @ts-ignore
    return (
        <IonPage>
            <AuthenticationHeader/>

        <IonLoading message={"Veuillez patienter s'il vous plaît..."} duration={0} isOpen={busy}/>

    {doneRegister ?
        <IonContent fullscreen className={"ion-padding"}>
        <IonLabel style={styles.titleComponent}>
            Enregistrez vous
    </IonLabel>
    <p style={{textAlign: "center"}} className={"ion-padding"}>
        Thank you your registration is complete click on the link below to login
    <Link to={"/auth/login"} style={{marginLeft: 10}}>Login</Link>
    </p>
    </IonContent> :

    <IonContent fullscreen className={"ion-padding"}>
    <IonLabel style={styles.titleComponent}>
        Enregistrez vous
    </IonLabel>
    <TextInput
        placeholder={"Adresse e-mail?"}
        setValue={setUsername}
        typeInput={"text"}
        />
        <TextInput
        placeholder={"Mot de passe?"}
        setValue={setPassword}
        typeInput={"password"}
        />
        <TextInput
        placeholder={"Confirmer le mot de passe?"}
        setValue={setConfirmPassword}
        typeInput={"password"}
        />
        <TextInput
        placeholder={"Nom & Postnom?"}
        setValue={setFullName}
        typeInput={"text"}
        />
        <TextInput
        placeholder={"Téléphone?"}
        setValue={setPhone}
        typeInput={"text"}
        />
        <InputButton
        name={"Enregistrez vous"}
        textColor={"secondary"}
        backgroundColor={"#3880ff"}
        btnOnClick={submitRegister}
        borderRadius={20}
        expand={"full"}
        icon={logIn}
        iconSlot={"start"}
        />
        <p style={{textAlign: "center"}} className={"ion-padding"}>
        Vous avez déjà un compte?
        <Link to={"/auth/login"} style={{marginLeft: 10}}>Se connecter</Link>
    </p>

    </IonContent>
    }
    </IonPage>
);
};

export default AuthRegisterPage;

const styles={
    titleComponent: {
        color: "#3880ff",
        fontSize: 18,
        fontWeight: "bold",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        marginTop: 10,
        marginBottom:10,
    }

}

