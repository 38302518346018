import {ConvertStringToBase64, escapeEmailAddress} from "./general-functions";
import firebase from "../configures/firebase-init";
import {AttendantEntry, WorkerCheckinEntry, WorkerHandleDataEntry} from "../../functions/src/services/types";
import moment from "moment";
import {QueueInvitation} from "../mocks/types";


export const RequestQueueFieldData = async (invite: QueueInvitation, fn: any) => {
    console.log("RequestQueueFieldData F! invite > ", invite)
    let checkinObj: QueueInvitation = invite;
    /**
     * let request fetch available data
     */
    let tableQueueQuery = "CcQueueFieldData";
    let refLead = await firebase.database().ref(`${tableQueueQuery}/${checkinObj.Queue}`).limitToFirst(1).get();
    let keyExist = refLead.exists()
    if (!keyExist) {
        return null
    }
    let info = refLead.val();
    let dataRecord = info[Object.keys(info)[0]];
    let innerRef = Object.keys(info)[0];

    let infoIn: WorkerHandleDataEntry = {
        Channel: checkinObj.Category,
        CreatedDate: moment().format("YYYY-MM-DD"),
        CreateTime: moment().format("HH:mm:SS"),
        Data: dataRecord,
        ExtRef: innerRef,
        InRef: innerRef,
        QueueRef: checkinObj.Queue,
        Username: checkinObj.Username,
    }

    /**
     * let save our request record as used handle
     */
    await saveRecordWorkerHandleData(infoIn);

    /**
     * let remove now this request from queue
     */
    await removeFromQueueField(checkinObj.Queue, innerRef);

    /**
     * let make our history fetching
     */
    await makeRequestQueueFieldHistoryStore(infoIn)

    /**
     * let make entry request history into realtime
     */
    await makeRequestQueueFieldHistoryRealtime(infoIn)
    /**
     * let make entry request history into realtime daily requested
     */
    await makeRequestQueueFieldDailyRealtime(infoIn)
    /**
     * let notify our queue that the user take a lead
     */
    await makeRequestQueueFieldTakenRealtime(infoIn)


    console.log("HHHHHH&--> ", refLead);
    console.log("RequestQueueFieldData F!", 4, refLead.val())

    fn(refLead)

    return refLead



}

export const makeRequestQueueFieldDailyRealtime = async (infoIn: WorkerHandleDataEntry) => {
    let entity = "CcWorkerHandleFieldDataRequestedDaily";
    let username = escapeEmailAddress(infoIn.Username);
    let date = moment().format("YYYY-MM-DD");
    let tot = 1;
    let path = `${entity}/${username}/${date}`;
    const res = await firebase.database().ref(path);
    //todo increment
    res.once('value', function (dashboardSnapshot) {
        if (dashboardSnapshot.val() !== null) {
            let record = dashboardSnapshot.val();
            // @ts-ignore
            res.update({
                ...record,
                Total: record.Total + 1,
                LastUpdatedTime: moment().format("HH:mm:SS")
            });
        } else {
            res.set({
                Username: infoIn.Username,
                Total: tot,
                Date: date,
                LastUpdatedTime: moment().format("HH:mm:SS"),
            })
        }
    });
}
export const makeRequestQueueFieldHistoryRealtime = async (infoIn: WorkerHandleDataEntry) => {
    let entity = "CcWorkerHandleFieldDataRequested";
    let username = escapeEmailAddress(infoIn.Username);
    let queueRef = infoIn.QueueRef;
    let path = `${entity}/${username}/${queueRef}`;
    const res = await firebase.database().ref(path);
    res.once('value', function (dashboardSnapshot) {
        console.log(" makeRequestQueueFieldHistoryRealtime ZZZZZZZ---> dashboardSnapshot:  ", dashboardSnapshot.val())
        if (dashboardSnapshot.val() !== null) {
            let record = dashboardSnapshot.val();
            // @ts-ignore
            res.update({
                ...record,
                Total: record.Total + 1,
                LastUpdatedDate: moment().format("YYYY-MM-DD"),
                LastUpdatedTime: moment().format("HH:mm:SS"),
            });
        } else {
            res.set({
                Username: infoIn.Username,
                QueueRef: infoIn.QueueRef,
                Total: 1,
                LastUpdatedDate: moment().format("YYYY-MM-DD"),
                LastUpdatedTime: moment().format("HH:mm:SS"),
            })
        }
    });
}
export const makeRequestQueueFieldTakenRealtime = async (infoIn: WorkerHandleDataEntry) => {
    let entity = "CcQueueFieldSetting";
    let queueRef = infoIn.QueueRef;
    let path = `${entity}/${queueRef}`;
    const res = await firebase.database().ref(path);
    res.once('value', async function (dashboardSnapshot) {
        let record = dashboardSnapshot.val();

        if (record !== null) {

            // @ts-ignore
            let newRecord = {
                ...record,
                Taken: parseInt(record.Taken) + 1,
                LastUpdatedTakenDate: moment().format("YYYY-MM-DD"),
                LastUpdatedTakenTime: moment().format("HH:mm:SS"),
                LastUpdatedUser: infoIn.Username
            };
            await firebase.database().ref(entity).child(queueRef).update(newRecord)

            console.log("zzzzx---> ", record, " > ", newRecord)
        }
    });
}
//
export const makeRequestQueueFieldHistoryStore = async (infoIn: WorkerHandleDataEntry) => {
    await firebase.firestore()
        .collection("CcWorkerHandleFieldData")
        .add(infoIn)
}
const removeFromQueueField = async (queueRef: string, innerRef: string) => {
    await firebase.database().ref(`CcQueueFieldData/${queueRef}/${innerRef}`).remove();
}
const saveRecordWorkerHandleData = async (infoIn: WorkerHandleDataEntry) => {
    let usernameRef = escapeEmailAddress(infoIn.Username);
    let entity = "CcWorkerHandleFieldData";

    console.log("CcWorkerHandleFieldData infoIn: ZZZZZZZ---> ", infoIn);

    await firebase.database()
        .ref(entity)
        .child(usernameRef)
        .set(infoIn).then((snpashot) => {
            console.log("CcWorkerHandleFieldData Saved data: ZZZZZZZ---> ", snpashot);
            return {snpashot, error: null}
        }).catch((err) => {
            console.log("CcWorkerHandleFieldData Error saving: ZZZZZZZ---> ", err)
            return {snpashot: null, error: err}
        })

}