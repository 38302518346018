import {WorkerHandleDataEntry} from "../../functions/src/services/types";
import {saveDataIntoOneRef, saveDataIntoTwoRef} from "./submit-common";
import {updateEntityDatabase} from "./general-functions";
import firebase from "../configures/firebase-init";
import {AttendantOriginEntry, RecycleQueueHistory} from "../mocks/types";
import moment from "moment";


export const RecycleQueue=async (queueRef:string, leadRef:string, category:string, username:string,
                          org:string, agency:string, data:AttendantOriginEntry)=>{


    let table= "CcQueueFieldData";
    if(category==="pipeline"){
        table= "CcQueuePipelineData";
    }
    await saveDataIntoTwoRef(table,queueRef,leadRef,data);
    /**
     * let make our report for total on the queue
     */
    let total:number=1;
     await firebase.database()
        .ref(`CcQueueFieldSetting/${queueRef}`)
        .once("value",snapshot => {
            const infos = snapshot.val()
            if(infos!==null){
                total = parseInt(infos.Total)+1
            }
        }).then(r=>{});
    await updateEntityDatabase("CcQueueFieldSetting", queueRef, {Total :total} )

    /**
     * recycle queue history
     */
    let recycleInfo:RecycleQueueHistory={
        Organization:org,
        Agency:agency,
        LeadRef:leadRef,
        QueueRef: queueRef,
        Category:category,
        Total:1,
        Date:moment().format("YYYY-MM-DD"),
        Time:moment().format("HH:mm:SS"),
        Username:username
    }
    await firebase.database()
        .ref(`CcQueueRecycle/${queueRef}`)
        .once("value",snapshot => {
            const infos = snapshot.val()
            if(infos!==null){
                recycleInfo.Total = parseInt(infos.Total)+1
            }
        }).then(r=>{});
    await saveDataIntoOneRef(`CcQueueRecycle`,queueRef,recycleInfo);

    /**
     * let now save our data history into firestore
     */
    await firebase
        .firestore()
        .collection("CcQueueRecycle")
        .add(recycleInfo);

}