import React, {useEffect, useState} from "react";
import {IonIcon, IonLabel, IonList, IonLoading} from "@ionic/react";
import {
    buildAddButton,
    buildAddInput,
    buildAddInputSelect,
    findDisplayKeyInArray,
    findUserLanguage,
    FormatCodeUpCase,
    getSelectionOptions,
    SubmitFormToFirebase,
    SyncData
} from "../../services/general-functions";
import {addCircle, closeCircleSharp} from 'ionicons/icons';
import {PipelineSettings, propsColumns, SelectOption} from "../../mocks/types";
import {InfoLang, menus as ListMenus} from "../../mocks/language";
import {DefaultFormInputFields, GetInputData, OnChangeInput} from "../../services/form-inputs";
import moment from "moment";
import {QueueBatchCreated} from "../../services/queue-functions";
import TableDataView from "../../components/common/TableDataView";

const QueuePipelinePage: React.FC = () => {
    const [currentLanguage, setCurrentLanguage] = useState("en")
    const [busy, setBusy] = useState<boolean>(false);
    const [IsEdit, setIsEdit] = useState<boolean>(false);
    const [isSync, setIsSync] = useState<boolean>(false);
    const [showList, setShowList] = useState(true);
    const [data, setData] = useState([]);
    const [dataOrganization, setDataOrganization] = useState([]);
    const [dataAgency, setDataAgency] = useState([]);
    const [DataTreeAll, setDataTreeAll] = useState<PipelineSettings[]>([])

    const [inputData, setInputData] = useState<object>({})

    const [InputName, setInputName] = useState("")
    const [InputOrganization, setInputOrganization] = useState("");
    const [InputAgency, setInputAgency] = useState("");
    const [InputPipeline, setInputPipeline] = useState("");
    const [InputPhase, setInputPhase] = useState("");
    const [InputRealtime, setInputRealtime] = useState("");
    const [InputHistory, setInputHistory] = useState("");

    //findUserLanguage(setCurrentLanguage, null);
    let info =ListMenus["fr"]// InfoLang(currentLanguage)
    const menus = info.invitation.all;

    const entityNameFirebase = "CcQueuePipelineSetting";
    const title = menus.pageTitle;
    const titleList = menus.tableListTitle;
    const titleForm = menus.submitNewRecordText;

    const onChangeInput = (value: any, key: string) => {
        OnChangeInput(value, key, inputData, setInputData)
    }

    const getInputData = (key: string) => {
        return GetInputData(key, inputData)
    }
    const cleanInputs = () => {
        setBusy(false)
        setInputData({})
        setShowList(true)
    }
    const getSelectedInput = (key: string): string => {
        let value: string = "";
        // @ts-ignore
        if (typeof inputData[key] !== "undefined") {
            // @ts-ignore
            value = inputData[key].toString()
        }
        return value;
    }


    const getPipeOptionFromSelectedOrganization = (): SelectOption[] => {
        let ls: SelectOption[] = [];
        let org = InputOrganization;
        let agency = InputAgency;
        console.log("getPipeOptionFromSelectedOrganization > ", org, " > ", agency, " > ", InputOrganization)
        for (let i in DataTreeAll) {
            let row = DataTreeAll[i];
            if (row.Organization !== org) {
                continue
            }

            if (row.Agency !== agency) {
                continue
            }

            ls.push({
                key: row.Ref,
                val: row.Name
            })
        }

        console.log("F1 > ", ls)
        return ls
    }

    const getPhaseOptionFromSelectedPipe = (): SelectOption[] => {
        let ls: SelectOption[] = [];
        for (let i in DataTreeAll) {
            let row = DataTreeAll[i];
            if (row.Organization !== InputOrganization) {
                continue
            }
            if (row.Agency !== InputAgency) {
                continue
            }

            for (let y in row.Phases) {
                let phase = row.Phases[y];
                ls.push({
                    key: phase.Ref,
                    val: phase.Name
                })
            }
        }
        return ls
    }

    let organizationOption: SelectOption[] = getSelectionOptions(dataOrganization, "Code", "Name");
    let agencyOption: SelectOption[] = getSelectionOptions(dataAgency, "Code", "Name");
    let pipeOption: SelectOption[] = getPipeOptionFromSelectedOrganization()
    let phaseOption: SelectOption[] = getPhaseOptionFromSelectedPipe();

    let yesNoOption: SelectOption[] = [{key: "yes", val: "Yes"}, {key: "No", val: "No"}]

    let columns: propsColumns[];
    columns = [
        DefaultFormInputFields("#", onChangeInput, getInputData, false, true, "text",
            [], "Action", ""),
        DefaultFormInputFields(menus.fieldNameLabel, onChangeInput, getInputData, true, false,
            "text", [], "Name", menus.fieldNameErr),
        DefaultFormInputFields(menus.fieldOrganizationLabel, onChangeInput, getInputData, true, false,
            "option", organizationOption, "Organization", menus.fieldOrganizationError),
        DefaultFormInputFields(menus.fieldAgencyLabel, onChangeInput, getInputData, true, false,
            "option", agencyOption, "Agency", menus.fieldAgencyError),
        DefaultFormInputFields(menus.fieldPipelineLabel, onChangeInput, getInputData, true, false,
            "option", pipeOption, "Pipeline", menus.fieldPipelineError),
        DefaultFormInputFields(menus.fieldPhaseLabel, onChangeInput, getInputData, true, false,
            "option", phaseOption, "Phase", menus.fieldPhaseErr),
        DefaultFormInputFields(menus.fieldRealtimeLabel, onChangeInput, getInputData, true, false,
            "option", yesNoOption, "Realtime", menus.fieldRealtimeErr),
        DefaultFormInputFields(menus.fieldHistoryLabel, onChangeInput, getInputData, true, false,
            "option", yesNoOption, "IncludeHistory", menus.fieldHistoryErr),
        DefaultFormInputFields(menus.fieldTotalLabel, onChangeInput, getInputData, true, false,
            "text", [], "Total", menus.fieldTotalErr),

    ];

    const submitForm = async () => {

        const org = InputOrganization;
        // @ts-ignore
        const agency = InputAgency;
        // @ts-ignore
        const name = FormatCodeUpCase(InputName);

        // @ts-ignore
        let id = moment().format("YYYYMMDDHHmmSS")
        const ref = `${org}-${agency}-${name}-${id}`;

        let record = {
            Code: ref,
            Organization: org,
            Agency: agency,
            Name: name,
            Pipeline: InputPipeline,
            Phase: InputPhase,
            Realtime: InputRealtime,
            IncludeHistory: InputHistory,
            Ref: ref,
            UniqueRef: ref,
            Total: 0,
            Taken: 0,
        }

        // @ts-ignore
        await SubmitFormToFirebase(record, columns, entityNameFirebase, ref, cleanInputs, menus.submitConfirmMessage)
        setIsEdit(false)
    }


    const localSyncData = () => {
        SyncData(setData, entityNameFirebase, "Ref", cleanInputs, styles.aIcon, true);
        SyncData(setDataOrganization, "CcOrganizations", "Code", cleanInputs, styles.aIcon, true)
        SyncData(setDataAgency, "CcAgency", "UniqueRef", cleanInputs, styles.aIcon, true)
        SyncData(setDataTreeAll, "CcPipelineSettings", "Ref", cleanInputs, styles.aIcon, true)
        setIsSync(true)
    }


    const cancelNew = () => {
        setIsEdit(false);
    }
    const requestEdit = () => {
        setIsEdit(true);
    }
    const buildAddInfo = (fieldName: string, fieldValue: string) => {
        return (
            <p>
                <span style={styles.buildAddInfoField}>{fieldName}</span>:
                <span style={styles.buildAddInfoValue}>{fieldValue}</span>
            </p>
        )
    }
    const findPhaseName=(org:string,agency:string,pipeline:string,ref:string)=>{
        let name = ref;
        for(let i in DataTreeAll){
            const row = DataTreeAll[i];
            for(let y in row.Phases){
                let rowPhase =row.Phases[y];
                console.log("findPhaseName > ",row.Agency,"!==",agency," > ",(row.Ref!==pipeline))
                if(rowPhase.Ref!==ref){
                    continue
                }
                name = rowPhase.Name
            }
        }
        return name;
    }
    const prepareData = () => {
        let tmpData: any[] = [];
        for (let i in data) {
            let row = data[i];
            let rowToUpload = row;

            console.log("^^^^---> ",111)
            // @ts-ignore
            if(rowToUpload.Organization !==InputOrganization){continue}
            console.log("^^^^---> ",222)
            // @ts-ignore
            if(rowToUpload.Agency !==InputAgency){continue}
            console.log("^^^^---> ",333)
            // @ts-ignore
            let org = findDisplayKeyInArray(row.Organization, dataOrganization, "Code", "Name");
            // @ts-ignore
            let agency = findDisplayKeyInArray(row.Agency, dataAgency, "Code", "Name");

            // @ts-ignore
            let pipeline = findDisplayKeyInArray(row.Pipeline, DataTreeAll, "Ref", "Name");

            // @ts-ignore
            let phase  = findPhaseName(rowToUpload.Organization,rowToUpload.Agency,rowToUpload.Pipeline,rowToUpload.Phase);

            console.log("^^^^---> ",org," > ",agency," > ",pipeline)

            let record = {};
            // @ts-ignore
            record.Realtime = row.Realtime;
            // @ts-ignore
            record.IncludeHistory = row.IncludeHistory;
            record.Action = row.Action;
            // @ts-ignore
            record.Ref = row.Ref;
            // @ts-ignore
            record.Code = row.Code;
            // @ts-ignore
            record.Name = row.Name
            // @ts-ignore
            record.Organization = org;
            // @ts-ignore
            record.Agency = agency
            // @ts-ignore
            record.Pipeline = pipeline

            // @ts-ignore
            record.Phase =phase


            tmpData.push(record)
        }
        return tmpData;
    }
    const ListBuilder = () => {

        let dataStore = prepareData();
        let store = {columns: columns, data: dataStore}
        // @ts-ignore
        return (
            <TableDataView
                {...store}
                tableTitle={"Données  (" + data.length + ") "}
                otherFunctions={[]}
                options={{
                    pageSize: 20,
                }}
                noAddButton={true}
            />
        )
    }

    useEffect(() => {
        if (!isSync) {
            localSyncData();
        }
    }, []);


    console.log("inputData > ", inputData)
    return (
        <div style={styles.container}>
            <IonLoading message={"please wait.."} duration={0} isOpen={busy}/>
            {IsEdit ?
                <div style={styles.container}>
                    <h1 style={{color: "#3880ff"}}>
                        <u>New phase!</u>
                        <a onClick={() => cancelNew()}
                           style={styles.cancelNewPipeline}
                        >
                            <IonIcon slot="start" ios={closeCircleSharp} md={closeCircleSharp}/>
                        </a>
                    </h1>
                    {buildAddInfo("Organization", InputOrganization)}
                    {buildAddInfo("Agency", InputAgency)}

                    {buildAddInput(InputName, "Name", "Name", setInputName, "text", true)}
                    {buildAddInputSelect(InputPipeline, "Pipeline", "Pipeline", setInputPipeline, "option", true, pipeOption)}
                    {buildAddInputSelect(InputPhase, "Phase", "Phase", setInputPhase, "option", true, phaseOption)}
                    {buildAddInputSelect(InputRealtime, "Realtime", "Realtime", setInputRealtime, "option", true, yesNoOption)}
                    {buildAddInputSelect(InputHistory, "Include History", "Include History", setInputHistory, "option", true, yesNoOption)}

                    {buildAddButton("Submit New Phase", "secondary", "#3880ff", submitForm)}
                </div> :
                <>


                    <IonList>
                        <IonLabel style={styles.titleComponent}>
                            Queue List
                            <a
                                onClick={() => requestEdit()}
                                style={styles.aIcon}

                            >
                                <IonIcon icon={addCircle} color={"primary"}>ADD NEW</IonIcon>
                            </a>
                        </IonLabel>
                    </IonList>
                    {buildAddInputSelect(InputOrganization, "Organization", "Organization", setInputOrganization, "option", true, organizationOption)}
                    {buildAddInputSelect(InputAgency, "Agency", "Agency", setInputAgency, "option", true, agencyOption)}
                    <ListBuilder/>
                </>
            }
        </div>
    )

}

export default QueuePipelinePage;

const styles = {
    buildAddInfoField: {
        color: "red",
        fontSize: 12,
        marginLeft: 10
    },
    buildAddInfoValue: {
        color: "gray",
        fontSize: 12,
        marginLeft: 10
    },
    titleComponent: {
        color: "#3880ff",
        fontSize: 18,
        fontWeight: "bold",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        marginTop: 20
    },
    requestNewPipeline: {
        float: "right",
        color: "blue",
        fontSize: 19,
        fontWeight: "bold"
    },
    cancelNewPipeline: {
        float: "right",
        color: "red",
        fontSize: 19,
        fontWeight: "bold",
        cursor: "pointer"
    },
    aIcon: {
        fontSize: 24,
        cursor: "pointer"
    },
    container: {
        minWidth: "100%",
        paddingRight: 20,

    }
}


