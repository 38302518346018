import React, { useState } from 'react';
import { IonPopover, IonButton } from '@ionic/react';
import {PropsPopupProcessField, SelectOption} from "../../mocks/types";
import './my.css'
import {buildAddInputSelect} from "../../services/general-functions";
import { Input } from 'antd';

const { TextArea } = Input;

export const PopupAgentProcessField= (props:PropsPopupProcessField) => {
    const [popoverState, setShowPopover] = useState({ showPopover: false, event: undefined });
    const [InputFieldPipeline,setInputFieldPipeline]=useState("")
    const [InputFieldPhase,setInputFieldPhase]=useState("")
    const [InputFieldComment,setInputFieldComment]=useState("")
    const [InputFieldWhatsApp,setInputFieldWhatsApp]=useState("")
    const {showPopover,onClose,event,setPipeline,setPhase,setComment,Pipelines,Organization,Agency,Checkin,submitProcess,OptionsComment,setIsWhatsApp}=props;

    const setPipelineInput=(value:string)=>{
        setInputFieldPipeline(value);
        setPipeline(value)
    }
    const setPhaseInput=(value:string)=>{
        setInputFieldPhase(value);
        setPhase(value)
    }
    const setCommentInput=(value:string)=>{
        setInputFieldComment(value);
        setComment(value)
    }
    const setWhatsAppInput=(value:string)=>{
        setInputFieldWhatsApp(value);
        setIsWhatsApp(value)
    }
    const onSubmit=async ()=>{
        if(InputFieldPipeline==="")return alert("Sorry select your pipeline!");
        if(InputFieldPhase==="")return alert("Sorry select your phase!");
        submitProcess()
    }
    const getPipelineOption=():SelectOption[]=>{
        console.log("getPipelineOption > ",Pipelines)
        console.log("getPipelineOption 2> ",Organization," > ",Agency," > ",Checkin)
        let ls:SelectOption[]=[];
        for(let i in Pipelines){
            let row=Pipelines[i];
            if(row.Organization !==Organization){continue}
            if(row.Agency !==Agency){continue}
            ls.push({
                key:row.Ref,
                val:row.Name
            })
        }
        return ls
    }
    const getPhaseOption=():SelectOption[]=>{
        let ls:SelectOption[]=[];
        for(let i in Pipelines){
            let row=Pipelines[i];
            if(row.Organization !==Organization){continue}
            if(row.Agency !==Agency){continue}
            if(row.Ref !==InputFieldPipeline){continue}
            if(typeof row.Phases==="undefined"){
                continue
            }
            let phases=row.Phases;
            for(let y in phases){
                let rec = phases[y];
                ls.push({
                    key:rec.Ref,
                    val:rec.Name
                })
            }
        }
        return ls
    }

    const optionsWhatsApp:SelectOption[]=[
        {key:"yes",val:"Oui"},
        {key:"no",val:"No"}
    ]

    return (
        <>
            <IonPopover
                cssClass='popoverProcessField'
                event={event}
                isOpen={showPopover}
                onDidDismiss={() => {
                    //setShowPopover({ showPopover: false, event: undefined })
                    onClose()
                }}
                mode={"md"}
            >
                <div style={styles.container}>
                    <p style={{textAlign:"center"}}>Veuillez fournir les informations ci-dessous après avoir préalablement contacté le client afin de clôturer cet ordre de travail</p>
                    {buildAddInputSelect(InputFieldPipeline, "Pipeline", "Flux de travail", setPipelineInput,
                        "option", true, getPipelineOption())}
                    {buildAddInputSelect(InputFieldPhase, "Phase", "Phase", setPhaseInput,
                        "option", true, getPhaseOption())}
                    {buildAddInputSelect(InputFieldWhatsApp, "IsWhatsApp", "WhatsApp?", setInputFieldWhatsApp,
                        "option", true,optionsWhatsApp)}
                    {buildAddInputSelect(InputFieldComment, "Commentaire", "Commentaire", setCommentInput, "option", true, OptionsComment)}
                    <TextArea
                        value={InputFieldComment}
                        rows={4}
                        showCount
                        maxLength={100}
                        onChange={(e)=>setCommentInput(e.target.value)}
                    />

                    <IonButton title={"CLose"} onClick={()=>onSubmit()} style={styles.btn} color={"secondary"}>Soumettre</IonButton>
                </div>

            </IonPopover>

        </>
    );
};

const styles={
    btn:{
        minWidth:"100%",
        borderRadius:10,
        borderWidth:10,
        backgroundColor:"red"
    },
    container:{
        minWidth:"90%",
        /*backgroundColor:"green",*/
        padding:10,
    }
}