import firebase from "../configures/firebase-init";
import {setEntityDatabaseKey} from "./firebase-functions";

const entityQueueHoldingChannelBatch = "CcQueueHoldingBatch";
const entityQueueHoldingChannelField= "CcQueueHoldingField"

export const QueueBatchFetchInfo=async (BatchRef: string,fn:any)=>{
    let path=`CcLeadBatch/${BatchRef}`;
    await firebase.database()
        .ref(path)
        .once("value",snapshot => {
            if (snapshot.exists()){
                const userData = snapshot.val();
                console.log("QueueBatchFetchInfo : exists! > ", userData);
                fn(userData)
            }else{
                fn(null)
            }
        })

}
export const QueueFieldCheckIfRefExist=async (BatchRef: string,fn:any)=>{
    await firebase.database()
        .ref(entityQueueHoldingChannelField).orderByChild("Current_id").equalTo(BatchRef).once("value",snapshot => {
            if (snapshot.exists()){
                fn(true)
            }else{
                fn(false)
            }
        }).then(r=>{});
}
export const QueueBatchCheckIfRefExist=async (BatchRef: string,fn:any)=>{
    await firebase.database()
    .ref(entityQueueHoldingChannelBatch).orderByChild("Current_id").equalTo(BatchRef).once("value",snapshot => {
        if (snapshot.exists()){
            fn(true)
        }else{
            fn(false)
        }
    }).then(r=>{});
}
export const QueueBatchIncrementValue=async (BatchRef: string)=>{
    const db = firebase.firestore();
    const userRef = db.collection(entityQueueHoldingChannelBatch).doc(BatchRef);
    const decrement = firebase.firestore.FieldValue.increment(-1);
    userRef.update({ Current_id: decrement });
}
export const QueueBatchFetchQueryData = async (BatchRef: string, KeySearch: string, KeyValue: string, fn: any) => {
    let collection = "CcLeadBatchCsv";
    await firebase
        .firestore()
        .collection(collection)
        .where("BatchRef", "==", BatchRef)
        .where(KeySearch, "array-contains", {[KeyValue]:KeyValue})
        .get()
        .then(result => {
            let data: any[] = [];
            console.log("QueueFetchQueryData > QQ> ", result.docs)
            result.forEach(doc => {
                console.log("QueueFetchQueryData > ", doc)
            });
            fn(data)
        })
}
export const QueueFetchOneData = async (BatchRef: string, PositionRef: string, fn: any) => {
    let collection = "CcLeadBatchCsv";
    await firebase
        .firestore()
        .collection(collection)
        .doc(BatchRef)
        .collection(PositionRef)
        .doc(PositionRef)
        .get()
        .then(result => {
            let doc = result.data();
            fn(doc)
        });
}

export const QueueFieldCreated=async (QueueRef:string)=>{
    let isQueueExist:boolean = false
    await QueueFieldCheckIfRefExist(QueueRef,(res:boolean)=>{
        isQueueExist =res
    });
    if(!isQueueExist){
        //let now create our queue info
        let info={
            Current_id:0,
            TotalQueue:0,
            Taken:0,
            QueueRef:QueueRef,
        }
        await setEntityDatabaseKey(entityQueueHoldingChannelField,QueueRef,info)
    }else{
        console.log("::)-isQueueExist> ",isQueueExist," > ",QueueRef)
    }
}


export const QueueBatchCreated=async (QueueRef:string,BatchRef: string)=>{
    let isQueueExist:boolean = false
    let totalQueueValue = 0;
    await QueueBatchCheckIfRefExist(QueueRef,(res:boolean)=>{
        isQueueExist =res
    });
    if(!isQueueExist){
        //let get the total lead in the batch
        await QueueBatchFetchInfo(BatchRef,(data:any)=>{
            totalQueueValue = data.Total
        });
        //let now create our queue info
        let info={
            Current_id:0,
            TotalQueue:totalQueueValue,
            BatchRef:BatchRef,
            QueueRef:QueueRef,
        }
        await setEntityDatabaseKey(entityQueueHoldingChannelBatch,QueueRef,info)
    }else{
        console.log("::)-isQueueExist> ",isQueueExist," > ",QueueRef)
    }
}
export const QueueBatchGetNext=async (QueueRef:string,UserRef: string)=>{
    //todo
}


export const BuildQueueBatchCsvData = async (batchRef: string, QueueRef: string) => {
    let isQueueExist:boolean = false
    let totalQueueValue = 0;
    await QueueBatchCheckIfRefExist(batchRef,(res:boolean)=>{
        isQueueExist =res
    });
    console.log("QueueBatchCheckIfRefExist > ",isQueueExist)
    if(!isQueueExist){
        await QueueBatchFetchInfo(batchRef,(data:any)=>{
            totalQueueValue = data.Total
        })
    }




    console.log("QueueBatchCheckIfRefExist totalQueueValue > ",totalQueueValue)

    //todo let fetch our data from teh batch
    let dataBatch: any[] = [];
    let collection = "CcLeadBatchCsv";
    let keySearch = "10";// "27739068947"
    let pathCollection = `${collection}/${batchRef}/${keySearch}`
    let snap = firebase
        .firestore()
        .collection(collection)
        .where("BatchRef", "==", batchRef)
        .where("PositionRef", "==", keySearch)
        .get();
    let myDoc = await firebase
        .firestore()
        .collection(collection)
        .doc(batchRef)
        .collection(keySearch)
        .doc(keySearch).get().then(result => {
            let doc = result.data();
            console.log("MyDoc 2> ", doc)
        })


    let records: any[] = [];


    console.log("BuildQueueBatchCsvData BatchRef > ", batchRef, " > ", records);

    return records

}
