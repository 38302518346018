import firebase from "../configures/firebase-init";
import {escapeEmailAddress} from "./general-functions";


export const removeDataFromDb=async (table:string,ref :string)=>{
    console.log("removeDataFromDb > ",table," > ",ref)

    await firebase.database().ref(`${table}/${ref}`).remove();
}
export const saveDataIntoZeroRef=async (table:string,data:any)=>{
    let postsRef = firebase.database().ref(`${table}`);
    const newPostRef = postsRef.push();
    await newPostRef.set(data);
}
export const saveDataIntoOneRef=async (table:string,ref1:string,data:any)=>{
    await firebase.database().ref(`${table}`)
        .child(ref1)
        .set(data);
}
export const saveDataIntoTwoRef=async (table:string,ref1:string,ref2:string,data:any)=>{
    await firebase.database().ref(`${table}`)
        .child(ref1)
        .child(ref2)
        .set(data);
}
export const saveDataIntoThreeRef=async (table:string,ref1:string,ref2:string,ref3:string,data:any)=>{
    await firebase.database().ref(`${table}`)
        .child(ref1)
        .child(ref2)
        .child(ref3)
        .set(data);
}
export const fetchingOnceDataFromDatabase=async (ref:string,fn:any)=>{
    let targetRef = firebase.database().ref(`${ref}`);
    await targetRef.once("value", async function (snapshot: any) {
        let innerRecord = snapshot.val();
        fn(innerRecord)
    })
}