import React, { useState } from 'react';
import { IonPopover, IonButton } from '@ionic/react';
import { PropsPopupReminder} from "../../mocks/types";
import './my.css'
import {buildAddInputSelect} from "../../services/general-functions";
import { Input } from 'antd';
import CalendarSelector from "../common/CalendarSelector";

const { TextArea } = Input;

export const PopupAgentReminder= (props:PropsPopupReminder) => {
    const [InputFieldTime,setInputFieldTime]=useState("")
    const [InputFieldDate,setInputFieldDate]=useState("")
    const [InputFieldComment,setInputFieldComment]=useState("")
    const {showPopover,onClose,setDate,setTime,setComment,event,submitProcess,OptionsReminder}=props;

    const setDateInput=(value:string)=>{
        setInputFieldDate(value);
        // @ts-ignore
        setDate(value)
    }
    const setTimeInput=(value:string)=>{
        setInputFieldTime(value);
        // @ts-ignore
        setTime(value)
    }
    const setCommentInput=(value:string)=>{
        setInputFieldComment(value);
        // @ts-ignore
        setComment(value)
    }
    const onSubmit=async ()=>{
        if(InputFieldDate=="")return alert("Sorry pickup your reminder date!");
        if(InputFieldTime==="")return alert("Sorry pickup your time!");
        submitProcess()
    }

    return (
        <>
            <IonPopover
                cssClass='popoverProcessField'
                event={event}
                isOpen={showPopover}
                onDidDismiss={() => {
                    //setShowPopover({ showPopover: false, event: undefined })
                    onClose()
                }}
                mode={"md"}
            >
                <div style={styles.container}>
                    <p style={{textAlign:"center"}}>Afin de reporter cet ordre de travail, veuillez fournir les détails ci-dessous après avoir préalablement tenté de contacter le client</p>
                    <CalendarSelector label={"Date de rappel"} setData={setDateInput} mode={"date"}/>
                    <CalendarSelector label={"Heure de rappel"} setData={setTimeInput} mode={"time"}/>
                    <hr/>
                    {buildAddInputSelect(InputFieldComment, "Comment", "Commentaire", setCommentInput, "option", true, OptionsReminder)}
                    <TextArea
                        value={InputFieldComment}
                        rows={4}
                        showCount
                        maxLength={100}
                        onChange={(e)=>setCommentInput(e.target.value)}
                    />

                    <IonButton title={"CLose"} onClick={()=>onSubmit()} style={styles.btn} color={"secondary"}>Soumettre</IonButton>
                </div>

            </IonPopover>

        </>
    );
};

const styles={
    btn:{
        minWidth:"100%",
        borderRadius:10,
        borderWidth:10,
        backgroundColor:"red"
    },
    container:{
        minWidth:"90%",
        /*backgroundColor:"green",*/
        padding:10,
    }
}

/*
{
  "Organization": "CC100001",
  "Agency": "ACX1001",
  "LeadData": {
    "Channel": "field",
    "CreateTime": "23:27:50",
    "CreatedDate": "2021-07-19",
    "Data": {
      "Agence": "AG00001",
      "Agency": "AG00001",
      "Code": "TAC100001ZzAG00001ZzTM01Zz20210719232558",
      "CustomerContactNumber": "4",
      "Date": "2021-07-19",
      "FullName": "test 84",
      "IsOnSite": true,
      "Latitude": -25.8314894,
      "Longitude": 28.1927365,
      "Organization": "C100001",
      "ParentNumber": "4",
      "Provice": "Kinshasa",
      "Ref": "TAC100001ZzAG00001ZzTM01Zz20210719232558",
      "Site": " COLLEGE PERE ALPHA KUNDA",
      "Team": "TM01",
      "Time": "23:25:51",
      "UserCode": "-Mc1NEOscsvz3JPZ4W2M",
      "UserFullName": "Merveilleux Biangacila",
      "Username": "biangacila@gmail.com"
    },
    "ExtRef": "TAC100001ZzAG00001ZzTM01Zz20210719232558",
    "InRef": "TAC100001ZzAG00001ZzTM01Zz20210719232558",
    "QueueRef": "Q0MxMDAwMDEtQUNYMTAwMS1RVUVVRTE5SlVMIEtBQkFTRVJWSUNFIFRFU1QgMS0yMDIxMDcxOTA5MjQ2OA==",
    "Username": "biangacila@gmail.com"
  },
  "InputReminderDate": "2021-07-22",
  "InputReminderComment": "",
  "InputReminderTime": "16:00:00",
  "Date": "2021-07-20",
  "Time": "23:20:23",
  "Username": "biangacila@gmail.com",
  "OrderRef": ""
}
 */