
import {applyMiddleware, compose, createStore} from "redux";
import rootReducer from "./rootReducer";
import {reduxFirestore,getFirestore} from "redux-firestore";
import {getFirebase,
    ReactReduxFirebaseProvider,
    firebaseReducer,
} from "react-redux-firebase";

import thunk from "redux-thunk";
import fbConfig from  "../configures/firebase-init"

// react-redux-firebase config.ts
const rrfConfig = {
    userProfile: 'users'
    // useFirestoreForProfile: true // Firestore for ProfilePage instead of Realtime DB
}

const store   = createStore(
    rootReducer,
    compose(
        applyMiddleware(thunk.withExtraArgument({getFirebase,getFirestore})),
        reduxFirestore(fbConfig),
    ));
export default store;
