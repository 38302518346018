
import {CommentHistoryEntry, UserEntry} from "../mocks/types";
import firebase from "../configures/firebase-init";
import {saveDataIntoOneRef, saveDataIntoTwoRef} from "./submit-common";
import moment from "moment";
import {ConvertStringToBase64, escapeEmailAddress} from "./general-functions";


export const SubmitRequestProcessComment=async (infoSubmit:CommentHistoryEntry)=>{
    let entity="CcProcessCommentHistory";
    let nameOfUser = "";

    let refUsers= await firebase.database().ref(`users`).get();
    let info = refUsers.val();
    for(let i in info){
        const row = info[i] as UserEntry;
        if(row.Username===infoSubmit.Username){
            nameOfUser = row.FullName;
        }
    }
    infoSubmit.UserFullName  = nameOfUser;
    let id = `${escapeEmailAddress(infoSubmit.LeadRef)}__${moment().format("YYYYMMDDHHmmSS")}`;
    //console.log("(((:submit-comment:))-> 1 ",infoSubmit);
    //console.log("(((:submit-comment:))-> 2 ",infoSubmit.LeadRef);
    //console.log("(((:submit-comment:))-> 3 ",id);
    await saveDataIntoTwoRef(entity,infoSubmit.LeadRef,id,infoSubmit)

}