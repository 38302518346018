import React, {useEffect, useState} from "react";
import {
    IonButton,
    IonIcon, IonList,
    IonLoading,
} from "@ionic/react";
import {
    escapeEmailAddress, IsDateBetween,
    SyncData
} from "../../services/general-functions";
// @ts-ignore
import firebase from "../../configures/firebase-init";

import moment from "moment";
import CalendarSelector from "../../components/common/CalendarSelector";
import TableDataView from "../../components/common/TableDataView";
import store from "../../redux/store";
import {Col, Row} from "antd";

type MiniQueue={
    Code:string,
    Name:string,
    Organization:string,
    Phase:string,
}
type MinQueueDaily={
    Code:string,
    Name:string,
    Date:string,
    Time:string,
    Category:string,
    Total:number
}

const WorkerReportPage = () => {
    const [busy, setBusy] = useState<boolean>(false);
    const [isSync, setIsSync] = useState<boolean>(false);
    const [startDate, setStartDate] = useState(moment().format("YYYY-MM-DD"));
    const [endDate, setEndDate] = useState(moment().format("YYYY-MM-DD"));
    const [dataAgentDaily, setDataAgentDaily] = useState([])
    const [dataUsers, setDataUsers] = useState([]);
    const [DataQueueListPipeline,setDataQueueListPipeline] = useState<MiniQueue[]>([]);
    const [DataQueueListField,setDataQueueListField] = useState<MiniQueue[]>([])
    const [DataQueueDailyPipeline,setDataQueueDailyPipeline] = useState<MinQueueDaily[]>([]);
    const [DataQueueDailyField,setDataQueueDailyField] = useState<MinQueueDaily[]>([])




    const loadQueueList=async (table:string,setFun:any)=>{
        let refTable = firebase.database().ref(table);
        refTable.on("value", (snapshot) => {
            let records = snapshot.val();
            let ls :MiniQueue[]= [];
            for (let i in records) {
                let row :MiniQueue= records[i] as MiniQueue;
                ls.push(row)
            }
            setFun(ls)
        })
    }

    const loadQueueDaily=async (table:string,category:string,setFun:any)=>{
        let refTable = firebase.database().ref(table);
        refTable.on("value", (snapshot) => {
            let records = snapshot.val();
            let ls :MinQueueDaily[]= [];
            for (let queueRef in records) {
                let record = records[queueRef];
                for(let date in record){
                    let row = record[date];
                    let o:MinQueueDaily={
                        Category:category,
                        Code:queueRef,
                        Date:date,
                        Name:"",
                        Time:row.LastUpdatedTime,
                        Total:row.Total,
                    }
                    ls.push(o)
                }
            }
            setFun(ls)
        })
    }

    const localSyncData = () => {
        loadQueueList("CcQueuePipelineSetting",setDataQueueListPipeline)
        loadQueueList("CcQueueFieldSetting",setDataQueueListField)

        loadQueueDaily("CcProcessPipelineReportQueueRefDaily","pipeline",setDataQueueDailyPipeline)
        loadQueueDaily("CcProcessFieldReportQueueRefDaily","field",setDataQueueDailyField)

        SyncData(setDataUsers, "users", "Username", null, styles.aIcon, false)
        SyncData(setDataAgentDaily, "CcProcessFieldReportAgentDaily", "Username", null, styles.aIcon, false)
        //SyncData(setDataUsers, "users", "Username", null, styles.aIcon, false);
        let refTable = firebase.database().ref("CcProcessFieldReportAgentDaily");
        refTable.on("value", (snapshot) => {
            let records = snapshot.val();

            let ls = [];
            for (let user in records) {
                let row1 = records[user];

                for (let date in row1) {
                    let rec = row1[date];
                    ls.push({
                        FullName: user,
                        LastDate: rec.LastUpdatedDate,
                        LastTime: rec.LastUpdatedTime,
                        Total: rec.Total
                    });
                }
            }

            setDataAgentDaily(ls)
        })
    }

    const renderReport = () => {
        const findName2 = (userIn) => {
            for (let i in dataUsers) {
                let row = dataUsers[i];
                let u = escapeEmailAddress(row.Username);
                if (u === userIn) {
                    return row.FullName
                }
            }
            return userIn
        }
        let columns = [
            {id: "FullName", label: "Agent Name"},
            {id: "LastDate", label: "LastDate"},
            {id: "LastTime", label: "Last Time"},
            {id: "Total", label: "Total"},
        ]
        let ls = [];
        let lsMap = {};
        for (let i in dataAgentDaily) {
            let rec = dataAgentDaily[i];
            if(!IsDateBetween(rec.LastDate,startDate,endDate)){
               continue
            }
            const u = rec.FullName;
            let tot = 0;
            if (typeof lsMap[u] !== "undefined") {
                tot = lsMap[u].Total;
            }
            tot = tot + rec.Total;
            lsMap[u] = {
                Total: tot,
                Username: u,
                LastDate: rec.LastDate,
                LastTime: rec.LastTime,
                FullName: findName2(rec.FullName),
            }
        }
        for (let i in lsMap) {
            let rec = lsMap[i]
            ls.push(rec)

        }

        let store = {columns: columns, data: ls}

        // @ts-ignore
        return (
            <TableDataView
                {...store}
                tableTitle={"Agent Données  (" + ls.length + ") "}
                otherFunctions={[]}
                options={{
                    pageSize: 10,
                }}
                noAddButton={true}
            />
        )
    }

    const renderReportDailyQueue = () => {
        const findQueueName = (queueRef:string) => {
            for (let i in DataQueueListField) {
                let row = DataQueueListField[i];
                if(row.Code ===queueRef){
                    return row.Name
                }
            }
            for (let i in DataQueueListPipeline) {
                let row = DataQueueListPipeline[i];
                if(row.Code ===queueRef){
                    return row.Name
                }
            }
            return queueRef
        }
        let columns = [
            {id: "Name", label: "Queue Name"},
            {id: "Category", label: "Category"},
            {id: "Date", label: "LastDate"},
            {id: "Time", label: "Last Time"},
            {id: "Total", label: "Total"},
        ]

        let arrayCombine:MinQueueDaily[] = DataQueueDailyField.concat(DataQueueDailyPipeline);
        console.log("XXXXXXZZZ-> ",arrayCombine)
        let ls = [];
        let lsMap = {};
        for (let i in arrayCombine) {
            let rec = arrayCombine[i];
            if(!IsDateBetween(rec.Date,startDate,endDate)){
                continue
            }
            const u = rec.Code;
            let tot = 0;
            if (typeof lsMap[u] !== "undefined") {
                tot = lsMap[u].Total;
            }
            tot = tot + rec.Total;
            lsMap[u] = {
                Total: tot,
                Category: rec.Category,
                Date: rec.Date,
                Time: rec.Time,
                Name: findQueueName(rec.Code),
            }
        }
        for (let i in lsMap) {
            let rec = lsMap[i]
            ls.push(rec)

        }

        console.log("ZZZZZZ-XX> 1 ",DataQueueListPipeline);
        console.log("ZZZZZZ-XX> 2 ",DataQueueListField)

        let store = {columns: columns, data: ls}

        // @ts-ignore
        return (
            <TableDataView
                {...store}
                tableTitle={"Queue Données  (" + ls.length + ") "}
                otherFunctions={[]}
                options={{
                    pageSize: 10,
                }}
                noAddButton={true}
            />
        )
    }



    useEffect(() => {
        if (!isSync) {
            localSyncData()
            setIsSync(true)
        }
    })

    return (
        <div style={styles.container}>
            <IonLoading message={"please wait.."} duration={0} isOpen={busy}/>
            <h1 style={{color: "#3880ff", marginLeft: 10, fontSize: 18}}>
                Report daily by agent
            </h1>

            <IonList>
                <CalendarSelector label={"Start Date"} setData={setStartDate} mode={"date"}/>
                <CalendarSelector label={"End Date"} setData={setEndDate} mode={"date"}/>
            </IonList>

            <Row>
                <Col span={24}>
                    {renderReport()}
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    {renderReportDailyQueue()}
                </Col>
            </Row>



        </div>
    )

}

export default WorkerReportPage;
const styles = {
    headerDetail: {
        color: "gray",
        fontWeight: "bold",
        fontSize: 14
    },
    link: {
        cursor: "pointer",
        color: "blue",
        marginLeft: 10
    },
    tabs: {
        backgroundColor: "lightgray",
        padding: 10,
        overflow: "auto"
    },
    innerTab: {
        minHeight: 400,
        overflow: "auto"
    },
    btn: {
        minWidth: "100%",
        maxWidth: "100%"
    },
    IonColHeaderAllocation: {
        borderStyle: "solid",
        borderColor: "#ddd",
        backgroundColor: "#eb445a",
        marginBottom: 5,
        borderWidth: 1,
        marginLeft: 5,
        color: "white"
    },
    IonColHeader: {
        borderStyle: "solid",
        borderColor: "#ddd",
        backgroundColor: "#eb445a",
        marginBottom: 5,
        borderWidth: 1,
        marginLeft: 5,
        color: "white"
    },
    IonCol2: {
        borderStyle: "solid",
        borderColor: "#ddd",
        backgroundColor: "#f7f7f7",
        marginBottom: 5,
        borderWidth: 1,
        marginLeft: 5,
        color: "red",
        fontSize: 14,
        fontWeight: "bold",
        justifyContent: "center",
    },
    IonCol: {
        borderStyle: "solid",
        borderColor: "#ddd",
        backgroundColor: "#f7f7f7",
        marginBottom: 5,
        borderWidth: 1,
        marginLeft: 5
    },
    IonRow2: {
        borderStyle: "solid",
        borderColor: "#46b0e0",
        backgroundColor: "#f7f7f7",
        borderRadius: 5,
        borderWidth: 1,
        marginLeft: 5,
        marginBottom: 5
    },
    IonRow: {
        /*borderStyle:"solid",
        borderColor:"#ddd",
        backgroundColor: "#f7f7f7",
        marginBottom:5,
        borderWidth:1,*/
    },
    buildAddInfoField: {
        color: "red",
        fontSize: 12,
        marginLeft: 10
    },
    buildAddInfoValue: {
        color: "gray",
        fontSize: 12,
        marginLeft: 10
    },
    titleComponent: {
        color: "#3880ff",
        fontSize: 18,
        fontWeight: "bold",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        marginTop: 20
    },
    requestNewPipeline: {
        float: "right",
        color: "blue",
        fontSize: 19,
        fontWeight: "bold"
    },
    cancelNewPipeline: {
        float: "right",
        color: "red",
        fontSize: 19,
        fontWeight: "bold",
        cursor: "pointer"
    },
    aIcon: {
        fontSize: 24,
        cursor: "pointer"
    },
    container: {
        minWidth: "100%",
        padding: 20,

    }
}

