import React, {useState, useEffect} from "react";
import FormBuilders from "../../components/common/FormBuilders";
import {IonIcon, IonLabel, IonList, IonLoading} from "@ionic/react";
import {
    findUserLanguage, getSelectionOptions,
    SubmitFormToFirebase,
    SyncData,
    findDisplayKeyInArray, FormatCodeUpCase, escapeEmailAddress, buildAddInput, buildAddInputSelect, buildAddButton
} from "../../services/general-functions";
import {
    addCircle,
    closeCircleSharp,
    cloudUpload
} from 'ionicons/icons';
import {propsColumns, QueueEntry, QueueInvitation, SelectOption} from "../../mocks/types";
import {InfoLang,menus as ListMenus} from "../../mocks/language";
import {OnChangeInput, GetInputData, DefaultFormInputFields} from "../../services/form-inputs";
import LeadUpload from "../../components/Lead/LeadUpload";
import moment from "moment";
import {BuildQueueBatchCsvData, QueueBatchCreated} from "../../services/queue-functions";
import TableDataView from "../../components/common/TableDataView";

const InvitationsPage: React.FC = () => {
    const [currentLanguage, setCurrentLanguage] = useState("en")
    const [busy, setBusy] = useState<boolean>(false);
    const [IsEdit,setIsEdit]=useState<boolean>(false)
    const [isSync, setIsSync] = useState<boolean>(false);
    const [showList, setShowList] = useState(true);
    const [data, setData] = useState([]);
    const [dataOrganization, setDataOrganization] = useState([]);
    const [dataAgency, setDataAgency] = useState([]);
    const [dataQueuePipeline, setDataQueuePipeline] = useState([]);
    const [dataQueueField, setDataQueueField] = useState([])
    const [Category, setCategory] = useState("");
    const [QueueOptions, setQueueOptions] = useState([])
    const [inputData, setInputData] = useState<object>({});

    const [InputOrganization,setInputOrganization]=useState("");
    const [InputAgency,setInputAgency]=useState("");
    const [InputCategory,setInputCategory]=useState("");
    const [InputQueue,setInputQueue]=useState("");
    const [InputUserFullName,setInputUserFullName]=useState("");
    const [InputUsername,setInputUsername]=useState("");




    //findUserLanguage(setCurrentLanguage, null);
    let info =ListMenus["fr"]// InfoLang(currentLanguage)
    const menus = info.invitation.all;

    const entityNameFirebase = "CcQueueInvitations";
    const title = menus.pageTitle;
    const titleList = menus.tableListTitle;
    const titleForm = menus.submitNewRecordText;

    const onChangeInput = (value: any, key: string) => {
        OnChangeInput(value, key, inputData, setInputData)
    }
    const getInputData = (key: string) => {
        return GetInputData(key, inputData)
    }
    const cleanInputs = () => {
        setBusy(false)
        setInputData({})
        setShowList(true)
    }
    const getQueueOption = (): SelectOption[] => {
        let ls: SelectOption[] = [];
        let data: any[] = [];
        let cat = "";
        // @ts-ignore
        if (typeof InputCategory!== "undefined") {
            // @ts-ignore
            cat = InputCategory;
        }
        if (cat === "field") {
            data = dataQueueField
        } else if (cat === "pipeline") {
            data = dataQueuePipeline;
        }

        console.log("getQueueOption cat: ",cat," > ",data )

        // @ts-ignore
        for (let i in data) {
            // @ts-ignore
            let row = data[i];
            let key = row.Ref;
            let val = row.Name;
            ls.push({
                key: key,
                val: val
            })
        }
        console.log("")
        return ls;
    }

    let organizationOption: SelectOption[] = getSelectionOptions(dataOrganization, "Code", "Name");
    let agencyOption: SelectOption[] = getSelectionOptions(dataAgency, "Code", "Name");
    let queueOption: SelectOption[] = getQueueOption();//getSelectionOptions(dataQueue, "UniqueRef", "Name");
    let categoryOption: SelectOption[] = [
        {key: "field", val: "Field Agent based Queue"},
        {key: "pipeline", val: "Pipeline Based Queue"}
    ]

    let columns: propsColumns[];
    columns = [
        DefaultFormInputFields("#", onChangeInput, getInputData, false, true, "text",
            [], "Action", ""),
        DefaultFormInputFields(menus.fieldOrganizationLabel, onChangeInput, getInputData, true, false,
            "option", organizationOption, "Organization", menus.fieldOrganizationError),
        DefaultFormInputFields(menus.fieldAgencyLabel, onChangeInput, getInputData, true, false,
            "option", agencyOption, "Agency", menus.fieldAgencyError),
        DefaultFormInputFields(menus.fieldCategoryLabel, onChangeInput, getInputData, true, false,
            "option", categoryOption, "Category", menus.fieldCategoryError),
        DefaultFormInputFields(menus.fieldQueueLabel, onChangeInput, getInputData, true, false,
            "option", queueOption, "Queue", menus.fieldQueueError),

        DefaultFormInputFields(menus.fieldUserNameLabel, onChangeInput, getInputData, true, false,
            "text", [], "UserFullName", menus.fieldUserNameErr),
        DefaultFormInputFields(menus.fieldUserEmailLabel, onChangeInput, getInputData, true, false,
            "text", [], "Username", menus.fieldUserEmailErr),
        DefaultFormInputFields(menus.fieldTotalLabel, onChangeInput, getInputData, true, true,
            "text", [], "Total", menus.fieldTotalErr),

    ];

    const submitForm = async () => {

        let ref = escapeEmailAddress(InputUsername);
        let record:QueueInvitation ={
            Organization:InputOrganization,
            Agency:InputAgency,
            Category:InputCategory,
            Queue:InputQueue,
            UserFullName:InputUserFullName,
            Username:InputUsername,
            Code:ref,
            DateTime:moment().format("YYYY-MM-DD HH:mm:SS"),
            Total:0,
            UniqueRef:ref,
        }

        let id = ref + "_" + moment().format("YYYYMMDDHHmmSS")

        console.log("submitForm (:)--> ", inputData)

        setBusy(true)
        // @ts-ignore
        await SubmitFormToFirebase(record, columns, entityNameFirebase, ref, cleanInputs, menus.submitConfirmMessage)

        await SubmitFormToFirebase(record, columns, `CcQueueInvitationsHistory`, id, cleanInputs, menus.submitConfirmMessage)

        setBusy(false);
        setIsEdit(false);
    }

    const prepareData = () => {
        let tmpData: any[] = [];

        for (let i in data) {
            let row = data[i];
            let rowToUpload = row;
            // @ts-ignore
            let org = findDisplayKeyInArray(row.Organization, dataOrganization, "Code", "Name");
            // @ts-ignore
            let agency = findDisplayKeyInArray(row.Agency, dataAgency, "Code", "Name");

            // @ts-ignore
            let queue = row.Queue;

            // @ts-ignore
            if (row.Category === "field") {
                // @ts-ignore
                queue = findDisplayKeyInArray(row.Queue, dataQueueField, "UniqueRef", "Name");
            } else { // @ts-ignore
                if (row.Category === "pipeline") {
                    // @ts-ignore
                    queue = findDisplayKeyInArray(row.Queue, dataQueuePipeline, "UniqueRef", "Name");
                }
            }


            // @ts-ignore
            row.Organization = org;
            // @ts-ignore
            row.Agency = agency
            // @ts-ignore
            row.Queue = queue

            // @ts-ignore
            row.Upload = <a onClick={() => requestUpload(rowToUpload)} style={{cursor: "pointer"}}>
                <IonIcon slot="start" ios={cloudUpload} md={cloudUpload} style={{minWidth: 44, minHeight: 44}}/>
            </a>


            tmpData.push(row)
        }
        return data;
    }
    const cancelNew = () => {
        setIsEdit(false);
    }
    const requestEdit = () => {
        setIsEdit(true);
    }
    const buildAddInfo = (fieldName: string, fieldValue: string) => {
        return (
            <p>
                <span style={styles.buildAddInfoField}>{fieldName}</span>:
                <span style={styles.buildAddInfoValue}>{fieldValue}</span>
            </p>
        )
    }
    const ListBuilder = () => {

        let dataStore = prepareData();
        let store = {columns: columns, data: dataStore}
        // @ts-ignore
        return (
            <TableDataView
                {...store}
                tableTitle={"Données  (" + data.length + ") "}
                otherFunctions={[]}
                options={{
                    pageSize: 20,
                }}
                noAddButton={true}
            />
        )
    }

    const localSyncData = () => {
        SyncData(setData, entityNameFirebase, "UniqueRef", cleanInputs, styles.aIcon, true);
        SyncData(setDataOrganization, "CcOrganizations", "Code", cleanInputs, styles.aIcon, true)
        SyncData(setDataAgency, "CcAgency", "Code", cleanInputs, styles.aIcon, true)
        SyncData(setDataQueuePipeline, "CcQueuePipelineSetting", "UniqueRef", cleanInputs, styles.aIcon, true)
        SyncData(setDataQueueField, "CcQueueFieldSetting", "UniqueRef", cleanInputs, styles.aIcon, true)
        setIsSync(true)
    }

    useEffect(() => {
        if (!isSync) {
            localSyncData();
        }

    }, []);

    return (
        <div style={styles.container}>
            <IonLoading message={"please wait.."} duration={0} isOpen={busy}/>
            {IsEdit ?
                <div style={styles.container}>
                    <h1 style={{color: "#3880ff"}}>
                        <u>New Invite!</u>
                        <a onClick={() => cancelNew()}
                           style={styles.cancelNewPipeline}
                        >
                            <IonIcon slot="start" ios={closeCircleSharp} md={closeCircleSharp}/>
                        </a>
                    </h1>
                    {/*{buildAddInfo("OrganizationCC", InputOrganizationCC)}
                    {buildAddInfo("AgencyCC", InputAgencyCC)}*/}


                    {buildAddInputSelect(InputOrganization, "OrganizationCC", "Organization", setInputOrganization, "option", true, organizationOption)}
                    {buildAddInputSelect(InputAgency, "Agency", "Agency", setInputAgency, "option", true, agencyOption)}
                    {buildAddInputSelect(InputCategory, "Category", "Category", setInputCategory, "option", true, categoryOption)}
                    {buildAddInputSelect(InputQueue, "Queue", "Queue", setInputQueue, "option", true, queueOption)}
                    {buildAddInput(InputUserFullName, "FullName", "Full name", setInputUserFullName, "text", true, [])}
                    {buildAddInput(InputUsername, "Username", "Username or Email", setInputUsername, "text", true, [])}

                    {buildAddButton("Submit New Queue", "secondary", "#3880ff", submitForm)}
                </div> :
                <>


                    <IonList>
                        <IonLabel style={styles.titleComponent}>
                            Invite List
                            <a
                                onClick={() => requestEdit()}
                                style={styles.aIcon}

                            >
                                <IonIcon icon={addCircle} color={"primary"}>ADD NEW</IonIcon>
                            </a>
                        </IonLabel>
                    </IonList>

                    <ListBuilder/>
                </>
            }
        </div>
    )


    return (
        <div style={styles.container}>
            <IonLoading message={"please wait.."} duration={0} isOpen={busy}/>
            <FormBuilders
                changeShowList={setShowList}
                showList={showList}
                columns={columns}
                title={title}
                titleList={titleList}
                titleForm={titleForm}
                submitForm={submitForm}
                data={prepareData()}
                btnSubmitText={info.queue.batchQueue.submitNewRecordText}
            />
        </div>
    )

}

export default InvitationsPage;

const styles = {
    buildAddInfoField: {
        color: "red",
        fontSize: 12,
        marginLeft: 10
    },
    buildAddInfoValue: {
        color: "gray",
        fontSize: 12,
        marginLeft: 10
    },
    titleComponent: {
        color: "#3880ff",
        fontSize: 18,
        fontWeight: "bold",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        marginTop: 20
    },
    cancelNewPipeline: {
        float: "right",
        color: "red",
        fontSize: 19,
        fontWeight: "bold",
        cursor: "pointer"
    },
    aIcon: {
        marginLeft:20,
        fontSize: 24,
        cursor: "pointer"
    },
    container: {
        minWidth: "100%",
        padding: 20,

    }
}
