import {IonButtons, IonHeader, IonItem, IonMenuButton, IonTitle, IonToolbar} from "@ionic/react";
import LogoItem from "./LogoItem";
import React from "react";


const AuthenticationHeader: React.FC = () => {

    return(
        <IonHeader>
            <IonToolbar color="contrast" >
                <IonItem lines={"none"}>
                    <LogoItem/>
                </IonItem>

            </IonToolbar>
        </IonHeader>
    )
}

export default AuthenticationHeader;
