import React, {useEffect, useState} from "react";
import {
    IonButton,
    IonIcon,
    IonLoading,
} from "@ionic/react";
import {
    ConvertStringToBase64, escapeEmailAddress,
    findDisplayKeyInArray,
    findUserLanguage,
    SyncData
} from "../../services/general-functions";
import {PipelineSettings, QueueEntry, QueueInvitation} from "../../mocks/types";
import {InfoLang} from "../../mocks/language";
import {WorkerCheckinEntry, WorkerHandleDataEntry} from "../../../functions/src/services/types";
import store from "../../redux/store";
// @ts-ignore
import firebase from "../../configures/firebase-init";
import {RequestQueueFieldData} from "../../services/request-queue-field-data";
import {IonGrid, IonRow, IonCol, IonContent} from '@ionic/react';
import {
    arrowDown,
} from 'ionicons/icons';

import {PopupAgentProcessField} from "../../components/WorkBoardAgent/PopupAgentProcessField";
import {Tabs} from 'antd';
import {SubmitRequestProcessField} from "../../services/submit-request-process-pipeline";
import WorkerAttendantField from "./WorkerAttendantField";
import WorkerAttendantPipeline from "./WorkerAttendantPipeline";

const {TabPane} = Tabs;


const WorkerAttendantPage: React.FC = () => {

    const [busy, setBusy] = useState<boolean>(false);
    const [CurrentCheckin, setCurrentCheckin] = useState<QueueInvitation>(Object)
    const [isSync, setIsSync] = useState<boolean>(false);
    const [isLoaded, setIsLoaded] = useState<boolean>(false);

    const entityName = "CcWorkerHandleFieldData";
    const infoLogin = store.getState();
    let username = infoLogin.auth.user.username;

    const cleanInputs = () => {

    }

    const loadHandleData = () => {
        let targetsCheckinRef = firebase.database().ref(`CcQueueInvitations`);
        targetsCheckinRef.on("value", function (snapshot: any) {
            let innerRecord = snapshot.val();

            if (innerRecord !== null) {
                let myKey = escapeEmailAddress(username)
                console.log("Bridge loadHandleData > ",myKey," > ",innerRecord);
                let rec = innerRecord[myKey] as QueueInvitation
                if (typeof rec !== "undefined") {
                    setCurrentCheckin(rec);
                    setIsLoaded(true)
                }
            }
        })
    }



    useEffect(() => {
        if (!isSync) {
            loadHandleData();
            //"CcPipelineSettings"
            setIsSync(true)
            console.log("useEffect > ", isSync)
        }
    }, []);


    if(!isLoaded){
        return(
            <div style={styles.container}>
                <IonLoading message={"please wait.."} duration={0} isOpen={busy}/>
                <h1 style={{color: "#eb445a", marginLeft: 10, fontSize: 20}}>
                    veuillez patienter pendant que vous recevez les informations de votre invitation....
                </h1>
                <p style={{color:"gray"}}>Notez que cette action peut être exécutée en raison d'une invitation manquante ou d'un problème de connexion. vérifiez que vous avez une invitation valide.</p>
            </div>
        )
    }

    if(CurrentCheckin.Category==="field"){
        return (
            <WorkerAttendantField CurrentCheckin={CurrentCheckin}/>
        )
    }
    return(
        <WorkerAttendantPipeline  CurrentCheckin={CurrentCheckin}/>
    )
}
export default WorkerAttendantPage;
const styles = {
    link:{
        cursor:"pointer",
        color:"blue"  ,
        marginLeft:10
    },
    tabs:{
      backgroundColor:"lightgray",
        padding:10
    },
    btn: {
        minWidth: "100%",
        maxWidth: "100%"
    },
    IonColHeaderAllocation: {
        borderStyle: "solid",
        borderColor: "#ddd",
        backgroundColor: "#eb445a",
        marginBottom: 5,
        borderWidth: 1,
        marginLeft: 5,
        color: "white"
    },
    IonColHeader: {
        borderStyle: "solid",
        borderColor: "#ddd",
        backgroundColor: "#eb445a",
        marginBottom: 5,
        borderWidth: 1,
        marginLeft: 5,
        color: "white"
    },
    IonCol2: {
        borderStyle: "solid",
        borderColor: "#ddd",
        backgroundColor: "#f7f7f7",
        marginBottom: 5,
        borderWidth: 1,
        marginLeft: 5,
        color: "red",
        fontSize: 14,
        fontWeight: "bold",
        justifyContent: "center",
    },
    IonCol: {
        borderStyle: "solid",
        borderColor: "#ddd",
        backgroundColor: "#f7f7f7",
        marginBottom: 5,
        borderWidth: 1,
        marginLeft: 5
    },
    IonRow2: {
        borderStyle: "solid",
        borderColor: "#46b0e0",
        backgroundColor: "#f7f7f7",
        borderRadius: 5,
        borderWidth: 1,
        marginLeft: 5,
        marginBottom: 5
    },
    IonRow: {
        /*borderStyle:"solid",
        borderColor:"#ddd",
        backgroundColor: "#f7f7f7",
        marginBottom:5,
        borderWidth:1,*/
    },
    buildAddInfoField: {
        color: "red",
        fontSize: 12,
        marginLeft: 10
    },
    buildAddInfoValue: {
        color: "gray",
        fontSize: 12,
        marginLeft: 10
    },
    titleComponent: {
        color: "#3880ff",
        fontSize: 18,
        fontWeight: "bold",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        marginTop: 20
    },
    requestNewPipeline: {
        float: "right",
        color: "blue",
        fontSize: 19,
        fontWeight: "bold"
    },
    cancelNewPipeline: {
        float: "right",
        color: "red",
        fontSize: 19,
        fontWeight: "bold",
        cursor: "pointer"
    },
    aIcon: {
        fontSize: 24,
        cursor: "pointer"
    },
    container: {
        minWidth: "100%",
        padding: 20,

    }
}
