import React from "react";
import {Drawer, Form, Button, Col, Row,Menu,Collapse, Comment, Tooltip, Avatar } from 'antd';
import {CommentEntry} from "../../../functions/src/services/types";
import moment from "moment";

type myProps={
    notes:CommentEntry[]
}
export default ( props:myProps)=>{
    let {notes}=props;

    return notes.map(row=>{
        const orgDateTime = `${row.Date} ${row.Time}`;
        return(
            <Comment
                actions={[]}
                author={<a>{row.Username}</a>}
                avatar={
                    <Avatar
                        src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png"
                        alt={row.Username}
                    />
                }
                content={
                    <p>
                        <span style={styles.subject}>{row.Subject}</span><br/>
                        {row.Body}
                    </p>
                }
                datetime={
                    <Tooltip title={moment(orgDateTime,'YYYY-MM-DD HH:mm:ss').format('DD-MMM-YYYY HH:mm:ss')}>
                        <span>{moment(orgDateTime,'YYYY-MM-DD HH:mm:ss').format('DD-MMM-YYYY HH:mm:ss')}</span>
                    </Tooltip>
                }
            />
        )
    })
}

const styles={
    subject:{
        color:"red",
        fontWeight:"bold",
    }
}