import React, {useEffect, useState} from "react";
import {IonIcon, IonLabel, IonList, IonLoading} from "@ionic/react";
import {
    buildAddButton,
    buildAddInput,
    buildAddInputSelect, ConvertStringToBase64,
    findDisplayKeyInArray,
    findUserLanguage,
    FormatCodeUpCase,
    getSelectionOptions,
    SubmitFormToFirebase,
    SyncData
} from "../../services/general-functions";
import {addCircle, closeCircleSharp} from 'ionicons/icons';
import {PipelineSettings, propsColumns, SelectOption} from "../../mocks/types";
import {InfoLang, menus as ListMenus} from "../../mocks/language";
import {DefaultFormInputFields, GetInputData, OnChangeInput} from "../../services/form-inputs";
import moment from "moment";
import {QueueBatchCreated} from "../../services/queue-functions";
import TableDataView from "../../components/common/TableDataView";
import {FeedsQueueField} from "../../services/feeds-queue-field";
import {IncludeHistoryQueueField} from "../../services/include-history-queue-field";

const QueueFieldPage: React.FC = () => {
    const [currentLanguage, setCurrentLanguage] = useState("en")
    const [busy, setBusy] = useState<boolean>(false);
    const [IsEdit, setIsEdit] = useState<boolean>(false);
    const [isSync, setIsSync] = useState<boolean>(false);
    const [showList, setShowList] = useState(true);
    const [data, setData] = useState([]);

    const [InputName, setInputName] = useState("")
    const [dataOrganizationCC, setDataOrganizationCC] = useState([]);
    const [dataAgencyFA, setDataAgencyFA] = useState([]);
    const [dataAgencyCC, setDataAgencyCC] = useState([]);
    const [dataOrganizationFA, setDataOrganizationFA] = useState([]);

    const [InputOrganizationCC, setInputOrganizationCC] = useState("");
    const [InputAgencyCC, setInputAgencyCC] = useState("");
    const [InputOrganizationFA, setInputOrganizationFA] = useState("");
    const [InputAgencyFA, setInputAgencyFA] = useState("");

    const[InputRealtime,setInputRealtime]=useState("");
    const[IncludeHistory,setIncludeHistory]=useState("");

    const[inputData,setInputData]=useState(Object);


    //findUserLanguage(setCurrentLanguage, null);
    let info =ListMenus["fr"]// InfoLang(currentLanguage)
    const menus = info.invitation.all;

    const entityNameFirebase = "CcQueueFieldSetting";
    const title = menus.pageTitle;
    const titleList = menus.tableListTitle;
    const titleForm = menus.submitNewRecordText;


    const onChangeInput = (value: any, key: string) => {
        OnChangeInput(value, key, inputData, setInputData)
    }

    const getInputData = (key: string) => {
        return GetInputData(key, inputData)
    }
    const cleanInputs = () => {
        setBusy(false)
        setShowList(true)
    }
    const getSelectedInput = (key: string): string => {
        let value: string = "";
        // @ts-ignore
        if (typeof inputData[key] !== "undefined") {
            // @ts-ignore
            value = inputData[key].toString()
        }
        return value;
    }


    let organizationOptionCC: SelectOption[] = getSelectionOptions(dataOrganizationCC, "Code", "Name");
    let organizationOptionFA: SelectOption[] = getSelectionOptions(dataOrganizationFA, "Code", "Name");
    let agencyOptionCC: SelectOption[] = getSelectionOptions(dataAgencyCC, "Code", "Name");
    let agencyOptionFA: SelectOption[] = getSelectionOptions(dataAgencyFA, "Code", "Name");


    let yesNoOption: SelectOption[] = [{key: "yes", val: "Yes"}, {key: "No", val: "No"}]

    let columns: propsColumns[];
    columns = [
        DefaultFormInputFields("#", onChangeInput, getInputData, false, true, "text",
            [], "Action", ""),
        DefaultFormInputFields(menus.fieldNameLabel, onChangeInput, getInputData, true, false,
            "text", [], "Name", menus.fieldNameErr),
        DefaultFormInputFields(menus.fieldOrganizationCCLabel, onChangeInput, getInputData, true, false,
            "option", organizationOptionCC, "OrganizationCC", menus.fieldOrganizationCCError),
        DefaultFormInputFields(menus.fieldOrganizationFALabel, onChangeInput, getInputData, true, false,
            "option", organizationOptionFA, "OrganizationFA", menus.fieldOrganizationFAError),

        DefaultFormInputFields(menus.fieldAgencyCCLabel, onChangeInput, getInputData, true, false,
            "option", agencyOptionCC, "AgencyCC", menus.fieldAgencyCCError),
        DefaultFormInputFields(menus.fieldAgencyFALabel, onChangeInput, getInputData, true, false,
            "option", agencyOptionFA, "AgencyFA", menus.fieldAgencyFAError),

        DefaultFormInputFields(menus.fieldRealtimeLabel, onChangeInput, getInputData, true, false,
            "option", yesNoOption, "Realtime", menus.fieldRealtimeError),
        DefaultFormInputFields(menus.fieldIncludeHistoryLabel, onChangeInput, getInputData, true, false,
            "option", yesNoOption, "IncludeHistory", menus.fieldIncludeHistoryErr),
        DefaultFormInputFields(menus.fieldTotalLabel, onChangeInput, getInputData, false, false,
            "text", yesNoOption, "Total", menus.fieldTotalErr),

    ];

    const submitForm = async () => {

        const orgCC = InputOrganizationCC;
        const orgFA= InputOrganizationFA;
        // @ts-ignore
        const agencyCC = InputAgencyCC;
        const agencyFA = InputAgencyFA;
        // @ts-ignore
        const name = InputName;

        // @ts-ignore
        let id = moment().format("YYYYMMDDHHmmSS")
        let ref = `${orgCC}-${agencyCC}-${FormatCodeUpCase(InputName)}-${id}`;
        ref = ConvertStringToBase64(ref)

        let record = {
            Code: ref,
            OrganizationCC: orgCC,
            OrganizationFA: orgFA,
            AgencyCC: agencyCC,
            AgencyFA: agencyFA,
            Name: name,
            Realtime: InputRealtime,
            IncludeHistory: IncludeHistory,
            Ref: ref,
            UniqueRef: ref,
            Total: 0,
            Taken: 0,
        }

        setBusy(true)
        // @ts-ignore
        await SubmitFormToFirebase(record, columns, entityNameFirebase, ref, cleanInputs, menus.submitConfirmMessage);
        setIsEdit(false);
        if(IncludeHistory==="yes"){
            await IncludeHistoryQueueField(ref,orgFA,agencyFA)
        }

        setBusy(false)
    }


    const localSyncData = () => {
        SyncData(setData, entityNameFirebase, "Ref", cleanInputs, styles.aIcon, true);
        SyncData(setDataOrganizationCC, "CcOrganizations", "Code", cleanInputs, styles.aIcon, true)
        SyncData(setDataAgencyCC, "CcAgency", "Code", cleanInputs, styles.aIcon, true)

        SyncData(setDataOrganizationFA, "Organizations", "Code", cleanInputs, styles.aIcon, true)
        SyncData(setDataAgencyFA, "Agencies", "Code", cleanInputs, styles.aIcon, true)

        setIsSync(true);
    }


    const cancelNew = () => {
        setIsEdit(false);
    }
    const requestEdit = () => {
        setIsEdit(true);
    }
    const buildAddInfo = (fieldName: string, fieldValue: string) => {
        return (
            <p>
                <span style={styles.buildAddInfoField}>{fieldName}</span>:
                <span style={styles.buildAddInfoValue}>{fieldValue}</span>
            </p>
        )
    }

    const prepareData = () => {
        let tmpData: any[] = [];
        for (let i in data) {
            let row = data[i];
            let rowToUpload = row;

            // @ts-ignore
            if(rowToUpload.OrganizationCC !==InputOrganizationCC){continue}
            // @ts-ignore
            if(rowToUpload.AgencyCC !==InputAgencyCC){continue}
            // @ts-ignore
            let orgCC = findDisplayKeyInArray(row.OrganizationCC, dataOrganizationCC, "Code", "Name");
            // @ts-ignore
            let orgFA = findDisplayKeyInArray(row.OrganizationFA, dataOrganizationFA, "Code", "Name");
            // @ts-ignore
            let agencyCC = findDisplayKeyInArray(row.AgencyCC, dataAgencyCC, "Code", "Name");
            // @ts-ignore
            let agencyFA= findDisplayKeyInArray(row.AgencyFA, dataAgencyFA, "Code", "Name");



            let record = {};
            // @ts-ignore
            record.Realtime = row.Realtime;
            // @ts-ignore
            record.IncludeHistory = row.IncludeHistory;
            // @ts-ignore
            record.Action = row.Action;
            // @ts-ignore
            record.Ref = row.Ref;
            // @ts-ignore
            record.Code = row.Code;
            // @ts-ignore
            record.Name = row.Name
            // @ts-ignore
            record.Total = row.Total

            // @ts-ignore
            record.OrganizationCC = orgCC;
            // @ts-ignore
            record.OrganizationFA = orgFA;
            // @ts-ignore
            record.AgencyCC = agencyCC
            // @ts-ignore
            record.AgencyFA = agencyFA
            // @ts-ignore


            tmpData.push(record)
        }
        return tmpData;
    }
    const ListBuilder = () => {

        let dataStore = prepareData();
        let store = {columns: columns, data: dataStore}
        // @ts-ignore
        return (
            <TableDataView
                {...store}
                tableTitle={"Données  (" + data.length + ") "}
                otherFunctions={[]}
                options={{
                    pageSize: 20,
                }}
                noAddButton={true}
            />
        )
    }

    useEffect(() => {
        if (!isSync) {
            localSyncData();
        }
    }, []);


    return (
        <div style={styles.container}>
            <IonLoading message={"please wait.."} duration={0} isOpen={busy}/>
            {IsEdit ?
                <div style={styles.container}>
                    <h1 style={{color: "#3880ff"}}>
                        <u>New phase!</u>
                        <a onClick={() => cancelNew()}
                           style={styles.cancelNewPipeline}
                        >
                            <IonIcon slot="start" ios={closeCircleSharp} md={closeCircleSharp}/>
                        </a>
                    </h1>
                    {buildAddInfo("OrganizationCC", InputOrganizationCC)}
                    {buildAddInfo("AgencyCC", InputAgencyCC)}

                    {buildAddInput(InputName, "Name", "Name", setInputName, "text", true)}
                    {buildAddInputSelect(InputOrganizationCC, "OrganizationCC", "Organization Callcentre", setInputOrganizationCC, "option", true, organizationOptionCC)}
                    {buildAddInputSelect(InputOrganizationFA, "OrganizationFA", "Organization Field Agent", setInputOrganizationFA, "option", true, organizationOptionFA)}
                    {buildAddInputSelect(InputAgencyCC, "AgencyCC", "Agency Callcentre", setInputAgencyCC, "option", true, agencyOptionCC)}
                    {buildAddInputSelect(InputAgencyFA, "AgencyFA", "Agency Field Agent", setInputAgencyFA, "option", true, agencyOptionFA)}
                    {buildAddInputSelect(InputRealtime, "Realtime", "Realtime", setInputRealtime, "option", true, yesNoOption)}
                    {buildAddInputSelect(IncludeHistory, "IncludeHistory", "Include History", setIncludeHistory, "option", true, yesNoOption)}

                    {buildAddButton("Submit New Queue", "secondary", "#3880ff", submitForm)}
                </div> :
                <>


                    <IonList>
                        <IonLabel style={styles.titleComponent}>
                            Queue List
                            <a
                                onClick={() => requestEdit()}
                                style={styles.aIcon}

                            >
                                <IonIcon icon={addCircle} color={"primary"}>ADD NEW</IonIcon>
                            </a>
                        </IonLabel>
                    </IonList>
                    {buildAddInputSelect(InputOrganizationCC, "OrganizationCC", "Organization CC", setInputOrganizationCC, "option", true, organizationOptionCC)}
                    {buildAddInputSelect(InputAgencyCC, "AgencyCC", "Agency CC", setInputAgencyCC, "option", true, agencyOptionCC)}
                    <ListBuilder/>
                </>
            }
        </div>
    )

}

export default QueueFieldPage;

const styles = {
    buildAddInfoField: {
        color: "red",
        fontSize: 12,
        marginLeft: 10
    },
    buildAddInfoValue: {
        color: "gray",
        fontSize: 12,
        marginLeft: 10
    },
    titleComponent: {
        color: "#3880ff",
        fontSize: 18,
        fontWeight: "bold",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        marginTop: 20
    },
    requestNewPipeline: {
        float: "right",
        color: "blue",
        fontSize: 19,
        fontWeight: "bold"
    },
    cancelNewPipeline: {
        float: "right",
        color: "red",
        fontSize: 19,
        fontWeight: "bold",
        cursor: "pointer"
    },
    aIcon: {
        fontSize: 24,
        cursor: "pointer"
    },
    container: {
        minWidth: "100%",
        paddingRight: 20,

    }
}


