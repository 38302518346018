import React, {useState} from "react";
import {
    Inject, ScheduleComponent,
    Day, Month, WorkWeek, Week, Agenda,
    EventSettingsModel,
    ResourcesDirective,
    ResourceDirective,
    TimelineViews,
    ViewsDirective,
    ViewDirective
} from "@syncfusion/ej2-react-schedule";
import "./appointment.css";
import {DataManager, WebApiAdaptor} from "@syncfusion/ej2-data";

import moment from "moment";
import {AppointmentEntry} from "../../../functions/src/services/types";
import {convertStringDateTimeToSysTime} from "../../services/general-functions";


const convertDateEntryIntoEvent = (dataIn: AppointmentEntry[]) => {
    let dataSource = [];
    for (let i in dataIn) {
        let row = dataIn[i];
        dataSource.push({
            Id: parseInt(i),
            EndTime: convertStringDateTimeToSysTime(row.TargetDate, row.EndTime),
            StartTime: convertStringDateTimeToSysTime(row.TargetDate, row.StartTime),
            Subject: row.Subject,
            Location: row.Location,
            CategoryColor: row.CategoryColor,
            Description: row.Description,
            Organization: row.Organization,
            Status: row.Status,
            LeadInfo: row
        })
    }
    let events: EventSettingsModel = {dataSource}
    return dataSource
}

/*L10n.load({
    'en-US': {
        'schedule': {
            'saveButton': 'Add',
            'cancelButton': 'Close',
            'deleteButton': 'Remove',
            'newEvent': 'Add Event',
        },
    }
});*/
export default (props) => {
    //const [currentView,setCurrentView]=useState("Agenda");
    let {dataIn, currentView, submitAction} = props;
    dataIn.Status = 0;
    console.log("renderTabs >< 2", dataIn);
    const remoteData = new DataManager({
        url: "https://js.syncfusion.com/demos/ejservices/api/Schedule/LoadData",
        adaptor: new WebApiAdaptor,
        crossDomain: true
    })
    let resourceStatus = [
        {StatusText: 'Pending', StatusId: 1, StatusColor: '#c43081'},
        {StatusText: 'Open & Process', StatusId: 2, StatusColor: '#ff7f50'}
        /*{StatusText: 'Progress', StatusId: 2, StatusColor: '#ff7f50'},
        { StatusText: 'Waiting', StatusId: 3, StatusColor: '#AF27CD' },
        {StatusText: 'Close', StatusId: 3, StatusColor: '#808000'}*/
    ];
    const resourceAction = [
        {ActionText: 'Open', ActionId: 1, ActionColor: '#c43081'},
        {ActionText: 'Cancel', ActionId: 2, ActionColor: '#ff7f50'},
    ]
    const onChange = (info: any) => {
        console.log("onChange==> ", info);
        if (info.requestType == "eventChanged") {
            let row: AppointmentEntry = info.changedRecords[0] as AppointmentEntry;
            if(row.Status===2){
                if(window.confirm("are you sure to Open this lead?")){
                    submitAction(row)
                }
            }
        }
    }
    let currDate = moment().format("YYYY-MM-DD");
    let currTime = moment().format("HH:mm:SS");

    let targetDate = convertStringDateTimeToSysTime(currDate, currTime);
    console.log("targetDate convertStringDateTimeToSysTime(currDate,currTime) > ", targetDate);

    const onEventRendered = (args: any) => {
        console.log("onEventRendered > ", args)
        let categoryColor = args.data.CategoryColor;
        if (!args.element || !categoryColor) {
            return;
        }
        if (currentView === 'Agenda') {
            args.element.firstChild.style.borderLeftColor = categoryColor;
        } else {
            args.element.style.backgroundColor = categoryColor;
        }
    }
    const onClickAdd=()=>{
        //todo
    }
    const onClickSave=()=>{
        //todo
    }
    const onClickDelete=()=>{

    }
    return (
        <ScheduleComponent
            currentView={currentView}
            eventSettings={{dataSource: convertDateEntryIntoEvent(dataIn)}}
            selectedDate={convertStringDateTimeToSysTime(currDate, currTime)}
            onChange={onChange}
            eventRendered={onEventRendered}
            actionComplete={onChange}

        >
            <Inject services={[Day, Month, WorkWeek, Week, Agenda]} />
            <ResourcesDirective>
                <ResourceDirective
                    field='Status'
                    title='Action'
                    name='Status'
                    allowMultiple={false}
                    dataSource={resourceStatus}
                    textField='StatusText'
                    idField='StatusId'
                    colorField='StatusColor'
                />
            </ResourcesDirective>


        </ScheduleComponent>
    )
}
