import React, {useState} from "react";
import {
    IonButton,
    IonContent,
    IonIcon,
    IonItem,
    IonLabel,
    IonLoading,
    IonPage,
    IonSelect,
    IonSelectOption
} from "@ionic/react";
import {
    saveSharp

} from 'ionicons/icons';
import {ConvertBase64IntoString, csvJSON} from "../../services/general-functions";
import {GetInputData, OnChangeInput} from "../../services/form-inputs";
import {
    setFirestoreEntityWithKey2,
    toast,
    updateEntityDatabase
} from "../../services/firebase-functions";

type propsTypes = {
    setHasUpload: any,
    uploadRow: object
}

const LeadUpload: (props: propsTypes) => JSX.Element = (props: propsTypes) => {
    const [busy, setBusy] = useState<boolean>(false);
    let {setHasUpload, uploadRow} = props;
    const [fileBase64String, setFileBase64String] = useState("");
    const [fileName, setFileName] = useState("");
    const [dataJson, setDataJson] = useState({});
    const [csvContentHeader, setCsvContentHeader] = useState<string[]>([])
    const [csvContentData, setCsvContentData] = useState<object>({})
    const [ourUniqueField, setOurUniqueField] = useState("");

    const handleInputMapField = (value: any, key: string) => {
        OnChangeInput(value, key, csvContentData, setCsvContentData)
    }
    const getInputDataMapField = (key: string) => {
        return GetInputData(key, csvContentData)
    }
    const onUploadFile = () => {
        let inputUpload = document.getElementById("fileID");
        // @ts-ignore
        let file = inputUpload.files[0];
        if (!file) {
            console.log("No file selected.");
            return;
        }
        const reader = new FileReader();
        reader.onload = async () => {
            const base64string: any = reader.result;
            const fileName: any = file.name;
            setFileBase64String(base64string);
            setFileName(fileName);

            /**
             * let convert from base64 to string
             */
            let arr = base64string.split(";base64,")[1];
            let lines = ConvertBase64IntoString(arr);
            let dJson = csvJSON(lines)
            setCsvContentHeader(dJson.headers)
            // @ts-ignore
            setCsvContentData(dJson.data)
        }
        reader.readAsDataURL(file);
        reader.onerror = function (error) {
            console.log('Error uploading file: ', error);
        };
    }
    const onCancel = () => {
        setHasUpload(false)
    }
    const onSubmit = async () => {
        let entityName = "CcLeadBatchCsv";
        // @ts-ignore
        const batchRef = uploadRow.UniqueRef;

        let tmp = [];
        for (let i in csvContentData) {
            const row = csvContentData[i];
            let str = JSON.stringify(row);
            str = str.replace(`undefined`, `""`)
            str = JSON.parse(str)

            // @ts-ignore
            let keyUnique = str[ourUniqueField];
            if (typeof keyUnique === "undefined") {
                continue
            }
            if (keyUnique === "") {
                continue
            }
            tmp.push(str)
        }
        setBusy(true);
        // @ts-ignore
        const total = tmp.length;
        for (let i in tmp) {
            let row = tmp[i];
            // @ts-ignore
            row.BatchRef = batchRef
            let saveKey = parseInt(i) + 1;
            // @ts-ignore
            row.PositionRef = saveKey
            await setFirestoreEntityWithKey2(entityName, batchRef, `${saveKey}`, row,);
        }
        await updateEntityDatabase("CcLeadBatch", batchRef, {
            Total: total,
            CsvBase64String: fileBase64String,
            CsvFileName: fileName,
            Headers: csvContentHeader,
            UniqueField: ourUniqueField,
        })
        await toast('Your have saved successfully');
        setBusy(false);
        setHasUpload(false)
        //todo
    }

    const renderUniqueField = () => {
        return (
            <IonItem style={styles.wrapInput}>
                <IonLabel style={{color: "red"}}>{"Unique Field"}</IonLabel>
                <IonSelect
                    value={ourUniqueField}
                    onIonChange={(dataIn) => setOurUniqueField(dataIn.detail.value)}
                >
                    {csvContentHeader.map(item => {
                        return (
                            <IonSelectOption
                                value={item}
                            >
                                {item}
                            </IonSelectOption>
                        )
                    })}
                </IonSelect>
            </IonItem>
        )
    }
    const renderSelectBox = (label: string, name: string, options: any[], value: any, onChange: any) => {

        return (
            <IonItem style={styles.wrapInput}>
                <IonLabel style={{color: "red"}}>{label}</IonLabel>
                <IonSelect
                    value={value}
                    onIonChange={(dataIn) => onChange(dataIn.detail.value, name)}
                >
                    {options.map(rec => {
                        return (
                            <IonSelectOption
                                value={rec.key}
                            >
                                {rec.value}
                            </IonSelectOption>
                        )
                    })}
                </IonSelect>
            </IonItem>
        )
    }

    let catOptions = [
        {key: "Name", value: "Name"},
        {key: "Phone", value: "Phone number"},
        {key: "Email", value: "Email"},
        {key: "Identity", value: "Identity"},
        {key: "Other", value: "Other"},
    ]

    return (
        <IonPage>
            <IonLoading message={"please wait while uploading.."} duration={0} isOpen={busy}/>
            <IonContent>
                <div style={styles.container}>
                    <h1 style={{color: "gray"}}><u>Upload Lead</u></h1>
                    <ol>
                        <li>
                            <h3 style={styles.titleStep}>Choose file</h3>
                            <input
                                id={"fileID"}
                                type={"file"}
                                style={styles.inputFile}
                                onChange={onUploadFile}
                            />
                        </li>
                        {csvContentHeader.length > 0 &&
                        <li>
                            <h3 style={styles.titleStep}>What is your unique field?</h3>
                            {renderUniqueField()}
                        </li>
                        }
                        {csvContentHeader.length > 0 && ourUniqueField !== "" ?
                            <li>
                                <h3 style={styles.titleStep}>Map your field category</h3>
                                {csvContentHeader.map(item => {
                                    return renderSelectBox(item, item, catOptions, getInputDataMapField(item), handleInputMapField)
                                })}
                            </li> : null
                        }
                    </ol>
                    <div style={styles.btnContainer}>
                        <div style={styles.btn}>
                            <IonButton
                                style={{
                                    minWidth: "100%",
                                    borderWidth: 5,
                                    borderRadius: 5,
                                    color: "whtie",
                                    fontWeight: "bold"
                                }}
                                color={"danger"}
                                onClick={onCancel}
                                expand={"full"}
                            >
                                <IonIcon icon={saveSharp} slot={"start"}/>
                                Cancel
                            </IonButton>
                        </div>
                        <div style={styles.btn}>
                            <IonButton
                                style={{
                                    minWidth: "100%",
                                    borderWidth: 5,
                                    borderRadius: 5,
                                    color: "whtie",
                                    fontWeight: "bold"
                                }}
                                color={"primary"}
                                onClick={onSubmit}
                                expand={"full"}
                            >
                                <IonIcon icon={saveSharp} slot={"start"}/>
                                Submit
                            </IonButton>
                        </div>
                    </div>
                </div>
            </IonContent>
        </IonPage>
    )
}

export default LeadUpload;

const styles = {
    wrapInput: {
        backgroundColor: "rgba(255, 255, 255, .8)",
        borderRadius: 20,
        marginBottom: 15,
        boxShadow: "0 10px 30px 0px rgba(57, 71, 82, 0.12)"
    },
    titleStep: {
        color: "#3880ff",
        fontSize: 20,
    },
    btn: {
        minWidth: "45%",
        maxWidth: "45%"
    },

    btnContainer: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        margin: 20,
    },
    inputFile: {
        minWidth: "100%",
        maxWidth: "100%",

    },
    container: {
        marginTop: 75,
        justifyContent: "center",
        alignItems: "center",
        flex: 1,
        minWidth: "98%",
        maxWidth: "98%",
        marginLeft: 20,
    },
    title: {
        minWidth: "100%",
        borderBottomWidth: 1,
        borderColor: "gray"
    }
}
