import React from "react";
import {Comment, Tooltip, Avatar} from 'antd';
import moment from "moment";
import {CommentHistoryEntry, PipelineSettings} from "../../mocks/types";

type myProps = {
    notes: CommentHistoryEntry[],
    pipelines:PipelineSettings[]
}
export default (props: myProps) => {
    let {notes,pipelines} = props;

    const getPipelinePhaseName=(pipelineCode:string,phaseCode:string)=>{
        for(let i in pipelines){
            let row = pipelines[i];
                for(let a in row.Phases){
                    let rec = row.Phases[a];
                    if(rec.Ref ===phaseCode) {
                        return rec.Name
                    }
                }
        }
        return phaseCode
    }
    const getPipelineName=(pipelineCode:string)=>{
        for(let i in pipelines){
            let row = pipelines[i];
            if(row.Ref ===pipelineCode){
                return row.Name
            }
        }
        return pipelineCode
    }
    return notes.map(row => {
        const orgDateTime = `${row.Date} ${row.Time}`;
        return (
            <Comment
                actions={[]}
                author={<a>{row.UserFullName}</a>}
                avatar={
                    <Avatar
                        src="https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png"
                        alt={row.Username}
                    />
                }
                content={
                    <p>
                        <span style={styles.subject}>{getPipelineName(row.Pipeline)} | {getPipelinePhaseName(row.Pipeline,row.Phase)}</span><br/>
                        {row.Comment}
                    </p>
                }
                datetime={
                    <Tooltip title={moment(orgDateTime, 'YYYY-MM-DD HH:mm:ss').format('DD-MMM-YYYY HH:mm:ss')}>
                        <span>{moment(orgDateTime, 'YYYY-MM-DD HH:mm:ss').format('DD-MMM-YYYY HH:mm:ss')}</span>
                    </Tooltip>
                }
            />
        )
    })
}

const styles = {
    subject: {
        color: "red",
        fontWeight: "bold",
    }
}