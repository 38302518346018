import React from "react";
import { IonItem, IonLabel, IonSelect, IonSelectOption} from "@ionic/react";


export default (props:any)=>{
    let {onChange,label,value,options,name}=props;
    return (
        <IonItem style={styles.wrapInput}>
            <IonLabel style={{color:"gray"}}>{label}</IonLabel>
            <IonSelect
                value={value}
                onIonChange={(dataIn)=>onChange(dataIn.detail.value,name)}
            >
                {options.map(rec=>{
                    return(
                        <IonSelectOption
                            value={rec.key}
                        >
                            {rec.val}
                        </IonSelectOption>
                    )
                })}
            </IonSelect>
        </IonItem>
    )

}

const styles={
    input: {
        display: "block",
        width: "100%",
        back: "transparent",
        fontFamily: "Josefin Sans",
        fontSize: 16,
        lineHeight: 1.2,
        border: "none",
        borderRadius: 20,
        height: "40px",
        outline: "none",
        padding: "0 20px 0 23px"
    },
    wrapInput: {
        backgroundColor: "rgba(255, 255, 255, .8)",
        borderRadius: 20,
        marginBottom: 15,
        boxShadow: "0 10px 30px 0px rgba(57, 71, 82, 0.12)"
    },
}



/*
 <ion-item>
          <ion-label>Pet</ion-label>
          <ion-select value="dog">
            <ion-select-option value="cat">Cat</ion-select-option>
            <ion-select-option value="dog">Dog</ion-select-option>
          </ion-select>
        </ion-item>
 */
