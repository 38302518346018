import {
    queryFirestoreData,
    removeEntityDatabase,
    setEntityDatabaseKey,
    toast,

} from "./firebase-functions";
import {propsColumns} from "./general-types";
import firebase from "../configures/firebase-init";
import React from "react";
import { IonIcon } from "@ionic/react";
import {closeCircle, close, saveSharp} from "ionicons/icons";
import store from "../redux/store";
import {ConditionOption, FirestoreQueryCondition} from "../mocks/types";
import InputSelect from "../components/ControllerInput/InputSelect";
import TextInput from "../components/ControllerInput/TextInput";
import InputButton from "../components/ControllerInput/InputButton";
import moment from "moment";


export const IsDateBetween=(targetDate:string,startDate:string,endDate:string)=> {
    if(typeof targetDate==="undefined"){return false}
    if(typeof startDate==="undefined"){return false}
    if(typeof endDate==="undefined"){return false}

    if(targetDate===""){return false}
    if(startDate===""){return false}
    if(endDate===""){return false}

    const convertToDate = (dataIn: string) => {
        let d1 = dataIn.split("-");
        return new Date(d1[0], parseInt(d1[1]) - 1, d1[2]);
    }
    let check = convertToDate(targetDate);
    let from = convertToDate(startDate);
    let to = convertToDate(endDate);
    return (check >= from && check <= to)
}

export const setEntityDatabaseKey2=async (route: string,key:string,innerKey:string, data: any)=> {
    await firebase.database()
        .ref(route)
        .child(key)
        .child(innerKey)
        .set(data).then((snpashot:any) => {
            return {snpashot, error: null}
        }).catch((err:any) => {
            return {snpashot: null, error: err}
        })
}

export const updateEntityDatabase=async (route: string, key:string, data: any)=> {
    const path = `${route}/${key}`
    await firebase.database()
        .ref(path)
        .update(data).then((snpashot:any) => {
            return {snpashot, error: null}
        }).catch((err:any) => {
            return {snpashot: null, error: err}
        })
}
export const fetchRecords=async (path:string,fn:any)=>{
    let ref = firebase.database().ref(path);
    await ref.once('value', (snap:any) => {
        let data:any[]=[];
        let rows  = snap.val();
        let keys=Object.keys(rows);
        for(let i in keys){
            let key = keys[i];
            let row: object=rows[key];
            data.push(row);

        }
        fn(data)
    }) ;

}

export const convertStringDateTimeToSysTime=(date:string,time:string):Date=>{
    return moment(`${date} ${time}`,"YYYY-MM-DD HH:mm:SS").toDate()
    const arrDate = date.split("-");
    let year = parseInt(arrDate[0]);
    let month = parseInt(arrDate[1]);
    let day = parseInt(arrDate[2]);
    const arrTime = time.split(":");
    let hour=parseInt(arrTime[0]);
    let minute=parseInt(arrTime[1]);
    //let second=parseInt(arrTime[2]);
    return new Date(year,month,day,hour,minute);
}

export function escapeEmailAddress(email:string) :string{
    if (!email) return ""
    // Replace '.' (not allowed in a Firebase key) with ',' (not allowed in an email address)
    email = email.toLowerCase();
    email = email.replace(/\./g, '');
    email = email.replace(/\@/g, '');
    email  = email.replace(" ","");
    email  = email.replace(/\#/g,'');
    email  = email.replace(/\]/g,'');
    email  = email.replace(/\[/g,'');
    email  = email.replace(/\$/g,'');
    return email;
}

export const cancelNew = (setIsEdit:any) => {
    setIsEdit(false);
}
export const requestEdit = (setIsEdit:any) => {
    setIsEdit(true);
}
export const buildAddInfo = (fieldName: string, fieldValue: string,styleField:object,styleValue:object) => {
    return (
        <p>
            <span style={styleField}>{fieldName}</span>:
            <span style={styleValue}>{fieldValue}</span>
        </p>
    )
}


export const buildAddInputSelect = (value: any, name: string, placeholder: string, setData: any, dataType: string, required: boolean, options: any[]) => {
    return (
        <InputSelect
            label={placeholder}
            name={name}
            placeholder={placeholder}
            onChange={setData}
            typeInput={dataType}
            required={required}
            options={options}
            value={value}
        />
    )
}
export const buildAddInput = (value: any, name: string, placeholder: string, setData: any, dataType: string, required: boolean) => {
    const onChangeSelect = (val: string) => {
        const value = val === "yes"
        setData(value)
    }
    if (dataType === "boolean") {
        return (
            <InputSelect
                value={value}
                label={placeholder}
                name={name}
                placeholder={placeholder}
                onChange={setData}
                typeInput={dataType}
                required={required}
                options={[{key: "yes", val: "Yes"}, {key: "no", val: "No"}]}
            />
        )
    }
    return (
        <TextInput
            name={name}
            placeholder={placeholder}
            setValue={setData}
            typeInput={dataType}
            required={required}
        />
    )
}
export const buildAddButton = (name: string, btnType: string, backgroundColor: string, onPress: any) => {
    return (
        <InputButton
            name={name}
            textColor={btnType}
            backgroundColor={backgroundColor}
            btnOnClick={onPress}
            borderRadius={25}
            expand={"full"}
            icon={saveSharp}
            iconSlot={"start"}
        />
    )
}

export const cleanInput=(input :string)=>{
    input = input.toLocaleUpperCase();
    input=input.trim();
    input=input.replace(" ","");
    input=input.replace(" ","");
    input=ConvertStringToBase64(input)
    return input;
}
export const isValueBoolean = (variable:any) => {
    if (typeof variable === "boolean") {
        // variable is a boolean
        return true
    }
    return false
}
export const SortDataAsc = (sortKey:string, data:any[]) => {
    const keyOne = sortKey;
    if(typeof data==="undefined"){
        return [];
    }
    if(data ===null){
        return [];
    }
    data.sort((a, b) => (a[keyOne] > b[keyOne]) ? 1 : -1)
    return data;
}
export const ConvertStringToBase64=(htmlBody:string)=> {
    let s= Buffer.from(htmlBody).toString('base64');
    return s;
}
export const ConvertBase64IntoString = (b64:any) =>{
    //let b = new Buffer(b64,'base64');
    let s = Buffer.from(b64, 'base64').toString('ascii');
    console.log("convertBase64IntoString > ",s); // Outputs: "SGVsbG8gV29ybGQh"
    return s;
}
export function csvJSON(csv:any) {
    let lines = csv.split("\n");
    let result = [];
    // NOTE: If your columns contain commas in their values, you'll need
    // to deal with those before doing the next step
    // (you might convert them to &&& or something, then covert them back later)
    // jsfiddle showing the issue https://jsfiddle.net/
    let headers = lines[0].split(",");
    for (let i = 1; i < lines.length; i++) {

        let obj = {};
        let currentline = lines[i].split(",");

        for (let j = 0; j < headers.length; j++) {
            obj[headers[j]] = currentline[j];
        }
        result.push(obj);

    }
    return {
        headers: headers,
        data: result
    }
}
export function ToDataUrl(url:string, callback:any) {
    var xhr = new XMLHttpRequest();
    xhr.onload = function () {
        var reader = new FileReader();
        reader.onloadend = function () {
            callback(reader.result);
        }
        reader.readAsDataURL(xhr.response);
    };
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.send();
}

export const FormatCodeUpCase=(codeIn:string)=>{
    codeIn = codeIn.toUpperCase();
    codeIn = codeIn.trim()
    let arraySpecialChar = [`"`,`'`,`:`,`,`,`:`,`;`,`#`,`@`,`%`,`*`,`(`,`)`,`-`,`_`,`=`,`!`,`~`,`.`,`+`,`/`,` `,]
    for(let i in arraySpecialChar){
        let item = arraySpecialChar[i];
        codeIn = codeIn.replace(item,"");
    }
    codeIn = codeIn.replace("`","");
    return codeIn;
}
export const findUserLanguage=async (setFn:any,feedback:any)=>{
    setFn("fr");
    if(feedback!==null){
        feedback("fr")
    }
    return
        let infoLogin = store.getState();
        const username = infoLogin.auth.user.username;
        let conditions: FirestoreQueryCondition[] = [];
        if(username!==null ||username!==""){
            conditions.push({Key:"Username",Value:username,Operation:"=="})
        }
        let results  = await queryFirestoreData("UserLanguage",conditions);
        if(results.length>0){
            let lan = results[0].Language;
            setFn(lan);
            if(feedback!==null){
                feedback(lan)
            }
        }


}

export const findCurrentUserLanguage = (fn:any) => {
    const infoLogin = store.getState();
    let username = infoLogin.auth.user.username;
    let root = "UserLanguage";
    let lan = "en";
    let ref = firebase.firestore().collection(root);
    ref.get(function (snapshot, err) {
        let data: any = [];
        snapshot.forEach(a => {
            let rows = a.val();
            fn(rows.language)
        })
    }).then(r =>{

    })


}
export const DeleteData = async (row: any,entity:string,deleteKey:string,cleanInputs:any) => {
    if (!window.confirm("Are you sure to delete this : " + deleteKey + "?")) {
        return
    }
    await removeEntityDatabase(entity, row[deleteKey])
    await toast('Organization deleted  successfully!');
    cleanInputs();
}
export const SyncDataWithCondition = async (setData:any,entity:string,deleteKey:string,cleanInputs:any,styleDeleteIcon:object,includeDeleteBtn:boolean,conditions:object) => {
    let root = entity;
    let ref = firebase.database().ref(root);
    for(let i in conditions){
        // @ts-ignore
        let v = conditions[i];
        if(typeof v==="undefined"){
            v="none"
        }

        ref.orderByChild(i).equalTo(v)
    }

    ref.on("value", function (snapshot, err) {
        let data: any = [];
        snapshot.forEach(a => {
            let rows = a.val();
            if(includeDeleteBtn){
                rows.Action = (
                    <a
                        onClick={() => DeleteData(rows,entity,deleteKey,cleanInputs)}
                        style={styleDeleteIcon}
                    >
                        <IonIcon icon={close} color={"danger"}></IonIcon>
                    </a>
                )
            }
            data.push(rows)
        })
        setData(data)
    });
}

export const DatabaseFetcher=async (path:string,fn:any)=>{
    let ref = firebase.database().ref(path);
    await ref.once("value", function (snapshot, err) {
        let data: any = [];
        snapshot.forEach(a => {
            let rows = a.val();
            data.push(rows)
        });
        fn(data)
    });
}
export const SyncData = async (setData:any,entity:string,deleteKey:string,cleanInputs:any,styleDeleteIcon:object,includeDeleteBtn:boolean) => {
    let root = entity;
    let ref = firebase.database().ref(root);
    ref.on("value", function (snapshot, err) {
        let data: any = [];

        snapshot.forEach(a => {
            let rows = a.val();
            if(includeDeleteBtn){
                rows.Action = (
                    <a
                        onClick={() => DeleteData(rows,entity,deleteKey,cleanInputs)}
                        style={styleDeleteIcon}
                    >
                        <IonIcon icon={close} color={"danger"}></IonIcon>
                    </a>
                )
            }
            data.push(rows)
        })
        setData(data)
    });
}
export const SyncDataOrigin= async (setData:any,entity:string) => {
    let root = entity;
    let ref = firebase.database().ref(root);
    ref.on("value", function (snapshot, err) {
        let data: any = [];
        snapshot.forEach(a => {
            let rows = a.val();
            data.push(rows)
        })
        setData(data)
    });
}
export const validationInput = (columns:propsColumns[],inputData:object) => {
    for (let i in columns) {
        let row = columns[i];
        // @ts-ignore
        let val = inputData[row.id]
        if (row.required) {
            if(typeof val==="undefined"){
                alert(row.errorMsg)
                return false
            }
            if( val===""){
                alert(row.errorMsg)
                return false
            }

        }
    }

    return true
}
export const UpdateFormToFirebase = async (inputData:object,entity:string,key:string) => {
    await updateEntityDatabase(entity, key, {...inputData});
}

export const SubmitFormToFirebase2 = async (inputData:object,entity:string,key:string,confirMessage:string) => {
    if (!window.confirm(confirMessage)) {
        return
    }
    await setEntityDatabaseKey(entity, key, {...inputData});
    await toast('Your have saved successfully');
}

export const SubmitFormToFirebase = async (inputData:object,columns:propsColumns[],entity:string,key:string,cleanInputs:any,confirMessage:string) => {
    let boo = validationInput(columns,inputData);
    if (!validationInput(columns,inputData)) {
        return
    }
    if (!window.confirm(confirMessage)) {
        return
    }
    await setEntityDatabaseKey(entity, key, {...inputData});
    await toast('Your have saved successfully');
    if(cleanInputs!==null){
        cleanInputs();
    }

}

export  const getSelectionOptionsWithConditions=(rawData:any[],fieldKey:string,fieldDisplay:string,conditions:ConditionOption[]):[]=>{

    let records=[];
    for(let i in rawData){
        let row = rawData[i];
        let matchCondition:boolean=true;
        /**
         * let make condition
         */
        for(let y in conditions){
            let cond = conditions[y];
            const storeValue = row[cond.key];
            if(storeValue!==cond.val){
                matchCondition=false
            }
            console.log("getSelectionOptionsWithConditions > ! ",storeValue," > ",cond.val)
        }

        if(matchCondition){
            records.push({
                key:row[fieldKey],
                val:row[fieldDisplay],
            })
        }
    }
    // @ts-ignore
    return records
}

export  const getSelectionOptions=(rawData:any[],fieldKey:string,fieldDisplay:string):[]=>{
    type OptionsType={
        key:string,
        val:any
    }
    let records=[];
    for(let i in rawData){
        let row = rawData[i];
        records.push({
            key:row[fieldKey],
            val:row[fieldDisplay],
        })
    }
    // @ts-ignore
    return records
}
export const findDisplayKeyInArray=(value:string,rawData:any[],checkKey:string,returnKey:string)=>{
    let name = value;
    for(let i in rawData){
        let row = rawData[i];
        let val=row[checkKey];
        if(value!==val){
            continue
        }
        name = row[returnKey]
    }
    return name;
}
