
import firebase from "firebase"

let  firebaseConfig = {
    apiKey: "AIzaSyAk4q4mJSPNUB7ZOqhv6HdVZeJUhPfiDP0",
    authDomain: "fieldagent-d7b51.firebaseapp.com",
    databaseURL: "https://fieldagent-d7b51-default-rtdb.firebaseio.com",
    projectId: "fieldagent-d7b51",
    storageBucket: "fieldagent-d7b51.appspot.com",
    messagingSenderId: "704341259642",
    appId: "1:704341259642:web:4e970fbc38f614402712c3",
    measurementId: "G-S94EH5L3R8"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();
firebase.firestore().settings({ timestampsInSnapshots: true });
export default firebase
