import React from "react";
import {Col, Row, Form, Button,DatePicker, Input, Select, Space,TimePicker} from "antd";
import moment from "moment";
// @ts-ignore
export default ({setData,label,mode})=>{

    if(mode==="date"){
        return(
            <Row gutter={16} style={{margin:10}}>
                <Col span={24}>
                    <Space direction="vertical" style={{maxWidth: "100%", minWidth: "100%"}}>
                        <DatePicker
                            style={{maxWidth: "100%", minWidth: "100%"}}
                            placeholder={label}
                            onChange={(date, dateString) => setData( dateString)}
                        />
                    </Space>
                </Col>
            </Row>
        )
    }else{
        return(
            <Row gutter={16} style={{margin:10}}>
                <Col span={24}>
                    <Space direction="vertical" style={{maxWidth: "100%", minWidth: "100%"}}>
                        <TimePicker
                            style={{maxWidth: "100%", minWidth: "100%"}}
                            placeholder={label}
                            onChange={(date, dateString) => setData( dateString)}
                        />
                    </Space>
                </Col>
            </Row>
        )
    }

}
