import React, {useState, useEffect} from "react";
import FormBuilders from "../../components/common/FormBuilders";
import {IonIcon, IonLoading} from "@ionic/react";
import {
    findUserLanguage, getSelectionOptions,
    SubmitFormToFirebase,
    SyncData,
    findDisplayKeyInArray, FormatCodeUpCase
} from "../../services/general-functions";
import {
    cloudUpload
} from 'ionicons/icons';
import {propsColumns, SelectOption} from "../../mocks/types";
import {InfoLang, menus as ListMenus} from "../../mocks/language";
import {OnChangeInput, GetInputData, DefaultFormInputFields} from "../../services/form-inputs";
import LeadUpload from "../../components/Lead/LeadUpload";
import moment from "moment";
import {BuildQueueBatchCsvData, QueueBatchCreated} from "../../services/queue-functions";

const InvitationPipelineAdminPage: React.FC = () => {
    const [currentLanguage, setCurrentLanguage] = useState("en")
    const [busy, setBusy] = useState<boolean>(false);
    const [isSync, setIsSync] = useState<boolean>(false);
    const [showList, setShowList] = useState(true);
    const [data, setData] = useState([]);
    const [dataOrganization,setDataOrganization]=useState([]);
    const [dataAgency,setDataAgency]=useState([]);
    const [dataQueue,setDataQueue]=useState([])

    const [inputData, setInputData] = useState<object>({})

    //findUserLanguage(setCurrentLanguage, null);
    let info =ListMenus["fr"]// InfoLang(currentLanguage)
    const menus = info.invitation.all;

    const entityNameFirebase = "CcQueuePipelineInvitations";
    const title = menus.pageTitle;
    const titleList = menus.tableListTitle;
    const titleForm = menus.submitNewRecordText;

    const onChangeInput = (value: any, key: string) => {
        OnChangeInput(value, key, inputData, setInputData)
    }
    const getInputData = (key: string) => {
        return GetInputData(key, inputData)
    }
    const cleanInputs = () => {
        setBusy(false)
        setInputData({})
        setShowList(true)
    }

    let organizationOption: SelectOption[] = getSelectionOptions(dataOrganization, "Code", "Name");
    let agencyOption: SelectOption[] = getSelectionOptions(dataAgency, "UniqueRef", "Name");
    let queueOption: SelectOption[] = getSelectionOptions(dataQueue, "UniqueRef", "Name");


    let columns: propsColumns[];
    columns = [
        DefaultFormInputFields("#", onChangeInput, getInputData, false, true, "text",
            [], "Action", ""),
        DefaultFormInputFields(menus.fieldOrganizationLabel, onChangeInput, getInputData, true, false,
            "option", organizationOption, "Organization", menus.fieldOrganizationError),
        DefaultFormInputFields(menus.fieldAgencyLabel, onChangeInput, getInputData, true, false,
            "option", agencyOption, "Agency", menus.fieldAgencyError),
        DefaultFormInputFields(menus.fieldQueueLabel, onChangeInput, getInputData, true, false,
            "option", queueOption, "Queue", menus.fieldQueueError),

        DefaultFormInputFields(menus.fieldUserNameLabel, onChangeInput, getInputData, true, false,
            "text", [], "UserFullName", menus.fieldUserNameErr),
        DefaultFormInputFields(menus.fieldUserEmailLabel, onChangeInput, getInputData, true, false,
            "text", [], "Username", menus.fieldUserEmailErr),
        DefaultFormInputFields(menus.fieldTotalLabel, onChangeInput, getInputData, true, true,
            "text", [], "Total", menus.fieldTotalErr),

    ];

    const submitForm = async () => {

        // @ts-ignore
        const org =inputData["Organization"]
        // @ts-ignore
        const agency = inputData["Agency"];
        // @ts-ignore
        const queue = inputData["Queue"];

        // @ts-ignore

        let id = moment().format("YYYYMMDDHHmmSS")
        const ref = `${queue}--${id}`;

        // @ts-ignore
        inputData["Code"] = ref;
        // @ts-ignore
        inputData["UniqueRef"]=ref;

        // @ts-ignore
        inputData["Total"]=0

        console.log("submitForm (:)--> ",inputData)

        // @ts-ignore
        await SubmitFormToFirebase(inputData, columns, entityNameFirebase, ref, cleanInputs, menus.submitConfirmMessage)

    }

    const prepareData = () => {
        let tmpData: any[] = [];

        for (let i in data) {
            let row = data[i];
            let rowToUpload =row;
            // @ts-ignore
            let org = findDisplayKeyInArray(row.Organization, dataOrganization, "Code", "Name");
            // @ts-ignore
            let agency = findDisplayKeyInArray(row.Agency, dataAgency, "Code", "Name");

            // @ts-ignore
            let queue = findDisplayKeyInArray(row.Queue, dataQueue, "UniqueRef", "Name");


            // @ts-ignore
            row.Organization = org;
            // @ts-ignore
            row.Agency = agency
            // @ts-ignore
            row.Queue = queue

            // @ts-ignore
            row.Upload = <a onClick={()=>requestUpload(rowToUpload)} style={{cursor:"pointer"}}>
                <IonIcon slot="start" ios={cloudUpload} md={cloudUpload} style={{minWidth:44,minHeight:44}}/>
            </a>


            tmpData.push(row)
        }
        return data;
    }

    const localSyncData = () => {
        SyncData(setData, entityNameFirebase, "UniqueRef", cleanInputs, styles.aIcon, true);
        SyncData(setDataOrganization, "CcOrganizations", "Code", cleanInputs, styles.aIcon, true)
        SyncData(setDataAgency, "CcAgency", "Code", cleanInputs, styles.aIcon, true)
        SyncData(setDataQueue, "CcQueuePipelineSetting", "UniqueRef", cleanInputs, styles.aIcon, true)
        setIsSync(true)
    }

    useEffect(() => {
        if (!isSync) {
            localSyncData();
        }

    }, []);


    return (
        <div style={styles.container}>
            <IonLoading message={"please wait.."} duration={0} isOpen={busy}/>
            <FormBuilders
                changeShowList={setShowList}
                showList={showList}
                columns={columns}
                title={title}
                titleList={titleList}
                titleForm={titleForm}
                submitForm={submitForm}
                data={prepareData()}
                btnSubmitText={info.queue.batchQueue.submitNewRecordText}
            />
        </div>
    )

}

export default InvitationPipelineAdminPage;

const styles = {
    aIcon: {
        fontSize: 24,
        cursor: "pointer"
    },
    container: {
        minWidth: "100%",
        paddingRight: 20,

    }
}


