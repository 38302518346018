
import otherReducer from "./reducer";

import {combineReducers} from 'redux';
import {
    firebaseReducer
} from 'react-redux-firebase'

const rootReducer =combineReducers({
    auth:otherReducer,
    firebase: firebaseReducer
});

export default rootReducer;
