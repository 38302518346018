import {IonButtons, IonContent, IonHeader, IonMenuButton, IonPage, IonTitle, IonToolbar} from '@ionic/react';
import {useParams} from 'react-router';
import ExploreContainer from '../components/ExploreContainer';
import './Page.css';
import {useEffect, useState} from "react";
import {SyncData} from "../services/general-functions";
import {typeLanguage} from "../mocks/types";
import store from "../redux/store";

import CommonHomePage from "./common/CommonHomePage";
import AuthLoginPage from "./auth/AuthLoginPage";
import AuthLogoutPage from "./auth/AuthLogoutPage";
import StructureOrganizationPage from "./Structures/StructureOrganizationPage";
import StructureAgencyPage from "./Structures/StructureAgencyPage";
import StructureTeamPage from "./Structures/StructureTeamPage";
import LeadBatchSourcePage from "./leads/LeadBatchSourcePage";
import LeadBatchNamePage from "./leads/LeadBatchNamePage";
import QueueBatchCsvPage from "./Queues/QueueBatchCsvPage";
import InvitationBatchAdminPage from "./Invitations/InvitationBatchAdmin";
import PipelineManagementPage from "./Pipelines/PipelineManagementPage";
import QueuePipelinePage from "./Queues/QueuePipelinePage";
import QueueFieldPage from "./Queues/QueueFieldPage";
import InvitationFieldAdminPage from "./Invitations/InvitationFieldPage";
import InvitationPipelineAdminPage from "./Invitations/InvitationPipelineAdminPage";
import WorkerCheckinPage from "./Worker/WorkderCheckinPage";
import WorkerAttendantPage from "./Worker/WorkerAttendantPage";
import AuthRegisterPage from "./auth/AuthRegisterPage";
import InvitationsPage from "./Invitations/InvitationsPage";
import AppointmentDefault from "./Profiles";
import PredefinedDispositionTitlePage from "./Predefined/PredifineDispotionTitlePage";
import PredefinedReminderTitlePage from "./Predefined/PredefinedReminderTitlePage";
import PredefinedCommentTitlePage from "./Predefined/PredefinedCommentTitlePage";
import PredefinedNoteTitlePage from "./Predefined/PredefinedNoteTitlePage";
import {list} from "ionicons/icons";
import {menus} from "../mocks/language";
import WorkerReportPage from "./Worker/WorkerReportPage";

interface AppPage {
    url: string;
    iosIcon: string;
    mdIcon: string;
    title: string;
}

const Page: React.FC = () => {
    const [isSync, setIsSync] = useState<boolean>(false);
    //const [menus, setMenus] = useState("en");
    const [currentLanguage,setCurrentLanguage] = useState("en");
    const [users,setUsers]=useState([])
    const {name} = useParams<{ name: string; }>();

    const setupLanguage=(data:typeLanguage[])=>{
        const infoLogin = store.getState();
        let username = infoLogin.auth.user.username;
        let tmpLang = currentLanguage;
        data.forEach(row=>{
            if(row.Username===username){
                tmpLang = row.Language
            }
        });
        setCurrentLanguage(tmpLang);
    }
    const localSync=async ()=>{
        SyncData(setupLanguage, "users", "Username", null, {}, false);
        setIsSync(true)
    }
    const switchComponent=()=> {
        if (name === "common-home") {
            return <CommonHomePage/>
        }
        if (name === "auth-login") {
            return <AuthLoginPage/>
        }
        if (name === "auth-register") {
            return <AuthRegisterPage/>
        }
        if (name === "auth-logout") {
            return <AuthLogoutPage/>
        }
        if (name === "setting-structure-organization") {
            return <StructureOrganizationPage/>
        }
        if (name === "setting-structure-agency") {
            return <StructureAgencyPage/>
        }
        if (name === "setting-structure-team") {
            return <StructureTeamPage/>
        }
        if (name === "setting-lead-batch-source") {
            return <LeadBatchSourcePage/>
        }
        if (name === "setting-lead-batch-name") {
            return <LeadBatchNamePage/>
        }
        if (name === "setting-queue-admin-batch") {
            return <QueueBatchCsvPage/>
        }
        if (name === "setting-invitation-admin-batch") {
            return <InvitationBatchAdminPage/>
        }
        if (name === "setting-invitation-admin-field") {
            return <InvitationFieldAdminPage/>
        }
        if (name === "setting-invitation-admin-pipeline") {
            return <InvitationPipelineAdminPage/>
        }


        if (name === "auth-profile") {
            return <AppointmentDefault/>
        }
        if(name==="setting-channel-pipeline-setup-tree"){
            return <PipelineManagementPage/>
        }

        if(name==="setting-queue-admin-pipe"){
            return <QueuePipelinePage/>
        }

        if(name==="setting-queue-admin-field"){
            return <QueueFieldPage/>
        }

        if(name==="worker-checkin"){
            return <WorkerCheckinPage/>
        }
        if(name==="worker-attendant"){
            return <WorkerAttendantPage/>
        }
        if(name==="setting-invitations"){
            return<InvitationsPage />
        }

        if(name==="setting-predefined-disposition"){
            return<PredefinedDispositionTitlePage />
        }
        if(name==="setting-predefined-reminder"){
            return<PredefinedReminderTitlePage />
        }
        if(name==="setting-predefined-comment"){
            return<PredefinedCommentTitlePage />
        }
        if(name==="setting-predefined-note"){
            return<PredefinedNoteTitlePage />
        }
        if(name==="worker-dashboard"){
            return<WorkerReportPage />
        }


        return <CommonHomePage/>

    }
    useEffect(() => {
        if (!isSync) {
            localSync();
        }
    }, []);

    // @ts-ignore
    let info = menus["fr"]

    const appPagesAuth: AppPage[] = [
        {
            title: 'Se connecter',
            url: '/page/auth-login',
            iosIcon: list,
            mdIcon: list
        },
    ];
    const appPagesProfile: AppPage[] = [
        {
            title: 'Se déconnecter',
            url: '/page/auth-logout',
            iosIcon: list,
            mdIcon: list
        },
        {
            title: 'Mon Profil',
            url: '/page/auth-profile',
            iosIcon: list,
            mdIcon: list
        },
    ];
    const appPagesAgent: AppPage[] = [
        /*{
            title: info.menu.agent.checkin,
            url: '/page/worker-checkin',
            iosIcon: list,
            mdIcon: list
        },*/
        {
            title: info.menu.agent.attendant,
            url: '/page/worker-attendant',
            iosIcon: list,
            mdIcon: list
        },
        {
            title: info.menu.agent.dashboard,
            url: '/page/worker-dashboard',
            iosIcon: list,
            mdIcon: list
        },
    ];
    const appPagesSettingDataSource: AppPage[] = [
        {
            title: info.menu.channelBatch.batchSource,
            url: '/page/setting-lead-batch-source',
            iosIcon: list,
            mdIcon: list
        },
        {
            title: info.menu.channelBatch.batchInfo,
            url: '/page/setting-lead-batch-name',
            iosIcon: list,
            mdIcon: list
        },
        /*{
            title: info.menu.lead.batchQueue,
            url: '/page/setting-lead-batch-queue',
            iosIcon: gitNetwork,
            mdIcon: gitNetwork
        },*/
    ]
    const appPagesSettingStructure: AppPage[] = [
        {
            title: info.menu.structure.organization,
            url: '/page/setting-structure-organization',
            iosIcon: list,
            mdIcon: list
        },
        {
            title: info.menu.structure.agency,
            url: '/page/setting-structure-agency',
            iosIcon: list,
            mdIcon: list
        },
        {
            title: info.menu.structure.team,
            url: '/page/setting-structure-team',
            iosIcon: list,
            mdIcon: list
        },
    ];
    const appPagesQueueAdmin: AppPage[] = [
        {
            title: info.menu.queue.queueBatch,
            url: '/page/setting-queue-admin-batch',
            iosIcon: list,
            mdIcon: list
        },
        {
            title: info.menu.queue.queueField,
            url: '/page/setting-queue-admin-field',
            iosIcon: list,
            mdIcon: list
        },
        {
            title: info.menu.queue.queuePipeLine,
            url: '/page/setting-queue-admin-pipe',
            iosIcon: list,
            mdIcon: list
        }
    ];
    const appPagesInvitationAdmin: AppPage[] = [
        {
            title: info.menu.invitation.all,
            url: '/page/setting-invitations',
            iosIcon: list,
            mdIcon: list
        }

    ]
    const appPagesChannelPipelineAdmin: AppPage[] = [
        {
            title: info.menu.channelPipeline.setupTree,
            url: '/page/setting-channel-pipeline-setup-tree',
            iosIcon: list,
            mdIcon: list
        },
    ];
    const appPagesPredefined: AppPage[] = [
        {
            title: info.menu.predefined.disposition,
            url: '/page/setting-predefined-disposition',
            iosIcon: list,
            mdIcon: list
        },
        {
            title: info.menu.predefined.reminder,
            url: '/page/setting-predefined-reminder',
            iosIcon: list,
            mdIcon: list
        },
        {
            title: info.menu.predefined.comment,
            url: '/page/setting-predefined-comment',
            iosIcon: list,
            mdIcon: list
        },
        {
            title: info.menu.predefined.note,
            url: '/page/setting-predefined-note',
            iosIcon: list,
            mdIcon: list
        },
    ];

    const getMenuTitle=()=>{
        let title  = name;
        for(let i in appPagesAuth){
            let row = appPagesAuth[i];
            let url = row.url;
            if(url.includes(name)){
                title = row.title
            }
        }
        for(let i in appPagesProfile){
            let row = appPagesProfile[i];
            let url = row.url;
            if(url.includes(name)){
                title = row.title
            }
        }
        for(let i in appPagesAgent){
            let row = appPagesAgent[i];
            let url = row.url;
            if(url.includes(name)){
                title = row.title
            }
        }
        for(let i in appPagesSettingDataSource){
            let row = appPagesSettingDataSource[i];
            let url = row.url;
            if(url.includes(name)){
                title = row.title
            }
        }
        for(let i in appPagesSettingStructure){
            let row = appPagesSettingStructure[i];
            let url = row.url;
            if(url.includes(name)){
                title = row.title
            }
        }
        for(let i in appPagesQueueAdmin){
            let row = appPagesQueueAdmin[i];
            let url = row.url;
            if(url.includes(name)){
                title = row.title
            }
        }
        for(let i in appPagesInvitationAdmin){
            let row = appPagesInvitationAdmin[i];
            let url = row.url;
            if(url.includes(name)){
                title = row.title
            }
        }
        for(let i in appPagesChannelPipelineAdmin){
            let row = appPagesChannelPipelineAdmin[i];
            let url = row.url;
            if(url.includes(name)){
                title = row.title
            }
        }
        for(let i in appPagesPredefined){
            let row = appPagesPredefined[i];
            let url = row.url;
            if(url.includes(name)){
                title = row.title
            }
        }

        return title;
    }

    return (
        <IonPage>
            <IonHeader>
                <IonToolbar>
                    <IonButtons slot="start">
                        <IonMenuButton/>
                    </IonButtons>
                    <IonTitle style={{color:"#1e2023"}}>{getMenuTitle()}</IonTitle>
                </IonToolbar>
            </IonHeader>

            <IonContent fullscreen>
                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large">{name}</IonTitle>
                    </IonToolbar>
                </IonHeader>
                {switchComponent()}
            </IonContent>
        </IonPage>
    );
};

export default Page;
