import React from "react";
import myLogo from "../assets/images/icon.png";
import {IonAvatar, IonItem, IonLabel} from "@ionic/react";

const LogoItem: React.FC = () => {


    return (
        <IonItem style={styles.container}>
            <IonAvatar slot={"start"}>
                <img src={myLogo} style={styles.img} alt={"logo"}/>
            </IonAvatar>
            <IonLabel style={styles.titleLabel}>SWALY</IonLabel>
        </IonItem>

    )

}

export default LogoItem;
const styles = {
    titleLabel:{
        color:"#2fdf75",
        fontWeight:"bold",
        fontSize:16
    },
    container: {
        display: "flex",
        flexDirection: "row",
        minWidth: 150,
        maxWidth: 150,
    },
    img: {
        width: 35,
        minWidth: 35,
        height: 35,
        borderRadius: 5,
        backgroundColor: "black",
        resizeMode: "contain"
    }
}
