import React, {useEffect, useState} from "react";
import {
    IonButton,
    IonIcon,
    IonLoading,
} from "@ionic/react";
import {
    convertStringDateTimeToSysTime,
    escapeEmailAddress,
    findDisplayKeyInArray,
    findUserLanguage,
    SyncData
} from "../../services/general-functions";
import {CommentHistoryEntry, PipelineSettings, QueueEntry, SelectOption} from "../../mocks/types";
import {InfoLang, menus as ListMenus} from "../../mocks/language";
import {
    AppointmentEntry,
    CommentEntry, PredefinedEntry,
    WorkerHandleDataEntry
} from "../../../functions/src/services/types";
import store from "../../redux/store";
// @ts-ignore
import firebase from "../../configures/firebase-init";
import {RequestQueueFieldData} from "../../services/request-queue-field-data";
import {IonGrid, IonRow, IonCol, IonContent} from '@ionic/react';
import {
    arrowDown,
} from 'ionicons/icons';

import {PopupAgentProcessField} from "../../components/WorkBoardAgent/PopupAgentProcessField";
import {Tabs} from 'antd';
import {SubmitRequestProcessField} from "../../services/submit-request-process-field";
import {RequestQueuePipelineData} from "../../services/request-queue-pipeline-data";
import {removeEntityDatabase, toast} from "../../services/firebase-functions";
import {PopupAgentReminder} from "../../components/WorkBoardAgent/PopupAgentReminder";
import moment from "moment";
import {SubmitRequestProcessReminder} from "../../services/submit-request-process-reminder";
import Appointiment from "../../components/WorkBoardAgent/Appointiment";
import {PopupAgentComment} from "../../components/WorkBoardAgent/PopupAgentComment";
import {saveDataIntoOneRef} from "../../services/submit-common";
import NotesAgent from "../../components/WorkBoardAgent/NotesAgent";
import {SubmitRequestProcessComment} from "../../services/submit-request-process-comment";
import {RecycleQueue} from "../../services/recycle-queue";

const {TabPane} = Tabs;


const WorkerAttendantField = ({CurrentCheckin}) => {
    const [currentLanguage, setCurrentLanguage] = useState("en")
    const [busy, setBusy] = useState<boolean>(false);
    const [IsAllocated, setIsAllocated] = useState<boolean>(false);
    const [NoDataFind, setNoDataFind] = useState<boolean>(false);
    const [isSync, setIsSync] = useState<boolean>(false);
    const [isReminder, setIsReminder] = useState<boolean>(false);
    const [dataQueueFields, setDataQueueFields] = useState<QueueEntry[]>([]);
    const [CurrentHandleData, setCurrentHandleData] = useState<WorkerHandleDataEntry>();
    const [OptionsComment, setOptionsComment] = useState<SelectOption[]>([])
    const [dataPipelines, setDataPipelines] = useState<PipelineSettings[]>([]);
    const [dataAppointments, setDataAppointments] = useState<AppointmentEntry[]>([]);
    const [dataNotes, setDataNotes] = useState<CommentEntry[]>([]);


    const [showPopoverField, setShowPopoverField] = useState<boolean>(false)
    const [showPopoverComment, setShowPopoverComment] = useState<boolean>(false)
    const [eventPopoverField, setEventPopoverField] = useState<any>(null);
    const [InputProcessFieldComment, setInputProcessFieldComment] = useState<string>("");
    const [InputProcessFieldPipeline, setInputProcessFieldPipeline] = useState<string>("");
    const [InputProcessFieldPhase, setInputProcessFieldPhase] = useState<string>("");
    const [InputProcessFieldWhatsApp, setIsWhatsApp] = useState<string>("");


    const [Counter, setCounter] = useState<number>(0);

    const [DataPredefinedDisposition, setDataPredefinedDisposition] = useState<PredefinedEntry[]>([])
    const [DataPredefinedReminder, setDataPredefinedReminder] = useState<PredefinedEntry[]>([])
    const [DataPredefinedComment, setDataPredefinedComment] = useState<PredefinedEntry[]>([])
    const [DataPredefinedNote, setDataPredefinedNote] = useState<PredefinedEntry[]>([])


    const [InputReminderComment, setInputReminderComment] = useState("");
    const [InputReminderDate, setInputReminderDate] = useState("");
    const [InputReminderTime, setInputReminderTime] = useState("");
    const [InputCommentNote, setInputCommentNote] = useState("");


    //findUserLanguage(setCurrentLanguage, null);
    let info =ListMenus["fr"]// InfoLang(currentLanguage)
    const menus = info.invitation.all;

    const entityName = "CcWorkerHandleFieldData";
    const infoLogin = store.getState();
    let username = infoLogin.auth.user.username;


    const cleanInputs = () => {

    }
    const hasNoData = () => {
        setNoDataFind(true);
        setCurrentHandleData(Object);

    }
    const requestReminder = () => {
        setIsReminder(true)
    }
    const requestComment = () => {
        setShowPopoverComment(true);
    }
    const closeComment = () => {
        setShowPopoverComment(false);
    }
    const closeReminder = () => {
        setIsReminder(false)
    }

    const getPhoneFromLead = () => {
        let phone = "";
        let dataRecord = CurrentHandleData.Data;
        if (typeof dataRecord.CustomerPhone !== "undefined") {
            if (dataRecord.CustomerPhone !== "") {
                phone = dataRecord.CustomerPhone;
            }
        }
        if (typeof dataRecord.CustomerContactNumber !== "undefined") {
            if (dataRecord.CustomerContactNumber !== "") {
                phone = dataRecord.CustomerContactNumber;
            }
        }
        return phone;
    }
    const getSiteFromLead = () => {
        let site = "";
        let dataRecord = CurrentHandleData.Data;
        if (typeof dataRecord.Site !== "undefined") {
            if (dataRecord.Site !== "") {
                site = dataRecord.Site;
            }
        }
        return site;
    }
    const getNameFromLead = () => {
        let customer = "";
        let dataRecord = CurrentHandleData.Data;
        if (typeof dataRecord.CustomerName !== "undefined") {
            if (dataRecord.CustomerName !== "") {
                customer = dataRecord.CustomerName;
            }
        }
        if (typeof dataRecord.FullName !== "undefined") {
            if (dataRecord.FullName !== "") {
                customer = dataRecord.FullName;
            }
        }
        return customer;
    }
    const submitComment = async () => {
        let record: CommentEntry = {
            Organization: CurrentCheckin.Organization,
            Agency: CurrentCheckin.Agency,
            Date: moment().format("YYYY-MM-DD"),
            Time: moment().format("HH:mm:ss"),
            Username: username,
            Category: "Note",
            Subject: 'Note',
            Body: InputCommentNote,
            Id: "",
        }
        record.Id = `${record.Organization}Zz${record.Agency}Zz${escapeEmailAddress(username)}Zz${moment().format("YYYYMMDD")}Zz${moment().format("HHmmSS")}`;
        record.Id = record.Id.replace(" ", "");
        record.Id = record.Id.trim();

        console.log("submitReminder > ", record)
        setBusy(true);
        closeComment();
        await saveDataIntoOneRef("CcWorkerNotes", record.Id, record);
        setBusy(false)
    }
    const submitReminder = async () => {
        let endTime = convertStringDateTimeToSysTime(InputReminderDate, InputReminderTime);
        endTime.setMinutes(endTime.getMinutes() + 20);
        //todo
        let record: AppointmentEntry = {
            Organization: CurrentCheckin.Organization,
            Agency: CurrentCheckin.Agency,
            LeadData: CurrentHandleData,
            TargetDate: InputReminderDate,
            Description: InputReminderComment,
            StartTime: InputReminderTime,
            EndTime: moment(endTime).format("HH:mm:SS"),
            Date: moment().format("YYYY-MM-DD"),
            Time: moment().format("HH:mm:ss"),
            Username: username,
            OrderRef: getPhoneFromLead(),
            Category: "QueueField",
            Location: getSiteFromLead(),
            CreatedBy: username,
            CategoryColor: "#357cd2",
            Status: 1,
            Subject: `${getNameFromLead()} | ${getPhoneFromLead()}`,
            QueueRef: CurrentHandleData.QueueRef,
            InRef: CurrentHandleData.InRef,
            Id: "",
        }
        record.Id = `${record.Organization}Zz${record.Agency}Zz${record.OrderRef}Zz${moment().format("YYYYMMDD")}Zz${moment().format("HHmmSS")}`;
        record.Id = record.Id.replace(" ", "");
        record.Id = record.Id.trim();
        if (!window.confirm("Are you sure to complete this reminder?" + record.OrderRef)) {
            return
        }
        console.log("submitReminder > ", record)
        setBusy(true)
        setIsReminder(false);
        await SubmitRequestProcessReminder(record)
        setBusy(false)
    }

    const submitReminderAction = async (appointment: AppointmentEntry) => {
        let entity = "CcWorkerHandleFieldData";
        let usernameRef = escapeEmailAddress(username);

        /**
         * let recycle first this handle data
         */
            // @ts-ignore
        let queueRef: string = CurrentHandleData.QueueRef;
        // @ts-ignore
        let leadRef: string = CurrentHandleData.ExtRef;
        // @ts-ignore
        let data = CurrentHandleData.Data;
        let org: string = CurrentCheckin.Organization;
        let agency: string = CurrentCheckin.Agency;

        setBusy(true)

        /**
         * let push our current handle lead back to the queue
         */
        await RecycleQueue(queueRef, leadRef, "field", username, org, agency, data);

        /**
         * let make now our handle
         */
        let dataIn = appointment.LeadData
        await firebase.database()
            .ref(entity)
            .child(usernameRef)
            .set(dataIn).then((snpashot) => {
            }).catch((err) => {})

        /**
         * let now remove our reminder from the list
         */
        await firebase.database().ref(`CcProcessReminder/${appointment.Id}`).remove();

        setBusy(false)
    }

    const loadCounterQueue = async (queueRef: string) => {
        let path = `CcQueueFieldData/${queueRef}`;
        let table = firebase.database().ref(path)
        table.on('value', function (snapshot) {
            let records = snapshot.val()
            let count = 0;
            if (records !== null) {
                let keys = Object.keys(snapshot.val())
                count = keys.length
            }
            setCounter(count)
        });
    }
    const autoRequest = async () => {
        if (busy) {
            //console.log("(((: this guy still busy submiting: ")
            return
        }
        if (!IsAllocated) {
            //let check if  user have a lead
            let refHasLead = await firebase.database().ref(`${entityName}/${escapeEmailAddress(username)}`).limitToFirst(1).get();
            if (refHasLead.exists()) {
                //console.log("(((: this guy still have a lead : ", refHasLead.val())
                return
            }
            let refLead = await firebase.database().ref(`CcQueueFieldData/${CurrentCheckin.Queue}`).limitToFirst(1).get();
            let keyExist = refLead.exists()
            if (!keyExist) {
                return null
            }
            const user = escapeEmailAddress(username)
            await RequestQueueFieldData(CurrentCheckin, (d: any) => {
            }).then(r => console.log("Done requesting! manual 3: ", user, ">", r));
        }
    }
    const loadHandleData = async () => {
        let keyRef = escapeEmailAddress(username)
        let targetsHandleDataRef = firebase.database().ref(`${entityName}/${keyRef}`);
        targetsHandleDataRef.on("value", async function (snapshot: any) {
            let innerRecord = snapshot.val();
            console.log("loadHandleData x > ", innerRecord)
            if (innerRecord !== null) {
                let rec = innerRecord as WorkerHandleDataEntry;
                setCurrentHandleData(rec);
                setIsAllocated(true);
                await toast('Your new data arrived');
                /**
                 * todo set it up
                 */
            } else {
                setCurrentHandleData(Object);
                setIsAllocated(false);
                hasNoData();
            }
        });

    }
    const findQueueName = (ref: string, channel: string): string => {
        let queueInfos: QueueEntry[] = [];
        if (channel === "field") {
            queueInfos = dataQueueFields;
        }
        return findDisplayKeyInArray(ref, queueInfos, "Ref", "Name");
    }
    const getRequestedInfo = () => {
        let total = 0, taken = 0;
        for (let i in dataQueueFields) {
            let row = dataQueueFields[i];
            if (row.Ref === CurrentCheckin.Queue) {
                total = row.Total;
                taken = row.Taken;
            }
        }
        return {
            total,
            taken
        }
    }
    const submitProcessField = async () => {
        //todo implement the submit method
        if (!window.confirm("Are sure to submit your lead?")) {
            return
        }
        setShowPopoverField(false)
        setEventPopoverField(null);
        setBusy(true);
        // @ts-ignore
        let myData = CurrentHandleData.Data;
        let recToSubmit = {
            CreatedDate: "",
            CreatedTime: "",
            Organization: CurrentCheckin.Organization,
            Agency: CurrentCheckin.Agency,
            Comment: InputProcessFieldComment,
            Phase: InputProcessFieldPhase,
            Pipeline: InputProcessFieldPipeline,
            QueueRef: CurrentCheckin.Queue,
            Channel: CurrentCheckin.Category,
            Ref: CurrentHandleData.InRef,
            Username: username,
            Data: myData,
            WhatsApp: InputProcessFieldWhatsApp,
        }
        await SubmitRequestProcessField(recToSubmit);
        console.log("SubmitRequestProcessField field > ", recToSubmit)

        //console.log("(((:submit-comment:))-> 0 ", CurrentHandleData.Data);

        let commentRecord: CommentHistoryEntry = {
            LeadRef: CurrentHandleData.Data.Ref,
            Organization: recToSubmit.Organization,
            Agency: recToSubmit.Agency,
            QueueRef: recToSubmit.QueueRef,
            Pipeline: recToSubmit.Pipeline,
            Phase: recToSubmit.Phase,
            Username: recToSubmit.Username,
            UserFullName: "",
            Comment: recToSubmit.Comment,
            Date: moment().format("YYYY-MM-DD"),
            Time: moment().format("HH:mm:SS"),
            Category: "field",
            Phone: getPhoneFromLead(),
        }
        await SubmitRequestProcessComment(commentRecord);

        setBusy(false);
    }
    const manualRequest = async () => {
        setBusy(true);

        let refLead = await firebase.database().ref(`CcQueueFieldData/${CurrentCheckin.Queue}`).limitToFirst(1).get();
        let keyExist = refLead.exists()
        if (!keyExist) {
            /**
             * let exit because our queue doesn't have records
             */
            alert("sorry! queue doesn't have records")
            setBusy(false);
            return null
        }
        console.log("Done requesting! manual ready")
        await RequestQueueFieldData(CurrentCheckin, (d: any) => {
        }).then(r => console.log("Done requesting! manual : ", r));
        setBusy(false)
    }
    const renderAllocation = () => {
        const infoQ = getRequestedInfo();
        const renderArrowDown = () => {
            return (
                <IonIcon slot="start" ios={arrowDown} md={arrowDown} style={{color: "orange"}}/>
            )
        }
        let daily = 0;
        return (
            <IonGrid>
                <IonRow style={styles.IonRow}>
                    <IonCol style={styles.IonColHeaderAllocation}>
                        File d'attente des Clients:
                        {findQueueName(CurrentCheckin.Queue, CurrentCheckin.Category)} |
                        Catégorie: {CurrentCheckin.Category}
                    </IonCol>
                </IonRow>
                <IonRow style={styles.IonRow}>
                    <IonCol style={styles.IonCol}><span>{renderArrowDown()}Qté dispo</span></IonCol>
                    <IonCol style={styles.IonCol}><span>{renderArrowDown()}Qté totale</span></IonCol>
                    <IonCol style={styles.IonCol}><span>{renderArrowDown()}Qté traitée</span></IonCol>
                </IonRow>
                <IonRow style={styles.IonRow}>
                    <IonCol style={styles.IonCol2}>{Counter}</IonCol>
                    <IonCol style={styles.IonCol2}>{infoQ.total}</IonCol>
                    <IonCol style={styles.IonCol2}>{infoQ.taken}</IonCol>
                </IonRow>

            </IonGrid>
        )
    }
    const renderData = () => {
        let record = CurrentHandleData;
        if (!IsAllocated) {
            return (

                <IonGrid>
                    <IonRow>
                        <IonCol>
                            Waiting for data....
                            <a onClick={() => manualRequest()} style={styles.link}>Refresh</a>
                        </IonCol>

                    </IonRow>
                </IonGrid>

            )
        }


        let avoids = ["IsOnSite", "Latitude", "Longitude", "Organization", "Ref", "Team", "UserCode", "UserFullName", "Username",
            "Agence", "Agency", "Code"]
        let customer = "", phone = "", parent = "", site = "";
        let dataRecord = record.Data;
        if(typeof record.Data.Data !=="undefined"){
            dataRecord = record.Data.Data;
        }
        if (typeof dataRecord.CustomerName !== "undefined") {
            if (dataRecord.CustomerName !== "") {
                customer = dataRecord.CustomerName;
            }
        }
        if (typeof dataRecord.FullName !== "undefined") {
            if (dataRecord.FullName !== "") {
                customer = dataRecord.FullName;
            }
        }
        if (typeof dataRecord.CustomerPhone !== "undefined") {
            if (dataRecord.CustomerPhone !== "") {
                phone = dataRecord.CustomerPhone;
            }
        }
        if (typeof dataRecord.CustomerContactNumber !== "undefined") {
            if (dataRecord.CustomerContactNumber !== "") {
                phone = dataRecord.CustomerContactNumber;
            }
        }


        if (typeof dataRecord.CustomerParentPhone !== "undefined") {
            if (dataRecord.CustomerParentPhone !== "") {
                parent = dataRecord.CustomerParentPhone;
            }
        }
        if (typeof dataRecord.ParentNumber !== "undefined") {
            if (dataRecord.ParentNumber !== "") {
                parent = dataRecord.ParentNumber;
            }
        }

        console.log("^^^^^^^Data detail: ", record.Data)
        return (
            <IonGrid>
                <IonRow style={styles.IonRow}>
                    <IonCol style={styles.IonColHeader}>Détails client:</IonCol>
                </IonRow>
                <IonRow style={styles.IonRow}>
                    <IonCol style={styles.IonCol}>Nom & Post-nom:</IonCol>
                    <IonCol style={styles.IonCol}>{customer}</IonCol>
                </IonRow>
                <IonRow style={styles.IonRow}>
                    <IonCol style={styles.IonCol}>Téléphone:</IonCol>
                    <IonCol style={styles.IonCol}>{phone}</IonCol>
                </IonRow>
                <IonRow style={styles.IonRow}>
                    <IonCol style={styles.IonCol}>Téléphone du parent:</IonCol>
                    <IonCol style={styles.IonCol}>{parent}</IonCol>
                </IonRow>
                <IonRow style={styles.IonRow}>
                    <IonCol style={styles.IonCol}>Site:</IonCol>
                    <IonCol style={styles.IonCol}>{dataRecord.Site}</IonCol>
                </IonRow>
                <IonRow style={styles.IonRow}>
                    <IonCol style={styles.IonCol}>Date & heure de création:</IonCol>
                    <IonCol style={styles.IonCol}>{dataRecord.Date + " " + dataRecord.Time}</IonCol>
                </IonRow>
            </IonGrid>
        )
    }
    const closeProcessField = () => {
        setShowPopoverField(false)
        setEventPopoverField(null);
    }
    const renderCommand = () => {
        const requestProcess = (e: any) => {
            setEventPopoverField(e);
            setShowPopoverField(true)
        }
        return (
            <IonGrid>

                <IonRow style={styles.IonRow2}>
                    <IonCol style={styles.IonCol}>
                        <IonButton size="small" color="secondary" style={styles.btn}
                                   onClick={(e: any) => {
                                       e.persist();
                                       requestProcess(e)
                                   }}>Clôturer</IonButton>
                    </IonCol>
                    <IonCol style={styles.IonCol}>
                        <IonButton onClick={() => requestReminder()} size="small" color="secondary"
                                   style={styles.btn}>Rappel</IonButton>
                    </IonCol>
                    <IonCol style={styles.IonCol}>
                        <IonButton onClick={() => requestComment()} size="small" color="secondary"
                                   style={styles.btn}>Notes</IonButton>
                    </IonCol>
                </IonRow>
            </IonGrid>
        )
    }

    const getAppointments = (state: string = "all"): AppointmentEntry[] => {
        let lsAppointments: AppointmentEntry[] = [];
        let currDate = moment().format("YYYY-MM-DD")
        for (let i in dataAppointments) {
            let row: AppointmentEntry = dataAppointments[i];
            if (row.Organization !== CurrentCheckin.Organization) {
                continue
            }
            if (row.Agency !== CurrentCheckin.Agency) {
                continue
            }
            if (row.Status === 3) {
                continue
            }
            if (state === "current") {
                if (row.TargetDate !== currDate) {
                    row.CategoryColor = "#357cd2"
                    //continue
                }
            } else if (state === "future") {
                if (!moment(row.TargetDate).isBefore(moment(), "day")) {
                    row.CategoryColor = "#808000"
                    //continue
                }
            } else if (state === "overdue") {
                if (moment(row.TargetDate).isBefore(moment(), "day")) {
                    row.CategoryColor = "#c43081"
                    //continue
                }
            }
            lsAppointments.push(row)
        }
        return lsAppointments
    }
    const getNotes = (): CommentEntry[] => {
        let ls: CommentEntry[] = [];
        for (let i in dataNotes) {
            let row: CommentEntry = dataNotes[i];
            if (row.Organization !== CurrentCheckin.Organization) {
                continue
            }
            if (row.Agency !== CurrentCheckin.Agency) {
                continue
            }
            if (row.Username !== CurrentCheckin.Username) {
                continue
            }
            ls.push(row)
        }

        let tempList: CommentEntry[] = [];
        for (let i = (ls.length - 1); i >= 0; i--) {
            let row = ls[i];
            tempList.push(row);
        }
        return tempList
    }
    const getPredefinedOption = (target: string): SelectOption[] => {
        let ls: SelectOption[] = [];
        let dataSource: PredefinedEntry[] = [];
        if (target === "disposition") {
            dataSource = DataPredefinedDisposition
        }
        if (target === "reminder") {
            dataSource = DataPredefinedReminder
        }
        if (target === "comment") {
            dataSource = DataPredefinedComment
        }
        if (target === "note") {
            dataSource = DataPredefinedNote
        }
        console.log("getPredefinedOption > ", dataSource)
        for (let i in dataSource) {
            let row: PredefinedEntry = dataSource[i];
            if (row.Organization !== CurrentCheckin.Organization) {
                continue
            }
            if (row.Agency !== CurrentCheckin.Agency) {
                continue
            }
            ls.push({
                key: row.Name,
                val: row.Name
            })
        }
        return ls
    }
    const renderTabs = () => {

        return (
            <div style={styles.tabs}>
                <div className="card-container">
                    <Tabs type="card" style={{minHeight: 400}}>
                        <TabPane tab="Rappels" key="1" style={styles.innerTab}>
                            <Appointiment submitAction={submitReminderAction} dataIn={getAppointments("all")} currentView={"Agenda"}/>
                        </TabPane>
                        <TabPane tab="Notes" key="4" style={styles.innerTab}>
                            <NotesAgent
                                notes={getNotes()}
                            />
                        </TabPane>
                    </Tabs>
                </div>
            </div>
        )
    }

    useEffect(() => {
        if (!isSync) {
            loadHandleData().then(r => null);
            RequestQueueFieldData(CurrentCheckin, (d: any) => {
            }).then(r => console.log("Done requesting!"));
            SyncData(setDataQueueFields, "CcQueueFieldSetting", "Code", cleanInputs, styles.aIcon, false)
            SyncData(setDataPipelines, "CcPipelineSettings", "Code", cleanInputs, styles.aIcon, false);
            SyncData(setDataAppointments, "CcProcessReminder", "Code", cleanInputs, styles.aIcon, false);
            SyncData(setDataNotes, "CcWorkerNotes", "Id", cleanInputs, styles.aIcon, false);

            SyncData(setDataPredefinedDisposition, "CcPredefinedDispositionTitle", "Id", cleanInputs, styles.aIcon, false);
            SyncData(setDataPredefinedReminder, "CcPredefinedReminderTitle", "Id", cleanInputs, styles.aIcon, false);
            SyncData(setDataPredefinedComment, "CcPredefinedCommentTitle", "Id", cleanInputs, styles.aIcon, false);
            SyncData(setDataPredefinedNote, "CcPredefinedNoteTitle", "Id", cleanInputs, styles.aIcon, false);


            loadCounterQueue(CurrentCheckin.Queue).then(r => null)

            const interval = setInterval(() => {
                console.log('This will run every 10 second! field type of queue');
                autoRequest().then(r => null)
            }, 10000);

            //"CcPipelineSettings"
            setIsSync(true)
            console.log("useEffect > ", isSync)
            //return () => clearInterval(interval);
        }
    }, []);


    return (
        <div style={styles.container}>
            <IonLoading message={"please wait.."} duration={0} isOpen={busy}/>
            <h1 style={{color: "#3880ff", marginLeft: 10, fontSize: 18}}>
                Bureau de travail! (Initial)
            </h1>
            {CurrentCheckin.Ref !== "" ?
                <>
                    {renderAllocation()}
                </> : null
            }

            {renderCommand()}

            {renderData()}

            {renderTabs()}

            <PopupAgentProcessField
                showPopover={showPopoverField}
                Agency={CurrentCheckin.Agency}
                Organization={CurrentCheckin.Organization}
                event={eventPopoverField}
                onClose={closeProcessField}
                submitProcess={submitProcessField}
                setComment={setInputProcessFieldComment}
                setPhase={setInputProcessFieldPhase}
                setPipeline={setInputProcessFieldPipeline}
                Pipelines={dataPipelines}
                Checkin={CurrentCheckin}
                OptionsComment={getPredefinedOption("comment")}
                setIsWhatsApp={setIsWhatsApp}
            />

            <PopupAgentReminder
                showPopover={isReminder}
                onClose={closeReminder}
                submitProcess={submitReminder}
                setComment={setInputReminderComment}
                setDate={setInputReminderDate}
                setTime={setInputReminderTime}
                event={eventPopoverField}
                OptionsReminder={getPredefinedOption("reminder")}
            />

            <PopupAgentComment
                showPopover={showPopoverComment}
                onClose={closeComment}
                submitProcess={submitComment}
                setComment={setInputCommentNote}
                event={eventPopoverField}
                OptionsComment={getPredefinedOption("note")}
            />


        </div>
    )


}
export default WorkerAttendantField;
const styles = {
    link: {
        cursor: "pointer",
        color: "blue",
        marginLeft: 10
    },
    tabs: {
        backgroundColor: "lightgray",
        padding: 10,
        overflow: "auto"
    },
    innerTab: {
        minHeight: 400,
        overflow: "auto"
    },
    btn: {
        minWidth: "100%",
        maxWidth: "100%"
    },
    IonColHeaderAllocation: {
        borderStyle: "solid",
        borderColor: "#ddd",
        backgroundColor: "#eb445a",
        marginBottom: 5,
        borderWidth: 1,
        marginLeft: 5,
        color: "white"
    },
    IonColHeader: {
        borderStyle: "solid",
        borderColor: "#ddd",
        backgroundColor: "#eb445a",
        marginBottom: 5,
        borderWidth: 1,
        marginLeft: 5,
        color: "white"
    },
    IonCol2: {
        borderStyle: "solid",
        borderColor: "#ddd",
        backgroundColor: "#f7f7f7",
        marginBottom: 5,
        borderWidth: 1,
        marginLeft: 5,
        color: "red",
        fontSize: 14,
        fontWeight: "bold",
        justifyContent: "center",
    },
    IonCol: {
        borderStyle: "solid",
        borderColor: "#ddd",
        backgroundColor: "#f7f7f7",
        marginBottom: 5,
        borderWidth: 1,
        marginLeft: 5
    },
    IonRow2: {
        borderStyle: "solid",
        borderColor: "#46b0e0",
        backgroundColor: "#f7f7f7",
        borderRadius: 5,
        borderWidth: 1,
        marginLeft: 5,
        marginBottom: 5
    },
    IonRow: {
        /*borderStyle:"solid",
        borderColor:"#ddd",
        backgroundColor: "#f7f7f7",
        marginBottom:5,
        borderWidth:1,*/
    },
    buildAddInfoField: {
        color: "red",
        fontSize: 12,
        marginLeft: 10
    },
    buildAddInfoValue: {
        color: "gray",
        fontSize: 12,
        marginLeft: 10
    },
    titleComponent: {
        color: "#3880ff",
        fontSize: 18,
        fontWeight: "bold",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        marginTop: 20
    },
    requestNewPipeline: {
        float: "right",
        color: "blue",
        fontSize: 19,
        fontWeight: "bold"
    },
    cancelNewPipeline: {
        float: "right",
        color: "red",
        fontSize: 19,
        fontWeight: "bold",
        cursor: "pointer"
    },
    aIcon: {
        fontSize: 24,
        cursor: "pointer"
    },
    container: {
        minWidth: "100%",
        padding: 20,

    }
}
