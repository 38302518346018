import {IonLoading} from "@ionic/react";
import React, {useState} from "react";
import Appointment from "../../components/WorkBoardAgent/Appointiment";


const AppointmentDefault= () => {
    const [busy, setBusy] = useState<boolean>(false);
    return (
        <div style={styles.container}>
            <IonLoading message={"please wait.."} duration={0} isOpen={busy}/>
            <h1 style={{color: "#3880ff", marginLeft: 10, fontSize: 18}}>
                Appointment
            </h1>

            <Appointment />

        </div>
    )
}

export default AppointmentDefault;
const styles = {
    link: {
        cursor: "pointer",
        color: "blue",
        marginLeft: 10
    },
    tabs: {
        backgroundColor: "lightgray",
        padding: 10
    },
    btn: {
        minWidth: "100%",
        maxWidth: "100%"
    },
    IonColHeaderAllocation: {
        borderStyle: "solid",
        borderColor: "#ddd",
        backgroundColor: "#eb445a",
        marginBottom: 5,
        borderWidth: 1,
        marginLeft: 5,
        color: "white"
    },
    IonColHeader: {
        borderStyle: "solid",
        borderColor: "#ddd",
        backgroundColor: "#eb445a",
        marginBottom: 5,
        borderWidth: 1,
        marginLeft: 5,
        color: "white"
    },
    IonCol2: {
        borderStyle: "solid",
        borderColor: "#ddd",
        backgroundColor: "#f7f7f7",
        marginBottom: 5,
        borderWidth: 1,
        marginLeft: 5,
        color: "red",
        fontSize: 14,
        fontWeight: "bold",
        justifyContent: "center",
    },
    IonCol: {
        borderStyle: "solid",
        borderColor: "#ddd",
        backgroundColor: "#f7f7f7",
        marginBottom: 5,
        borderWidth: 1,
        marginLeft: 5
    },
    IonRow2: {
        borderStyle: "solid",
        borderColor: "#46b0e0",
        backgroundColor: "#f7f7f7",
        borderRadius: 5,
        borderWidth: 1,
        marginLeft: 5,
        marginBottom: 5
    },
    IonRow: {
        /*borderStyle:"solid",
        borderColor:"#ddd",
        backgroundColor: "#f7f7f7",
        marginBottom:5,
        borderWidth:1,*/
    },
    buildAddInfoField: {
        color: "red",
        fontSize: 12,
        marginLeft: 10
    },
    buildAddInfoValue: {
        color: "gray",
        fontSize: 12,
        marginLeft: 10
    },
    titleComponent: {
        color: "#3880ff",
        fontSize: 18,
        fontWeight: "bold",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        marginTop: 20
    },
    requestNewPipeline: {
        float: "right",
        color: "blue",
        fontSize: 19,
        fontWeight: "bold"
    },
    cancelNewPipeline: {
        float: "right",
        color: "red",
        fontSize: 19,
        fontWeight: "bold",
        cursor: "pointer"
    },
    aIcon: {
        fontSize: 24,
        cursor: "pointer"
    },
    container: {
        minWidth: "100%",
        padding: 20,

    }
}