import {
    IonButton,
    IonContent,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonListHeader,
    IonMenu,
    IonMenuToggle,
    IonNote,
} from '@ionic/react';

import {useLocation} from 'react-router-dom';
import {
    timeOutline,
    chatbox,
    list,
    gitNetwork,
    mailOutline,
    mailSharp,

} from 'ionicons/icons';
import './Menu.css';
import {useEffect, useState} from 'react';
import {SyncData, findUserLanguage, escapeEmailAddress} from '../services/general-functions';
import store from '../redux/store';
import {getCurrentUser, logoutUser, queryFirestoreData} from '../services/firebase-functions';
import {FirestoreQueryCondition, MenusRow} from "../mocks/types";
import {InfoLang,menus} from "../mocks/language";
import firebase from "../configures/firebase-init";

interface AppPage {
    url: string;
    iosIcon: string;
    mdIcon: string;
    title: string;
}

const Menu: React.FC = () => {
    const [isSync, setIsSync] = useState<boolean>(false);
    const [isLogin, setIsLogin] = useState<boolean>(false);
    const [dataUsers, setDataUsers] = useState([]);
    const [dataAdminUsers, setDataAdminUsers] = useState([]);
    const [dataSuperUsers, setDataSuperUsers] = useState([]);
    const [currentLanguage, setCurrentLanguage] = useState("fr")
    const [syncLang, setSyncLang] = useState<boolean>(false);
    //const [menus, setMenus] = useState<string>();
    const [loginUsername, setLoginUsername] = useState("guest@home.com");
    const location = useLocation();



    //findUserLanguage(setCurrentLanguage, null);
    // @ts-ignore
    let info = menus["fr"]

    const appPagesAuth: AppPage[] = [
        {
            title: 'Se connecter',
            url: '/page/auth-login',
            iosIcon: list,
            mdIcon: list
        },
    ];
    const appPagesProfile: AppPage[] = [
        {
            title: 'Se déconnecter',
            url: '/page/auth-logout',
            iosIcon: list,
            mdIcon: list
        },
        {
            title: 'Mon Profil',
            url: '/page/auth-profile',
            iosIcon: list,
            mdIcon: list
        },
    ];
    const appPagesAgent: AppPage[] = [
        /*{
            title: info.menu.agent.checkin,
            url: '/page/worker-checkin',
            iosIcon: list,
            mdIcon: list
        },*/
        {
            title: info.menu.agent.attendant,
            url: '/page/worker-attendant',
            iosIcon: list,
            mdIcon: list
        },
        {
            title: info.menu.agent.dashboard,
            url: '/page/worker-dashboard',
            iosIcon: list,
            mdIcon: list
        },
    ];
    const appPagesSettingDataSource: AppPage[] = [
        {
            title: info.menu.channelBatch.batchSource,
            url: '/page/setting-lead-batch-source',
            iosIcon: list,
            mdIcon: list
        },
        {
            title: info.menu.channelBatch.batchInfo,
            url: '/page/setting-lead-batch-name',
            iosIcon: list,
            mdIcon: list
        },
        /*{
            title: info.menu.lead.batchQueue,
            url: '/page/setting-lead-batch-queue',
            iosIcon: gitNetwork,
            mdIcon: gitNetwork
        },*/
    ]
    const appPagesSettingStructure: AppPage[] = [
        {
            title: info.menu.structure.organization,
            url: '/page/setting-structure-organization',
            iosIcon: list,
            mdIcon: list
        },
        {
            title: info.menu.structure.agency,
            url: '/page/setting-structure-agency',
            iosIcon: list,
            mdIcon: list
        },
        {
            title: info.menu.structure.team,
            url: '/page/setting-structure-team',
            iosIcon: list,
            mdIcon: list
        },
    ];
    const appPagesQueueAdmin: AppPage[] = [
        {
            title: info.menu.queue.queueBatch,
            url: '/page/setting-queue-admin-batch',
            iosIcon: list,
            mdIcon: list
        },
        {
            title: info.menu.queue.queueField,
            url: '/page/setting-queue-admin-field',
            iosIcon: list,
            mdIcon: list
        },
        {
            title: info.menu.queue.queuePipeLine,
            url: '/page/setting-queue-admin-pipe',
            iosIcon: list,
            mdIcon: list
        }
    ];
    const appPagesInvitationAdmin: AppPage[] = [
        {
            title: info.menu.invitation.all,
            url: '/page/setting-invitations',
            iosIcon: list,
            mdIcon: list
        }

    ]
    const appPagesChannelPipelineAdmin: AppPage[] = [
        {
            title: info.menu.channelPipeline.setupTree,
            url: '/page/setting-channel-pipeline-setup-tree',
            iosIcon: list,
            mdIcon: list
        },
    ];
    const appPagesPredefined: AppPage[] = [
        {
            title: info.menu.predefined.disposition,
            url: '/page/setting-predefined-disposition',
            iosIcon: list,
            mdIcon: list
        },
        {
            title: info.menu.predefined.reminder,
            url: '/page/setting-predefined-reminder',
            iosIcon: list,
            mdIcon: list
        },
        {
            title: info.menu.predefined.comment,
            url: '/page/setting-predefined-comment',
            iosIcon: list,
            mdIcon: list
        },
        {
            title: info.menu.predefined.note,
            url: '/page/setting-predefined-note',
            iosIcon: list,
            mdIcon: list
        },
    ];

    const cleanInputs = () => {
    }
    const monitorLanguageChange=async ()=>{
        const infoLogin = store.getState();
        let username = infoLogin.auth.user.username;
        let user = escapeEmailAddress(username);
        await firebase.database().ref(`UserLanguage/${user}`).on("value",(snapchot)=>{
            if(snapchot!==null){
                let data =snapchot.val();
                let ln =data.Language;
                setCurrentLanguage(ln)
            }
        });

    }
    const localSyncData = () => {
        SyncData(setDataAdminUsers, "AdminUsers", "Code", cleanInputs, styles.aIcon, false);
        SyncData(setDataSuperUsers, "SuperUsers", "Code", cleanInputs, styles.aIcon, false);
        SyncData(setDataUsers, "users", "Username", cleanInputs, styles.aIcon, false);
        monitorLanguageChange().then(r => null)
        setIsSync(true)
    }
    const getInfoLogin = () => {
        const infoLogin = store.getState();
        let username = infoLogin.auth.user.username;
        let user = null;

        for (let i in dataUsers) {
            let row = dataUsers[i];
            // @ts-ignore
            if (row.Username.toLowerCase() === username.toLowerCase()) {
                user = row
            }
        }
        return user
    }
    const isSuperUser = () => {
        let user = getInfoLogin();
        if (user === null) {
            return false;
        }
        for (let i in dataSuperUsers) {
            let row = dataSuperUsers[i];
            if (row.Username === user.Username) {
                return true;
            }
        }
        return false
    }
    const isAdminUser = () => {
        let user = getInfoLogin();
        if (user === null) {
            return false;
        }
        for (let i in dataAdminUsers) {
            let row = dataSuperUsers[i];
            if (row.Username === user.Username) {
                return true;
            }
        }
        return false
    }
    const checkIfUserLogin = () => {
        let user = getInfoLogin();
        if (user === null) {
            return false
        }
        return true;
    }
    const renderMenusContent = (dataFeeds: MenusRow[]) => {
        return dataFeeds.map((row, index) => {
            return (
                <IonMenuToggle key={index} autoHide={false}>
                    <IonItem className={location.pathname === row.url ? 'selected' : ''}
                             routerLink={row.url} routerDirection="none" lines="none"
                             detail={false}>
                        <IonIcon slot="start" ios={row.iosIcon} md={row.mdIcon}/>
                        <IonLabel>{row.title}</IonLabel>
                    </IonItem>
                </IonMenuToggle>
            )
        })
    }
    const newLoginListener = () => {
        let loadedloginHistoru = [];
        const channelsRef = firebase.database().ref('LoginHistory');
        /// PLACING A LISTENER ON CHANNELS HERE IN DB
        channelsRef.on('value', snap => {
            loadedloginHistoru.push(snap.val());
            const infoLogin = store.getState();
            let username = infoLogin.auth.user.username;
            setLoginUsername(username)
        });
    };
    useEffect(() => {
        if (!isSync) {
            localSyncData();
        }
        getCurrentUser().then((user: any) => {
            if (user) {
                setIsLogin(true)
            }
        })
        newLoginListener();
    }, []);

    let username = loginUsername;

    return (
        <IonMenu contentId="main" type="overlay">
            <IonContent>
                <IonList id="inbox-list">
                    <IonListHeader>Swaly Service Client <span style={styles.version}>v1.0.11</span></IonListHeader>
                    <IonNote>Salut! {username}</IonNote>
                    <hr/>
                </IonList>
                {checkIfUserLogin() ?
                    <>
                        <IonList id="labels-list">
                            {renderMenusContent(appPagesProfile)}
                        </IonList>
                        <IonList id="labels-list">
                            <IonListHeader>{info.menu.agent.title}</IonListHeader>
                            {renderMenusContent(appPagesAgent)}
                        </IonList>


                        {isSuperUser()||isAdminUser()?
                            <>
                                <IonListHeader>{info.menu.invitation.title}</IonListHeader>
                                {renderMenusContent(appPagesInvitationAdmin)}
                            </>:null
                        }

                    </> :
                    <>
                        <IonList>
                            {renderMenusContent(appPagesAuth)}
                        </IonList>
                    </>
                }
                {checkIfUserLogin() && isSuperUser() ?
                    <IonList id="labels-list">
                        <IonListHeader>{info.menu.structure.title}</IonListHeader>
                        {renderMenusContent(appPagesSettingStructure)}
                        <br/>
                        <IonList id="labels-list">
                            <IonListHeader>{info.menu.channelBatch.title}</IonListHeader>
                            {renderMenusContent(appPagesSettingDataSource)}
                        </IonList>

                        <IonList id="labels-list">
                            <IonListHeader>{info.menu.channelPipeline.title}</IonListHeader>
                            {renderMenusContent(appPagesChannelPipelineAdmin)}
                        </IonList>

                        <IonListHeader>{info.menu.queue.title}</IonListHeader>
                        {renderMenusContent(appPagesQueueAdmin)}

                        <IonListHeader>{info.menu.predefined.title}</IonListHeader>
                        {renderMenusContent(appPagesPredefined)}



                    </IonList> : null
                }

            </IonContent>
        </IonMenu>
    );
};

export default Menu;

const styles = {
    version: {
        color: 'red',
        marginLeft: 10,
        fontSize: 10,
        fontWeight: "bold"
    },
    aIcon: {
        fontSize: 24,
        cursor: "pointer"
    },
    container: {
        minWidth: "100%",
        paddingRight: 20,

    }
}
