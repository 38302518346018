
import moment from "moment";
import { escapeEmailAddress} from "./general-functions";
import {saveDataIntoOneRef, fetchingOnceDataFromDatabase,removeDataFromDb,saveDataIntoThreeRef} from "./submit-common";
import {AppointmentEntry} from "../../functions/src/services/types";


export const SubmitRequestProcessReminder=async (infoSubmit:AppointmentEntry)=> {

    let entity = "CcProcessReminder";

    console.log("UUF ",1)
    let currentDate = moment().format("YYYY-MM-DD");
    let currentTime = moment().format("HH:mm:SS");
    const appendDataTimeTotal=(inRecord:any,total:number)=>{
        inRecord.LastUpdatedDate = currentDate;
        inRecord.LastUpdatedTime = currentTime;
        inRecord.Total = total;
        return inRecord
    }

    let username = escapeEmailAddress(infoSubmit.Username);
    console.log("UUF ",2)
    /**
     * let save our reminder for reuse purpose
     */
    console.log("ZZZZZZZzzz-< ",infoSubmit.Id);
    await saveDataIntoOneRef(entity,infoSubmit.Id ,infoSubmit);
    console.log("UUF ",3)
    /**
     *  let remove this lead from worker handle for this agent
     */
    let removeTable = "CcWorkerHandlePipelineData";
    if(infoSubmit.Category==="QueueField"){
        removeTable= "CcWorkerHandleFieldData";
    }
    await removeDataFromDb(removeTable,username);
    console.log("UUF ",4)
    /**
     * let make our report
     */

    let reportTable = "CcProcessDashboardReport";
    let keyRef = `${reportTable}/${infoSubmit.QueueRef}/${infoSubmit.Date}/${username}`;
    await fetchingOnceDataFromDatabase(keyRef,async (dataOut:any)=>{
        if(dataOut!==null){
            dataOut  = appendDataTimeTotal(dataOut,parseInt(dataOut.Total) + 1)
            await saveDataIntoThreeRef(reportTable,infoSubmit.QueueRef,infoSubmit.Date,username,dataOut)
        }else{
            dataOut={}
            dataOut  = appendDataTimeTotal(dataOut,1)
            await saveDataIntoThreeRef(reportTable,infoSubmit.QueueRef,infoSubmit.Date,username,dataOut)
        }
    })
    console.log("UUF ",5)

}
