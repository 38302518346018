
import { IonLoading, IonPage } from "@ionic/react";
import React, { useState } from "react";
import { logoutUser } from "../../services/firebase-functions";
import {saveDataIntoZeroRef} from "../../services/submit-common";
import moment from "moment";
import store from "../../redux/store";


const AuthLogoutPage: React.FC = () => {
    const [busy, setBusy] = useState<boolean>(false);
    const logout = async () => {
        await logoutUser();

    }
    logout().then(async r => {
        console.log("logout complete");
        const infoLogin = store.getState();
        let username = infoLogin.auth.user.username;
        await saveDataIntoZeroRef("LoginHistory",{
            Username:username,
            Date: moment().format("YYYY-MM-DD"),
            Time: moment().format("HH:mm:SS"),
            State:"logout",
        })
        window.location.href="/page/auth-login";
    });
    return (
        <IonPage>
            <IonLoading message={"Veuillez patienter s'il vous plaît..."} duration={0} isOpen={busy}/>
        </IonPage>
    )
}

export default AuthLogoutPage;
