export const InfoLang = (lang: string) => {
    // @ts-ignore
    if (typeof menus[lang] === "undefined") {
        return menus.en
    }
    // @ts-ignore
    return menus[lang]
}

export const menus = {
    en: {
        menu: {
            auth: {
                register: "Register",
                login: "Login",
                logout: "Logout",
            },
            channelBatch: {
                title: "Channel Batch",
                batchSource: "Supplier",
                batchInfo: "Data",
                batchCsv: "Csv Data",
                batchQueue: "Queue Data",
            },
            channelPipeline: {
                title: "Channel Pipeline",
                setupTree: "Setup Process Flow",
            },
            queue: {
                title: "Queue (admin)",
                queueBatch: "Batch Csv",
                queueField: "Field Agent",
                queuePipeLine: "Pipe line",
            },
            structure: {
                title: "Structure (admin)",
                organization: "organization",
                agency: "Agency",
                team: "Team",
            },
            invitation: {
                title: "Invitation",
                batch: "Batch Queue",
                pipeline: "Pipeline Queue",
                field: "Field Agent",
                all: "Field & Pipeline"
            },
            agent: {
                title: "Agent",
                checkin: "Checkin",
                attendant: "Attendant",
                dashboard: "Dashboard"
            },
            predefined: {
                title: "Predefined Title",
                disposition: "Disposition",
                reminder: "Reminder",
                comment: "Comment",
                note:"Note"
            },
        },
        auth: {
            login: {
                pageTitle: "Login Authentication",
                usernameLabel: "Username",
                passwordLabel: "Password",
                usernamePlaceholder: "Enter your username...",
                passwordPlaceholder: "Enter your password...",
                submitButtonText: "Submit",
                needAccountTitle: "Need an account?",
                needAccountLink: "Register here..."
            }
        },
        lead: {
            source: {
                pageTitle: "Lead Source",
                tableListTitle: "Available List",
                submitNewRecordText: "Add new source",

                fieldOrganizationLabel: "Organization",
                fieldOrganizationErr: "error organization can not be empty",
                fieldAgencyLabel: "Agency",
                fieldAgencyErr: "error Agency can not be empty",
                fieldCodeLabel: "Source Code",
                fieldCodeErr: "error source code o can not be empty",
                fieldNameLabel: "Name",
                fieldNameErr: "error source name can not be empty",
            },
            batchName: {
                pageTitle: "Batch Names",
                tableListTitle: "Available List",
                submitNewRecordText: "Add new batch",

                fieldOrganizationLabel: "Organization",
                fieldOrganizationErr: "error organization can not be empty",
                fieldAgencyLabel: "Agency",
                fieldAgencyErr: "error Agency can not be empty",
                fieldSourceLabel: "Source",
                fieldSourceErr: "error Source can not be empty",
                fieldCodeLabel: "Batch Code",
                fieldCodeErr: "error batch code o can not be empty",
                fieldNameLabel: "Name",
                fieldNameErr: "error source name can not be empty",
            },
        },
        structure: {
            organization: {
                pageTitle: "Organization",
                tableListTitle: "Available List",
                submitNewRecordText: "Add new organization",
                fieldCodeLabel: "Enter Code",
                fieldCodeErr: "error organization code can not be empty",
                fieldNameLabel: "Enter Name",
                fieldNameErr: "error organization name can not be empty",
                fieldContactPersonLabel: "Contact Person",
                fieldNContactPersonErr: "error contact person can not be empty",
                fieldContactNumberLabel: "Contact Number",
                fieldContactNumberErr: "error contact number can not be empty",
                fieldContactEmailLabel: "Contact Email",
                fieldContactEmailErr: "error contact email can not be empty",
                fieldCategoryLabel: "Category",
                fieldCategoryErr: "error category can not be empty",
                submitConfirmMessage: "Are you to submit this organization?"
            },
            agency: {
                pageTitle: "Agency",
                tableListTitle: "Available agencies List",
                submitConfirmMessage: "Are you to submit this agency?",

                fieldOrganizationLabel: "Organization",
                fieldOrganizationError: "error organization can not be empty",
                fieldCodeLabel: "Agency Code",
                fieldCodeErr: "error  agency code can not be empty",
                fieldNameLabel: "Agency Name",
                fieldNameErr: "error  agency name can not be empty",
            },
            team: {
                pageTitle: "Team",
                tableListTitle: "Available agencies List",
                submitConfirmMessage: "Are you to submit this agency?",

                fieldOrganizationLabel: "Organization",
                fieldOrganizationError: "error organization can not be empty",
                fieldAgencyLabel: "Agency",
                fieldAgencyError: "error agency can not be empty",
                fieldCodeLabel: "Team Code",
                fieldCodeErr: "error  agency code can not be empty",
                fieldNameLabel: "Team Name",
                fieldNameErr: "error  team name can not be empty",
            }
        },
        queue: {
            batchQueue: {
                pageTitle: "Queue Batch Csv",
                tableListTitle: "Available List",
                submitNewRecordText: "Add new Queue",

                fieldOrganizationLabel: "Organization",
                fieldOrganizationErr: "error organization can not be empty",
                fieldAgencyLabel: "Agency",
                fieldAgencyErr: "error Agency can not be empty",
                fieldSourceLabel: "Source",
                fieldSourceErr: "error Source can not be empty",
                fieldBatchLabel: "Batch",
                fieldBatchErr: "error batch can not be empty",

                fieldCodeLabel: "Queue Code",
                fieldCodeErr: "error queue code o can not be empty",
                fieldNameLabel: "Name",
                fieldNameErr: "error source name can not be empty",

            },
            pipeQueue: {
                pageTitle: "Queue Pipe Setting",
                tableListTitle: "Available List",
                submitNewRecordText: "Add new Queue",

                fieldOrganizationLabel: "Organization",
                fieldOrganizationErr: "error organization can not be empty",
                fieldAgencyLabel: "Agency",
                fieldAgencyErr: "error Agency can not be empty",
                fieldPipelineLabel: "Pipeline",
                fieldPipelineErr: "error pipeline can not be empty",
                fieldPhaseLabel: "Phase",
                fieldPhaseErr: "error phase can not be empty",

                fieldRealtimeLabel: "Realtime",
                fieldRealtimeErr: "error realtime can not be empty",
                fieldNameLabel: "Name",
                fieldNameErr: "error source name can not be empty",
                fieldHistoryLabel: "History",
                fieldHistoryErr: "error History can not be empty",
                fieldTotalLabel: "Total",
                fieldTotalErr: "error Total can not be empty",
            },
            fieldQueue: {
                pageTitle: "Queue Field Setting",
                tableListTitle: "Available List",
                submitNewRecordText: "Add new Queue",

                fieldOrganizationCCLabel: "Organization Callcentre",
                fieldOrganizationCCErr: "error organization callcentre can not be empty",
                fieldAgencyCCLabel: "Agency Callcentre",
                fieldAgencyCCErr: "error Agency Callcentre can not be empty",

                fieldOrganizationFALabel: "Organization Field agent",
                fieldOrganizationFAErr: "error organization field can not be empty",
                fieldAgencyFALabel: "Agency Field",
                fieldAgencyFAErr: "error Agency Field can not be empty",

                fieldRealtimeLabel: "Realtime",
                fieldRealtimeErr: "error Realtime can not be empty",
                fieldIncludeHistoryLabel: "Include History",
                fieldIncludeHistoryErr: "error Include History can not be empty",

                fieldTotalLabel: "Total",
                fieldTotalErr: "error total can not be empty",

                fieldNameLabel: "Name",
                fieldNameErr: "error source name can not be empty",
            },
        },
        invitation: {

            batch: {
                pageTitle: "Queue Batch Csv",
                tableListTitle: "Available List",
                submitNewRecordText: "Add new Queue",

                fieldOrganizationLabel: "Organization",
                fieldOrganizationErr: "error organization can not be empty",
                fieldAgencyLabel: "Agency",
                fieldAgencyErr: "error Agency can not be empty",
                fieldQueueLabel: "Queue",
                fieldQueueErr: "error Queue can not be empty",

                fieldCategoryLabel: "Category",
                fieldCategoryErr: "error Category can not be empty",

                fieldUserNameLabel: "Name of User",
                fieldUserNameErr: "error name of user can not be empty",
                fieldUserEmailLabel: "Email of User",
                fieldUserEmailErr: "error name of user can not be empty",
                fieldTotalLabel: "Total Taken",
                fieldTotalErr: "error total taken  can not be empty",
            },
            all: {
                pageTitle: "Queue Field & Pipeline",
                tableListTitle: "Queue Field & Pipeline Available List",
                submitNewRecordText: "Add new Queue",

                fieldOrganizationLabel: "Organization",
                fieldOrganizationErr: "error organization can not be empty",
                fieldAgencyLabel: "Agency",
                fieldAgencyErr: "error Agency can not be empty",
                fieldQueueLabel: "Queue",
                fieldQueueErr: "error Queue can not be empty",

                fieldCategoryLabel: "Category",
                fieldCategoryErr: "error Category can not be empty",

                fieldUserNameLabel: "Name of User",
                fieldUserNameErr: "error name of user can not be empty",
                fieldUserEmailLabel: "Email of User",
                fieldUserEmailErr: "error name of user can not be empty",
                fieldTotalLabel: "Total Taken",
                fieldTotalErr: "error total taken  can not be empty",
            },
            pipeline: {
                pageTitle: "Queue Pipeline",
                tableListTitle: "Queue Pipeline Available List",
                submitNewRecordText: "Add new Queue",

                fieldOrganizationLabel: "Organization",
                fieldOrganizationErr: "error organization can not be empty",
                fieldAgencyLabel: "Agency",
                fieldAgencyErr: "error Agency can not be empty",
                fieldQueueLabel: "Queue",
                fieldQueueErr: "error Queue can not be empty",

                fieldUserNameLabel: "Name of User",
                fieldUserNameErr: "error name of user can not be empty",
                fieldUserEmailLabel: "Email of User",
                fieldUserEmailErr: "error name of user can not be empty",
                fieldTotalLabel: "Total Taken",
                fieldTotalErr: "error total taken  can not be empty",
            },
            field: {
                pageTitle: "Queue Field Agent",
                tableListTitle: "Queue Pipeline Available List",
                submitNewRecordText: "Add new Queue",

                fieldOrganizationLabel: "Organization",
                fieldOrganizationErr: "error organization can not be empty",
                fieldAgencyLabel: "Agency",
                fieldAgencyErr: "error Agency can not be empty",
                fieldQueueLabel: "Queue",
                fieldQueueErr: "error Queue can not be empty",

                fieldUserNameLabel: "Name of User",
                fieldUserNameErr: "error name of user can not be empty",
                fieldUserEmailLabel: "Email of User",
                fieldUserEmailErr: "error name of user can not be empty",
                fieldTotalLabel: "Total Taken",
                fieldTotalErr: "error total taken  can not be empty",
            },
        },
        worker: {
            checkin: {
                pageTitle: "Agent Subscription",
                tableListTitle: "Agent Subscription Available List",
                submitNewRecordText: "Add new Subscription",

                fieldOrganizationLabel: "Organization",
                fieldOrganizationErr: "error organization can not be empty",
                fieldAgencyLabel: "Agency",
                fieldAgencyErr: "error Agency can not be empty",
                fieldQueueLabel: "Queue",
                fieldQueueErr: "error Queue can not be empty",

                fieldChannelLabel: "Channel",
                fieldChannelErr: "error Channel can not be empty",

                fieldCreateDateLabel: "Created Date",
                fieldCreateDateErr: "error created date can not be empty",

                fieldCreateTimeLabel: "Created Time",
                fieldCreateTimeErr: "error created time can not be empty",

                fieldCommentLabel: "Comment",
                fieldCommentErr: "error comment can not be empty",
            },
        },
        predefined: {
            disposition: {
                pageTitle: "Disposition Title",
                tableListTitle: "Disposition Title Available List",
                submitNewRecordText: "Add new Disposition title",

                fieldOrganizationLabel: "Organization",
                fieldOrganizationErr: "error organization can not be empty",
                fieldAgencyLabel: "Agency",
                fieldAgencyErr: "error Agency can not be empty",
                fieldNameLabel: "Name",
                fieldNameErr: "error name can not be empty",
            },
            reminder: {
                pageTitle: "Reminder Title",
                tableListTitle: "Reminder Title Available List",
                submitNewRecordText: "Add new Reminder title",

                fieldOrganizationLabel: "Organization",
                fieldOrganizationErr: "error organization can not be empty",
                fieldAgencyLabel: "Agency",
                fieldAgencyErr: "error Agency can not be empty",
                fieldNameLabel: "Name",
                fieldNameErr: "error name can not be empty",
            },
            Comment: {
                pageTitle: "Comment Title",
                tableListTitle: "Comment Title Available List",
                submitNewRecordText: "Add new Comment title",

                fieldOrganizationLabel: "Organization",
                fieldOrganizationErr: "error organization can not be empty",
                fieldAgencyLabel: "Agency",
                fieldAgencyErr: "error Agency can not be empty",
                fieldNameLabel: "Name",
                fieldNameErr: "error name can not be empty",
            },
            note: {
                pageTitle: "Note Title",
                tableListTitle: "Note Title Available List",
                submitNewRecordText: "Add new Note title",

                fieldOrganizationLabel: "Organization",
                fieldOrganizationErr: "error organization can not be empty",
                fieldAgencyLabel: "Agency",
                fieldAgencyErr: "error Agency can not be empty",
                fieldNameLabel: "Name",
                fieldNameErr: "error name can not be empty",
            },
        },
    },
    fr: {
        menu: {
            auth: {
                register: "Enregistrez vous",
                login: "Se connecter",
                logout: "Se déconnecter"
            },
            channelBatch: {
                title: "Lot (Admin)",
                batchSource: "Source de lot",
                batchInfo: "Lots (batch)",
                batchCsv: "Données Csv",
                batchQueue: "Données d'attente"
            },
            channelPipeline: {
                title: "Channel Pipeline",
                setupTree: "Setup Process Flow",
            },
            queue: {
                title: "Queue (admin)",
                queueBatch: "Batch Csv",
                queueField: "Agent de terrain",
                queuePipeLine: "Pipeline",
            },
            structure: {
                title: "Structure",
                organization: "Organisation",
                agency: "Agence",
                team: "Equipes",
            },
            invitation: {
                title: "Assignations",
                batch: "Batch Queue",
                pipeline: "Pipeline Queue",
                field: "Field Agent",
                all: "Phases/Départements"
            },
            agent: {
                title: "Opérateur",
                checkin: "Checkin",
                attendant: "Données",
                dashboard: "Rapports"
            },
            predefined: {
                title: "Predefined Title",
                disposition: "Disposition",
                reminder: "Reminder",
                comment: "Comment",
                note:"Note",
            },
        },
        auth: {
            login: {
                pageTitle: "Se connecter",
                usernameLabel: "Adresse e-mail",
                passwordLabel: "Mot de passe",
                usernamePlaceholder: "Adresse e-mail?",
                passwordPlaceholder: "Mot de passe?",
                submitButtonText: "Se connecter",
                needAccountTitle: "Besoin d'un compte?",
                needAccountLink: "Enregistrez vous ici..."
            }
        },
        structure: {
            organization: {
                pageTitle: "Organisation",
                tableListTitle: "Liste disponible",
                submitNewRecordText: "Ajouter une nouvelle organisation",
                fieldCodeLabel: "Entrez le code",
                fieldCodeErr: "erreur le code d'organisation ne peut pas être vide",
                fieldNameLabel: "Nom de l'organisation",
                fieldNameErr: "erreur le nom de l'organisation ne peut pas être vide",
                fieldContactPersonLabel: "Personne de contact",
                fieldContactPersonErr: "erreur la personne de contact ne peut pas être vide",
                fieldContactNumberLabel: "Numéro de contact",
                fieldContactNumberErr: "le numéro de contact d'erreur ne peut pas être vide",
                fieldContactEmailLabel: "Email du contact",
                fieldContactEmailErr: "l'email de contact d'erreur ne peut pas être vide",
                fieldCategoryLabel: "Catégorie",
                fieldCategoryErr: "la catégorie d'erreur ne peut pas être vide",
                submitConfirmMessage: "Êtes-vous de soumettre cette organisation?"
            },
            agency: {
                pageTitle: "Agence",
                tableListTitle: "Liste disponible d'agences",
                submitNewRecordText: "Ajouter une nouvelle agence",

                fieldOrganizationLabel: "Organisation",
                fieldOrganizationError: "l'organisation de l'erreur ne peut pas être vide",
                fieldCodeLabel: "Code d'agence",
                fieldCodeErr: "erreur le code d'agence ne peut pas être vide",
                fieldNameLabel: "Nom d'agence",
                fieldNameErr: "erreur le nom de l'agence ne peut pas être vide",
            },
            team: {
                pageTitle: "Équipe",
                tableListTitle: "Liste disponible d'Équipe",
                submitNewRecordText: "Ajouter une nouvelle Équipe",

                fieldOrganizationLabel: "Organisation",
                fieldOrganizationError: "l'organisation ne peut pas être vide",
                fieldAgencyLabel: "Agence",
                fieldAgencyError: "l'agence  ne peut pas être vide",
                fieldCodeLabel: "Code d'Équipe",
                fieldCodeErr: "erreur le code d'Équipe ne peut pas être vide",
                fieldNameLabel: "Nom d'Équipe",
                fieldNameErr: "erreur le nom de l'Équipe ne peut pas être vide",
            }
        },
        lead: {
            source: {
                pageTitle: "Source Lead",
                tableListTitle: "Liste disponible",
                submitNewRecordText: "Ajouter une nouvelle source",

                fieldOrganizationLabel: "Organisation",
                fieldOrganizationErr: "l'organisation de l'erreur ne peut pas être vide",
                fieldAgencyLabel: "Agence",
                fieldAgencyErr: "erreur L'agence ne peut pas être vide",

                fieldCodeLabel: "Code source",
                fieldCodeErr: "code source d'erreur o ne peut pas être vide",
                fieldNameLabel: "Nom du lot",
                fieldNameErr: "le nom d'erreur du lot ne peut pas être vide",
            },
            batchName: {
                pageTitle: "Lots",
                tableListTitle: "Liste disponible",
                submitNewRecordText: "Ajouter une nouvelle lot",

                fieldOrganizationLabel: "Organisation",
                fieldOrganizationErr: "l'organisation de l'erreur ne peut pas être vide",
                fieldAgencyLabel: "Agence",
                fieldAgencyErr: "erreur L'agence ne peut pas être vide",
                fieldSourceLabel: "Source",
                fieldSourceErr: "erreur source ne peut pas être vide",

                fieldCodeLabel: "Code Lot",
                fieldCodeErr: "erreur code lot  ne peut pas être vide",
                fieldNameLabel: "Nom du lot",
                fieldNameErr: "le nom d'erreur du lot ne peut pas être vide",

            }
        },
        queue: {
            batchQueue: {
                pageTitle: "Queue Batch Csv",
                tableListTitle: "Liste disponible",
                submitNewRecordText: "Ajouter une nouvelle queue",

                fieldOrganizationLabel: "Organisation",
                fieldOrganizationErr: "erreur organisation ne peut pas être vide",
                fieldAgencyLabel: "Agency",
                fieldAgencyErr: "erreur Agency ne peut pas être vide",
                fieldSourceLabel: "Source",
                fieldSourceErr: "erreur Source ne peut pas être vide",
                fieldBatchLabel: "Batch",
                fieldBatchErr: "erreur batch ne peut pas être vide",

                fieldCodeLabel: "Queue Code",
                fieldCodeErr: "erreur queue ne peut pas être vide",
                fieldNameLabel: "Name",
                fieldNameErr: "erreur nom  ne peut pas être videy",

            },
            pipeQueue: {
                pageTitle: "Queue Pipe Setting",
                tableListTitle: "Available List",
                submitNewRecordText: "Add new Queue",

                fieldOrganizationLabel: "Organization",
                fieldOrganizationErr: "error organization can not be empty",
                fieldAgencyLabel: "Agency",
                fieldAgencyErr: "error Agency can not be empty",
                fieldPipelineLabel: "Pipeline",
                fieldPipelineErr: "error pipeline can not be empty",
                fieldPhaseLabel: "Phase",
                fieldPhaseErr: "error phase can not be empty",

                fieldRealtimeLabel: "Realtime",
                fieldRealtimeErr: "error realtime can not be empty",
                fieldNameLabel: "Name",
                fieldNameErr: "error source name can not be empty",
                fieldHistoryLabel: "History",
                fieldHistoryErr: "error History can not be empty",
            },
            fieldQueue: {
                pageTitle: "Queue Field Setting",
                tableListTitle: "Available List",
                submitNewRecordText: "Add new Queue",

                fieldOrganizationCCLabel: "Organization Callcentre",
                fieldOrganizationCCErr: "error organization callcentre can not be empty",
                fieldAgencyCCLabel: "Agency Callcentre",
                fieldAgencyCCErr: "error Agency Callcentre can not be empty",

                fieldOrganizationFALabel: "Organization Field agent",
                fieldOrganizationFAErr: "error organization field can not be empty",
                fieldAgencyFALabel: "Agency Field",
                fieldAgencyFAErr: "error Agency Field can not be empty",

                fieldRealtimeLabel: "Realtime",
                fieldRealtimeErr: "error Realtime can not be empty",
                fieldIncludeHistoryLabel: "Include History",
                fieldIncludeHistoryErr: "error Include History can not be empty",

                fieldNameLabel: "Name",
                fieldNameErr: "error source name can not be empty",

                fieldTotalLabel: "Total",
                fieldTotalErr: "error total can not be empty",

            },
        },
        invitation: {
            batch: {
                pageTitle: "Queue Batch Invitation",
                tableListTitle: "Liste disponible queue invitation",
                submitNewRecordText: "Ajouter une nouvelle invitation",

                fieldOrganizationLabel: "Organisation",
                fieldOrganizationErr: "erreur organisation ne peut pas être vide",
                fieldAgencyLabel: "Agency",
                fieldAgencyErr: "erreur Agency ne peut pas être vide",
                fieldQueueLabel: "Queue",
                fieldQueueErr: "error Queue can not be empty",

                fieldCategoryLabel: "Category",
                fieldCategoryErr: "error Category ne peut pas être vide",

                fieldUserNameLabel: "Nom de l'utilisateur",
                fieldUserNameErr: "error, le nom d'utilisateur ne peut pas être vide",
                fieldUserEmailLabel: "Email address de l'utilisateur",
                fieldUserEmailErr: "error, email address d'utilisateur ne peut pas être vide",
                fieldTotalLabel: "Total pris",
                fieldTotalErr: "error total pris  ne peut pas être vide",
            },
            all: {
                pageTitle: "Queue Field & Pipeline",
                tableListTitle: "Queue Field & Pipeline Available List",
                submitNewRecordText: "Add new Queue",

                fieldOrganizationLabel: "Organization",
                fieldOrganizationErr: "error organization can not be empty",
                fieldAgencyLabel: "Agency",
                fieldAgencyErr: "error Agency can not be empty",
                fieldQueueLabel: "Queue",
                fieldQueueErr: "error Queue can not be empty",

                fieldCategoryLabel: "Category",
                fieldCategoryErr: "error Category can not be empty",

                fieldUserNameLabel: "Name of User",
                fieldUserNameErr: "error name of user can not be empty",
                fieldUserEmailLabel: "Email of User",
                fieldUserEmailErr: "error name of user can not be empty",
                fieldTotalLabel: "Total Taken",
                fieldTotalErr: "error total taken  can not be empty",
            },
            pipeline: {
                pageTitle: "Queue Pipeline",
                tableListTitle: "Queue Pipeline Available List",
                submitNewRecordText: "Add new Queue",

                fieldOrganizationLabel: "Organization",
                fieldOrganizationErr: "error organization can not be empty",
                fieldAgencyLabel: "Agency",
                fieldAgencyErr: "error Agency can not be empty",
                fieldQueueLabel: "Queue",
                fieldQueueErr: "error Queue can not be empty",

                fieldUserNameLabel: "Name of User",
                fieldUserNameErr: "error name of user can not be empty",
                fieldUserEmailLabel: "Email of User",
                fieldUserEmailErr: "error name of user can not be empty",
                fieldTotalLabel: "Total Taken",
                fieldTotalErr: "error total taken  can not be empty",
            },
            field: {
                pageTitle: "Queue Field Agent",
                tableListTitle: "Queue Pipeline Available List",
                submitNewRecordText: "Add new Queue",

                fieldOrganizationLabel: "Organization",
                fieldOrganizationErr: "error organization can not be empty",
                fieldAgencyLabel: "Agency",
                fieldAgencyErr: "error Agency can not be empty",
                fieldQueueLabel: "Queue",
                fieldQueueErr: "error Queue can not be empty",

                fieldUserNameLabel: "Name of User",
                fieldUserNameErr: "error name of user can not be empty",
                fieldUserEmailLabel: "Email of User",
                fieldUserEmailErr: "error name of user can not be empty",
                fieldTotalLabel: "Total Taken",
                fieldTotalErr: "error total taken  can not be empty",
            },
        },
        predefined: {
            disposition: {
                pageTitle: "Disposition Title",
                tableListTitle: "Disposition Title Available List",
                submitNewRecordText: "Add new Disposition title",

                fieldOrganizationLabel: "Organization",
                fieldOrganizationErr: "error organization can not be empty",
                fieldAgencyLabel: "Agency",
                fieldAgencyErr: "error Agency can not be empty",
                fieldNameLabel: "Name",
                fieldNameErr: "error name can not be empty",
            },
            reminder: {
                pageTitle: "Reminder Title",
                tableListTitle: "Reminder Title Available List",
                submitNewRecordText: "Add new Reminder title",

                fieldOrganizationLabel: "Organization",
                fieldOrganizationErr: "error organization can not be empty",
                fieldAgencyLabel: "Agency",
                fieldAgencyErr: "error Agency can not be empty",
                fieldNameLabel: "Name",
                fieldNameErr: "error name can not be empty",
            },
            Comment: {
                pageTitle: "Comment Title",
                tableListTitle: "Comment Title Available List",
                submitNewRecordText: "Add new Comment title",

                fieldOrganizationLabel: "Organization",
                fieldOrganizationErr: "error organization can not be empty",
                fieldAgencyLabel: "Agency",
                fieldAgencyErr: "error Agency can not be empty",
                fieldNameLabel: "Name",
                fieldNameErr: "error name can not be empty",
            },
            note: {
                pageTitle: "Note Title",
                tableListTitle: "Note Title Available List",
                submitNewRecordText: "Add new Note title",

                fieldOrganizationLabel: "Organization",
                fieldOrganizationErr: "error organization can not be empty",
                fieldAgencyLabel: "Agency",
                fieldAgencyErr: "error Agency can not be empty",
                fieldNameLabel: "Name",
                fieldNameErr: "error name can not be empty",
            },
        },
    },
}

