import {
    IonContent, IonItem, IonLabel,
    IonListHeader,
    IonLoading,
    IonPage,
    IonRadio,
    IonRadioGroup
} from '@ionic/react';
import React, {useState} from "react";
import {logIn} from "ionicons/icons";
import {Link, useHistory} from "react-router-dom";
import {
    loginUser,
    setEntityDatabaseKey,
    setFirestoreEntity,
    setFirestoreEntityWithKey,
    toast
} from "../../services/firebase-functions";
import {useDispatch} from "react-redux";
import TextInput from "../../components/ControllerInput/TextInput";
import InputButton from "../../components/ControllerInput/InputButton";
import AuthenticationHeader from "../../components/AuthenticationHeader";
import {setLanguageState, setUserState} from "../../redux/actions";
import {InfoLang} from "../../mocks/language";
import {saveDataIntoOneRef, saveDataIntoTwoRef, saveDataIntoZeroRef} from "../../services/submit-common";
import moment from "moment";
import {escapeEmailAddress} from "../../services/general-functions";

const AuthLoginPage: React.FC = () => {
    const [busy, setBusy] = useState<boolean>(false);
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [selected, setSelected] = useState<string>('fr');
    const [menus, setMenus] = useState<string>(InfoLang("fr"));
    const dispatch = useDispatch();
    const history = useHistory();


    const cleanUsername=()=>{
        let u = username.toLowerCase();
        u = u.trim();
        return u
    }
    const submitLogin = async () => {
        setBusy(true);
        const res: any = await loginUser(username, password);
        if (!res) {
            await toast('Votre nom de compte ou mot de passe n\'est pas reconnu!!')
        } else {
            await saveDataIntoZeroRef("LoginHistory",{
                Username: res.user.email,
                Date: moment().format("YYYY-MM-DD"),
                Time: moment().format("HH:mm:SS"),
                State:"login",
            })
            dispatch(setUserState(res.user.email));
            dispatch(setLanguageState(selected));
            history.replace('/page/dashboard');
            window.location.reload();
            await toast('Yous vous êtes connecté avec succès!')

        }
        const user = cleanUsername();
        await saveDataIntoOneRef("UserLanguage",escapeEmailAddress(user),{Username:user,Language:selected})
        await setFirestoreEntityWithKey("UserLanguage",user,{Username:user,Language:selected},null)
        setBusy(false)
        console.log(`${res ? 'Login success' : 'Login fail!'}`)
    }
    const onSelectLanguage=(value:string)=>{
        setSelected(value)
        setMenus(InfoLang(value))
        const user = cleanUsername();
         saveDataIntoOneRef("UserLanguage", escapeEmailAddress(user), {Username: user, Language: selected}).then (r =>null)
    }
    // @ts-ignore
    // @ts-ignore
    return (
        <IonPage>

            <IonLoading message={"Veuillez patienter s'il vous plaît..."} duration={0} isOpen={busy}/>
            <AuthenticationHeader/>
            <IonContent fullscreen className={"ion-padding"}>
                {/*<IonRadioGroup value={selected} onIonChange={e => onSelectLanguage(e.detail.value)} style={styles.IonRadioGroup}>
                    <IonItem>
                        <IonLabel>English</IonLabel>
                        <IonRadio slot="start" value="en" />
                    </IonItem>
                    <IonItem>
                        <IonLabel>français</IonLabel>
                        <IonRadio slot="start" value="fr" />
                    </IonItem>
                </IonRadioGroup>*/}

                <IonLabel style={styles.titleComponent}>
                    {menus.auth.login.pageTitle}
                </IonLabel>
                <TextInput
                    placeholder={"Adresse e-mail?"}
                    setValue={setUsername}
                    typeInput={"email"}
                />
                <TextInput
                    placeholder={"Mot de passe?"}
                    setValue={setPassword}
                    typeInput={"password"}
                />
                <InputButton
                    name={menus.auth.login.submitButtonText}
                    textColor={"secondary"}
                    backgroundColor={"#3880ff"}
                    btnOnClick={submitLogin}
                    borderRadius={20}
                    expand={"full"}
                    icon={logIn}
                    iconSlot={"start"}
                />
                <p style={{textAlign: "center"}} className={"ion-padding"}>
                    {menus.auth.login.needAccountTitle}
                    <Link to={"/page/auth-register"} style={{marginLeft: 10}}>{menus.auth.login.needAccountLink}</Link>
                </p>
            </IonContent>
        </IonPage>
    );
};

export default AuthLoginPage;

const styles={
    IonRadioGroup:{
        marginTop: 10,
        display:"flex",
        justifyContent:"flex-end"

    },
    titleComponent: {
        color: "#3880ff",
        fontSize: 18,
        fontWeight: "bold",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        marginTop: 10,
        marginBottom:10,
    }

}
