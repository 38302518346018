import React, {useState, useEffect} from "react";
import FormBuilders from "../../components/common/FormBuilders";
import {IonContent, IonIcon, IonLabel, IonLoading} from "@ionic/react";
import {
    findUserLanguage,
    getSelectionOptions,
    SubmitFormToFirebase,
    SyncData,
    findDisplayKeyInArray,
    FormatCodeUpCase,
    SyncDataOrigin,
    SubmitFormToFirebase2,
    SortDataAsc,
    cleanInput,
    UpdateFormToFirebase, buildAddInput, buildAddButton, buildAddInputSelect
} from "../../services/general-functions";
import {
    addCircle,
    closeCircleSharp, saveSharp
} from 'ionicons/icons';
import {
    PipelineField,
    PipelineSettingPhase,
    PipelineSettings,
    PipelineSettingStep,
    PipeTree,
    propsColumns,
    SelectOption
} from "../../mocks/types";
import {InfoLang, menus as ListMenus} from "../../mocks/language";
import {OnChangeInput, GetInputData, DefaultFormInputFields} from "../../services/form-inputs";
import LeadUpload from "../../components/Lead/LeadUpload";
import moment from "moment";
import {BuildQueueBatchCsvData, QueueBatchCreated} from "../../services/queue-functions";
import TextInput from "../../components/ControllerInput/TextInput";
import InputButton from "../../components/ControllerInput/InputButton";
// @ts-ignore
import FSRoot from 'react-fs-tree';
import {IonButton, IonPage, useIonAlert} from '@ionic/react';
import {toast} from "../../services/firebase-functions";
import InputSelect from "../../components/ControllerInput/InputSelect";

const PipelineManagementPage: React.FC = () => {

    const [currentLanguage, setCurrentLanguage] = useState("en")
    const [busy, setBusy] = useState<boolean>(false);
    const [isSync, setIsSync] = useState<boolean>(false);
    const [contentType, setContentType] = useState("tree");

    const [data, setData] = useState<PipelineSettings[]>([]);
    const [dataOrganization,setDataOrganization]=useState([]);
    const [dataAgency,setDataAgency]=useState([]);
    const [InputFields, setInputFields] = useState<object>(Object);

    const [SelectedPipeline, setSelectedPipeline] = useState<PipelineSettings>(Object);
    const [SelectedPhase, setSelectedPhase] = useState<PipelineSettings>(Object);
    const [SelectedStep, setSelectedStep] = useState<PipelineSettings>(Object);
    const [SelectedCategory, setSelectedCategory] = useState("tree");

    const [NewPipelineName, setNewPipelineName] = useState("");
    const [NewPipelinePosition, setNewPipelinePosition] = useState<number>(1);

    const [NewPhaseName, setNewPhaseName] = useState("");
    const [NewPhasePosition, setNewPhasePosition] = useState<number>(1);

    const [NewStepName, setNewStepName] = useState("");
    const [NewStepPosition, setNewStepPosition] = useState<number>(1);

    const [InputFieldOrganization,setInputFieldOrganization]=useState("")
    const [InputFieldAgency,setInputFieldAgency]=useState("")
    const [InputFieldName, setInputFieldName] = useState("");
    const [InputFieldMandatory, setInputFieldMandatory] = useState("no");
    const [InputFieldLabel, setInputFieldLabel] = useState("");
    const [InputFieldList, setInputFieldList] = useState("");
    const [InputFieldDefault, setInputFieldDefault] = useState("");
    const [InputFieldDataType, setInputFieldDataType] = useState("");
    const [InputFieldPhone, setInputFieldPhone] = useState("no");
    const [InputFieldEmail, setInputFieldEmail] = useState("no");
    const [InputFieldUnique, setInputFieldUnique] = useState("no");
    const [InputFieldDisplay, setInputFieldDisplay] = useState("no");
    const [InputFieldDisplaySelection, setInputFieldDisplaySelection] = useState("no");


    const [present] = useIonAlert();

    //findUserLanguage(setCurrentLanguage, null);
    let info =ListMenus["fr"]// InfoLang(currentLanguage)
    const menus = info.invitation.all;

    const entityNameFirebase = "CcPipelineSettings";

    const cleanInputs = () => {

    }

    const submitNewPipeline = async () => {
        let org = InputFieldOrganization, agency = InputFieldAgency
        let id = moment().format("YYYYMMDDHHmmSS")
        const ref = `${org}--${agency}--${id}`;
        let record: PipelineSettings = {
            Activities: [],
            Agency: agency,
            Fields: [],
            Files: [],
            Name: NewPipelineName,
            Position: NewPipelinePosition,
            Organization: org,
            Phases: [],
            Priorities: [],
            Stages: [],
            Statuses: [],
            Ref: ref
        }
        setBusy(true)
        // @ts-ignore
        await SubmitFormToFirebase2(record, entityNameFirebase, ref, "Are you sure to submit this pipeline?")
        cancelNew();
        setBusy(false)
    }
    const submitNewPhase = async () => {
        let id = moment().format("YYYYMMDDHHmmSS");

        let record: PipelineSettings = SelectedPipeline;
        let ref = `${record.Ref}---${cleanInput(NewPhaseName)}--${id}`;
        let phases: PipelineSettingPhase[] = [];
        if (typeof record.Phases !== "undefined") {
            phases = record.Phases;
        }
        phases.push({
            Activities: [],
            Agency: record.Agency,
            Organization: record.Organization,
            Fields: [],
            Files: [],
            Name: NewPhaseName,
            Position: NewPhasePosition,
            Priorities: [],
            Stages: [],
            Statuses: [],
            Steps: [],
            Ref: ref
        });
        setBusy(true)

        if (!window.confirm("Are you sure to submit this phase?")) {
            return
        }
        // @ts-ignore
        await UpdateFormToFirebase({Phases: phases}, entityNameFirebase, record.Ref);
        await toast('Your have update successfully your pipe line with new phase');
        cancelNew();
        setBusy(false);
    }
    const submitNewStep = async () => {
        //todo
        let record: PipelineSettings = SelectedPipeline;
        let pipeRef: string = record.Ref;
        let phases: PipelineSettingPhase[] = [];
        if (typeof record.Phases !== "undefined") {
            phases = record.Phases;
        }
        let steps: PipelineSettingStep[] = [];
        let indexPhase: number = 0;
        for (let i in phases) {
            let st = phases[i];
            if (st.Ref === SelectedPhase.Ref) {
                indexPhase = parseInt(i);
                if (typeof st.Steps !== "undefined") {
                    steps = st.Steps
                }
                break;
            }
        }

        let id = moment().format("YYYYMMDDHHmmSS");
        let ref = `${SelectedPhase.Ref}---${cleanInput(NewStepName)}--${id}`;

        steps.push({
            Activities: [],
            Agency: record.Agency,
            Organization: record.Organization,
            Fields: [],
            Files: [],
            Name: NewStepName,
            Position: NewStepPosition,
            Priorities: [],
            Stages: [],
            Statuses: [],
            Ref: ref,
        });

        phases[indexPhase].Steps = steps;

        // @ts-ignore
        await UpdateFormToFirebase({Phases: phases}, entityNameFirebase, record.Ref);
        await toast('Your have update successfully your pipe line with new step');
        cancelNew();
        setBusy(false);

    }
    const submitUpdatePipe = async (keyToUpdate: string, valueToUpdate: any, ref: string) => {
        setBusy(true);
        console.log("submitUpdatePipe > Ref: ", ref, " > keyToUpdate: ", keyToUpdate, " > valueToUpdate: ", valueToUpdate)
        // @ts-ignore
        await UpdateFormToFirebase({[keyToUpdate]: valueToUpdate}, entityNameFirebase, ref);
        await toast('Your have update successfully your pipe line with new step');
        cancelNew();
        setBusy(false);
    }
    const submitNewField = async () => {
        //TODO
        let record: PipelineSettings = SelectedPipeline;
        let cat = SelectedCategory;
        let entry: PipelineField = {
            Agency: record.Agency,
            Organization: record.Organization,
            DataType: InputFieldDataType,
            DefaultValue: InputFieldDefault,
            DisplayDateFormat: "",
            DisplayInAll: InputFieldEmail === "yes",
            DisplayInOptions: InputFieldEmail === "yes",
            Email: InputFieldEmail === "yes",
            Phone: InputFieldEmail === "yes",
            Identity: false,
            Label: InputFieldLabel,
            LeftEmpty: false,
            List: InputFieldList,
            Mandatory: InputFieldEmail === "yes",
            Name: InputFieldName,
            UniqueKey: InputFieldEmail === "yes",
        }

        if (cat === "pipe") {
            let fields: PipelineField[] = [];
            if (typeof record.Fields !== "undefined") {
                fields = record.Fields;
            }
            fields.push(entry)
            await submitUpdatePipe("Fields", fields, record.Ref)
        } else if (cat === "phase") {
            let phases: PipelineSettingPhase[] = [];
            if (typeof record.Phases !== "undefined") {
                phases = record.Phases;
            }
            let tmpPhases: PipelineSettingPhase[] = [];
            for (let i in phases) {
                let phase = phases[i];
                if (typeof phase.Fields === "undefined") {
                    phase.Fields = [];
                }
                if (phase.Ref === SelectedPhase.Ref) {
                    phase.Fields.push(entry)
                }
                tmpPhases.push(phase)
            }
            await submitUpdatePipe("Phases", tmpPhases, record.Ref)
        } else if (cat === "step") {
            let phases: PipelineSettingPhase[] = [];
            if (typeof record.Phases !== "undefined") {
                phases = record.Phases;
            }
            let tmpPhases: PipelineSettingPhase[] = [];
            for (let i in phases) {
                let phase = phases[i];
                if (phase.Ref !== SelectedPhase.Ref) {
                    continue
                }
                if (typeof phase.Steps === "undefined") {
                    phase.Steps = [];
                }
                for (let y in phase.Steps) {
                    let step = phase.Steps[y];
                    if (step.Ref !== SelectedStep.Ref) {
                        continue
                    }
                    if (typeof step.Fields === "undefined") {
                        step.Fields = [];
                    }
                    step.Fields.push(entry)
                    phase.Steps[y] = step;
                }

                tmpPhases.push(phase)
            }
            await submitUpdatePipe("Phases", tmpPhases, record.Ref)
        }

    }

    const localSyncData = () => {
        SyncDataOrigin(setData, entityNameFirebase);
        SyncData(setDataOrganization, "CcOrganizations", "Code", cleanInputs, styles.aIcon, true)
        SyncData(setDataAgency, "CcAgency", "UniqueRef", cleanInputs, styles.aIcon, true)
        setIsSync(true)
    }
    const requestNewPipeline = () => {
        setContentType("pipeline");
        setNewPhaseName("");
        setNewPipelinePosition(0)
    }
    const cancelNew = () => {
        setContentType("tree");
        setNewStepName("");
        setNewStepPosition(0);
        setSelectedPipeline(Object);
        setSelectedPhase(Object);
        setNewPhaseName("");
        setNewPhasePosition(0);
        setSelectedPipeline(Object);
        setNewPipelineName("");
        setNewPipelinePosition(0)
    }

    useEffect(() => {
        if (!isSync) {
            localSyncData();
        }
    }, []);

    const requestSetCurrentInfo = (pipe: PipeTree, targetContent: string) => {
        setSelectedPipeline(pipe.currentPipe);
        setSelectedPhase(pipe.currentPhase);
        setSelectedStep(pipe.currentStep);
        setSelectedCategory(pipe.category);
        setContentType(targetContent);
    }
    const buildAddInfo = (fieldName: string, fieldValue: string) => {
        return (
            <p>
                <span style={styles.buildAddInfoField}>{fieldName}</span>:
                <span style={styles.buildAddInfoValue}>{fieldValue}</span>
            </p>
        )
    }


    const renderTree = () => {

        const onSelect = async (node: any) => {
            let info = node.state.node;
            let pipe: PipelineSettings = info.record;
            console.log(`node > `, node.state.node, ` selected`);
            if (info.nextAction === "phase") {
                await present({
                    cssClass: 'my-css',
                    header: 'Pipeline Action',
                    message: `Do you know that you can Add or remove from this pipeline: ${info.category} - ${info.name}`,
                    buttons: [
                        'Close',
                        {
                            text: 'Add New Phase', handler: (d) => {
                                requestSetCurrentInfo(info, "phase")
                            }
                        },
                        {
                            text: 'REQUIRED FIELDS', handler: (d) => {
                                requestSetCurrentInfo(info, "attribute")
                            }
                        },
                        {text: 'Remove Pipe', handler: (d) => console.log('remove pressed')},
                    ],
                    onDidDismiss: (e) => console.log('did dismiss'),
                })
            } else if (info.nextAction === "step") {
                await present({
                    cssClass: 'my-css',
                    header: 'Phase Action',
                    /*message: `Do you know that you can Add or remove from this phase: ${info.currentPhase.Name}`,*/
                    buttons: [
                        'Close',
                        {
                            text: 'Add New Step', handler: (d) => {
                                requestSetCurrentInfo(info, "step")
                            }
                        },
                        {
                            text: 'REQUIRED FIELDS', handler: (d) => {
                                requestSetCurrentInfo(info, "attribute")
                            }
                        },
                        {text: 'Remove Pipe', handler: (d) => console.log('remove pressed')},
                    ],


                    onDidDismiss: (e) => console.log('did dismiss'),
                })
            } else if (info.category === "step") {
                await present({
                    cssClass: 'my-css',
                    header: 'Phase Action',
                    /*message: `Do you know that you can Add or remove from this phase: ${info.currentPhase.Name}`,*/
                    buttons: [
                        'Close',
                        {
                            text: 'REQUIRED FIELDS', handler: (d) => {
                                requestSetCurrentInfo(info, "attribute")
                            }
                        },
                        {text: 'Remove Step', handler: (d) => console.log('remove pressed')},
                    ],
                    onDidDismiss: (e) => console.log('did dismiss'),
                })
            }


        }
        const onOpen = (node: any) => {
            console.log(`node ${node.path} opened`)
        }
        const makePipeTreeRecord = (name: string, record: any, mode: string, cat: string, parentRef: any, parentCat: string, nextAction: string, currPipe: PipelineSettings,
                                    currPhase: PipelineSettings, currStep: PipelineSettings) => {
            let o: { mode: string; currentStep: PipelineSettings; currentPipe: PipelineSettings; currentPhase: PipelineSettings; record: any; name: string; parentCategory: string; nextAction: string; category: string; parentRef: any } = {
                name: name,
                record: record,
                mode: mode,
                category: cat,
                parentRef: parentRef,
                parentCategory: parentCat,
                nextAction: nextAction,
                currentPipe: currPipe,
                currentPhase: currPhase,
                currentStep: currStep,
            }
            return o;
        }
        const fetchFields = (record: PipelineSettings, inNode: PipeTree) => {
            if (typeof record.Fields === "undefined") {
                return inNode
            }
            let info: PipeTree = makePipeTreeRecord("REQUIRED FIELDS", record, "", "subtitle", "", "pipe",
                "end", inNode.currentPipe, inNode.currentPhase, inNode.currentStep);
            info.childNodes = [];
            for (let i in record.Fields) {
                let recField = record.Fields[i];
                let recPipe: PipeTree = makePipeTreeRecord(recField.Name, recField, "", "field", record.Ref, inNode.parentCategory,
                    "end", inNode.currentPipe, inNode.currentPhase, inNode.currentStep);
                info.childNodes.push(recPipe)
            }
            if (typeof inNode.childNodes === "undefined") {
                inNode.childNodes = [];
            }
            inNode.childNodes.push(info)
            return inNode;
        }

        let ls = [];
        //let sort our list order position
        let store = SortDataAsc("Position", data)
        for (let i in store) {
            let row = store[i];
            let recordMain: PipeTree = makePipeTreeRecord(row.Name, row, "a", "pipe", "", "pipe", "phase", row, row, row)


            /**
             * implement the pipe child list (phase)
             */
            if (typeof row.Phases !== "undefined") {
                let phases = row.Phases;
                let phaseEntry: PipeTree = makePipeTreeRecord("PHASES", row, "a", "subtitle", row, "pipe", "phase", row, row, row)
                phaseEntry.childNodes = [];
                for (let x in phases) {
                    const rowPhase = phases[x];
                    let recPhase: PipeTree = makePipeTreeRecord(rowPhase.Name, rowPhase, "a", "phase", row, "pipe", "step", row, rowPhase, rowPhase)
                    /**
                     * implement the phase child list (step)
                     */
                    if (typeof rowPhase.Steps !== "undefined") {
                        recPhase.childNodes = []
                        let steps = rowPhase.Steps;
                        let stepEntry: PipeTree = makePipeTreeRecord("STEPS", rowPhase, "a", "subtitle", rowPhase, "phase", "end", row, rowPhase, rowPhase)
                        stepEntry.childNodes = []

                        for (let y in steps) {
                            let recStep = steps[y];
                            console.log("$$$$$ recStep > ", recStep)
                            let my = makePipeTreeRecord(recStep.Name, recStep, "a", "step", rowPhase, "phase", "end", row, rowPhase, recStep)
                            my = fetchFields(recStep, my)
                            stepEntry.childNodes.push(my)

                        }

                        recPhase.childNodes.push(stepEntry)
                    }
                    phaseEntry.childNodes.push(recPhase)
                }


                recordMain.childNodes = [];
                recordMain.childNodes.push(phaseEntry)
            }


            ls.push(recordMain);
        }

        return (
            <FSRoot onSelect={onSelect} onOpen={onOpen} childNodes={ls}/>
        )

    }
    const renderNewPipe = () => {
        return (

            <div style={styles.container}>
                <h1 style={{color: "#3880ff"}}>
                    <u>New Pipeline!</u>
                    <a onClick={() => cancelNew()}
                       style={styles.cancelNewPipeline}
                    >
                        <IonIcon slot="start" ios={closeCircleSharp} md={closeCircleSharp}/>
                    </a>
                </h1>

                <TextInput
                    name={"Name"}
                    placeholder={"Name"}
                    setValue={setNewPipelineName}
                    typeInput={"text"}
                    required={true}
                />
                <TextInput
                    name={"Position"}
                    placeholder={"Position"}
                    setValue={setNewPipelinePosition}
                    typeInput={"numeric"}
                    required={true}
                />

                <InputButton
                    name={"Submit New Pipeline"}
                    textColor={"secondary"}
                    backgroundColor={"#3880ff"}
                    btnOnClick={submitNewPipeline}
                    borderRadius={25}
                    expand={"full"}
                    icon={saveSharp}
                    iconSlot={"start"}
                />

            </div>

        )
    }
    const renderNewPhase = () => {
        return (

            <div style={styles.container}>
                <h1 style={{color: "#3880ff"}}>
                    <u>New phase!</u>
                    <a onClick={() => cancelNew()}
                       style={styles.cancelNewPipeline}
                    >
                        <IonIcon slot="start" ios={closeCircleSharp} md={closeCircleSharp}/>
                    </a>
                </h1>
                {buildAddInfo("Pipeline", SelectedPipeline.Name)}

                {buildAddInput(NewPhaseName, "Name", "Name", setNewPhaseName, "text", true)}
                {buildAddInput(NewPhasePosition, "Position", "Position", setNewPhasePosition, "numeric", true)}
                {buildAddButton("Submit New Phase", "secondary", "#3880ff", submitNewPhase)}

            </div>

        )
    }
    const renderNewStep = () => {
        return (
            <div style={styles.container}>
                <h1 style={{color: "#3880ff"}}>
                    <u>New Step!</u>
                    <a onClick={() => cancelNew()}
                       style={styles.cancelNewPipeline}
                    >
                        <IonIcon slot="start" ios={closeCircleSharp} md={closeCircleSharp}/>
                    </a>
                </h1>
                {buildAddInfo("Pipeline", SelectedPipeline.Name)}
                {buildAddInfo("Phase", SelectedPhase.Name)}

                {buildAddInput(NewStepName, "Name", "Name", setNewStepName, "text", true)}
                {buildAddInput(NewStepPosition, "Position", "Position", setNewStepPosition, "numeric", true)}

                {buildAddButton("Submit New Phase", "secondary", "#3880ff", submitNewStep)}
            </div>
        )
    }
    const renderNewField = () => {
        return (

            <div style={styles.container}>
                <h1 style={{color: "#3880ff"}}>
                    <u>New Field!</u>
                    <a onClick={() => cancelNew()}
                       style={styles.cancelNewPipeline}
                    >
                        <IonIcon slot="start" ios={closeCircleSharp} md={closeCircleSharp}/>
                    </a>
                </h1>

                {buildAddInput(InputFieldName, "Name", "Field Name", setInputFieldName, "text", true)}
                {buildAddInput(InputFieldLabel, "Label", "Label", setInputFieldLabel, "text", true)}
                {buildAddInput(InputFieldMandatory, "Mandatory", "Mandatory", setInputFieldMandatory, "boolean", true)}
                {buildAddInput(InputFieldList, "List", "List option(e.g. Male,Female,..)", setInputFieldList, "text", true)}
                {buildAddInputSelect(InputFieldDataType, "DataType", "Data Type", setInputFieldDataType, "option", true,
                    [{key: "text", val: "Text"}, {key: "float", val: "Number or Decimal"}, {
                        key: "date",
                        val: "Date"
                    }, {key: "boolean", val: "Yes Or No"},])}
                {buildAddInput(InputFieldPhone, "Used as phone number?", "Used as phone number?", setInputFieldPhone, "boolean", true)}
                {buildAddInput(InputFieldEmail, "Used as email address?", "Used as email address?", setInputFieldEmail, "boolean", true)}
                {buildAddInput(InputFieldUnique, "Used as unique key?", "Used as unique key?", setInputFieldUnique, "boolean", true)}
                {buildAddInput(InputFieldDisplaySelection, "Used as display in selection option?", "Used as display in selection?", setInputFieldDisplaySelection, "boolean", true)}
                {buildAddInput(InputFieldLabel, "Default Value", "Default Value if not provided", setInputFieldLabel, "text", true)}

                {buildAddButton("Submit New Field", "secondary", "#3880ff", submitNewField)}
            </div>
        )

    }
    const getSelectedInput=(key:string):string=>{
        let value:string = "";
        // @ts-ignore
        if(typeof inputData[key]!=="undefined"){
            // @ts-ignore
            value = inputData[key].toString()
        }
        return value;
    }

    const getAgencyOption=():SelectOption[]=>{
        //let get selected company

        let ls:SelectOption[]=[];
        for(let i in dataAgency){
            let row = dataAgency[i];

            // @ts-ignore
            if(row.Organization!==InputFieldOrganization){continue}

            // @ts-ignore
            const code:string = row.Code;
            // @ts-ignore
            const name:string = row.Name;
            ls.push({
                key:code,
                val:name
            })
        }

        console.log(":::)-> ",ls)
        return ls
    }
    const getOrganizationOption=():SelectOption[]=>{
        let ls:SelectOption[]=[];
        for(let i in dataOrganization){
            let row=dataOrganization[i];
            // @ts-ignore
            const code:string = row.Code;
            // @ts-ignore
            const name:string = row.Name;
            ls.push({
                key:code,
                val:name
            })
        }
        return ls
    }




    return (
        <div style={styles.container}>
            <IonLoading message={"please wait.."} duration={0} isOpen={busy}/>

            {contentType === "tree" && (
                <>
                    <IonLabel style={styles.titleComponent}>
                        Customer Process Flow (Pipeline)
                        <a
                            onClick={() => requestNewPipeline()}
                            style={styles.aIcon}
                        >
                            <IonIcon icon={addCircle} color={"primary"}>ADD NEW</IonIcon>
                        </a>
                    </IonLabel>
                    {buildAddInputSelect(InputFieldOrganization, "Organization", "Organization", setInputFieldOrganization, "option", true,
                        getOrganizationOption())}
                    {buildAddInputSelect(InputFieldAgency, "Agency", "Agency", setInputFieldAgency, "option", true,
                        getAgencyOption())}
                    {renderTree()}
                </>
            )}


            {contentType === "pipeline" && renderNewPipe()}
            {contentType === "phase" && renderNewPhase()}
            {contentType === "step" && renderNewStep()}
            {contentType === "attribute" && renderNewField()}


        </div>
    )
}

export default PipelineManagementPage;

const styles = {
    buildAddInfoField: {
        color: "red",
        fontSize: 12,
        marginLeft: 10
    },
    buildAddInfoValue: {
        color: "gray",
        fontSize: 12,
        marginLeft: 10
    },
    titleComponent: {
        color: "#3880ff",
        fontSize: 18,
        fontWeight: "bold",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        marginTop: 20
    },
    requestNewPipeline: {
        float: "right",
        color: "blue",
        fontSize: 19,
        fontWeight: "bold"
    },
    cancelNewPipeline: {
        float: "right",
        color: "red",
        fontSize: 19,
        fontWeight: "bold",
        cursor: "pointer"
    },
    aIcon: {
        fontSize: 24,
        cursor: "pointer"
    },
    container: {
        minWidth: "100%",
        paddingRight: 20,

    }
}

