import React from "react";
import {IonInput} from "@ionic/react";


export default (props:any)=>{
    let {name,setValue,typeInput,required,placeholder}=props;
    return(
        <div style={styles.wrapInput}>

            <IonInput
                style={styles.input}
                placeholder={placeholder}
                onIonChange={(e:any)=>setValue(e.target.value,name)}
                type={typeInput}
            />
        </div>
    )
}

const styles={
    input: {
        display: "block",
        width: "100%",
        back: "transparent",
        fontFamily: "Josefin Sans",
        fontSize: 16,
        lineHeight: 1.2,
        border: "none",
        borderRadius: 20,
        height: "40px",
        outline: "none",
        padding: "0 20px 0 23px"
    },
    wrapInput: {
        backgroundColor: "rgba(255, 255, 255, .8)",
        borderRadius: 20,
        marginBottom: 15,
        boxShadow: "0 10px 30px 0px rgba(57, 71, 82, 0.12)"
    },
}
