import firebase from "../configures/firebase-init";
import moment from "moment";
import {FirestoreQueryCondition} from "../mocks/types";


export const queryFirestoreData=(collection:string,conditions:FirestoreQueryCondition[])=>{
    // Create a reference to the cities collection
    let ref = firebase.firestore().collection(collection);

// Create a query against the collection.
    let query;
    for(let i in conditions){
        let row = conditions[i];
        ref.where(row.Key,row.Operation as "<" | "<=" | "==" | "!=" | ">=" | ">" | "array-contains" | "in" | "array-contains-any" | "not-in",row.Value)
    }
   return ref.get()
       .then((querySnapshot)=>{
           let records: any[]  = [];
           querySnapshot.forEach((doc) => {
               // doc.data() is never undefined for query doc snapshots
               records.push(doc.data())
           });
           return records
       })

}

export const getCurrentLocationApp=async (fn:any)=>{
    let latitude = 0;
    let longitude=0;
    await  navigator.geolocation.getCurrentPosition(function(position) {

        let latitude =  position.coords.latitude;
        let longitude = position.coords.longitude;

        console.log("Latitude is :", position.coords.latitude);
        console.log("Longitude is :", position.coords.longitude);
        fn({latitude,longitude});
    });
   // return{latitude,longitude}
}
export async function toast(message:string, duration=2000) {
    const toast = document.createElement('ion-toast');
    toast.message = message;
    toast.duration = duration;

    document.body.appendChild(toast);
    return toast.present();
}
export const setFirestoreEntityWithKey2 = async (collection: any,path:string, key: string, data: any) => {
    data.CreatedAt = firebase.firestore.FieldValue.serverTimestamp();
    data.CreateDate = moment().format("YYYY-MM-DD")
    data.CreateTime = moment().format("HH:mm:ss");

    firebase.firestore()
        .collection(collection)
        .doc(path)
        .collection(key)
        .doc(key)
        .set(data)
        .then((info)=>{
            console.log("setFirestoreEntityWithKey info: ",info);
        })
        .catch((error) => console.log("(:)->ERROR setFirestoreEntityWithKey > ",error));
}
export const setFirestoreEntityWithKey = async (collection: any, key: string, data: any, feedback: any) => {
    data.CreatedAt = firebase.firestore.FieldValue.serverTimestamp();
    data.CreateDate = moment().format("YYYY-MM-DD")
    data.CreateTime = moment().format("HH:mm:ss");

    console.log("onSubmit hub > ",key,">",data,)
    firebase.firestore()
        .collection(collection)
        .doc(key)
        .set(data)
        .then((info)=>{
            console.log("setFirestoreEntityWithKey info: ",info);
        })
        .catch((error) => console.log("(:)->ERROR setFirestoreEntityWithKey > ",error));
}
export const setFirestoreEntity=async (collection:any,key:string,data:any,feedback:any)=>{
    data.CreatedAt = firebase.firestore.FieldValue.serverTimestamp();
    data.CreateDate = moment().format("YYYY-MM-DD")
    data.CreateTime = moment().format("HH:mm:ss")

    firebase.firestore()
        .collection(collection)
        .add(data)
        .then((snapshot) => {
            data.id = snapshot.id;
            snapshot.set(data);
        }).then(() => feedback(data))
        .catch((error) => console.log("(:)->ERROR setFirestoreEntity > ",error," > ",data));
}
export const GetDatabase = async (refKey: string, fn: any) => {
    let root = refKey;
    let ref = firebase.database().ref(root);
    ref.on("value", function (snapshot) {
        fn(snapshot.val())
    }, function (errorObject) {
        console.log("The read failed: " + errorObject);
    });
}
export async function removeEntityDatabase(route: string, key: any) {

    const path = `${route}/${key}`
    await firebase.database().ref(path).remove((error)=>{
        console.log("removeEntityDatabase data: ", error," > ",path);
    });
}
export async function removeEntityFirestore(route: string, key: any) {
    await firebase.firestore().collection(route).doc(key).delete();
}
export async function updateEntityDatabase(route: string, key:string, data: any) {
    //let key = firebase.database().ref().push().key
    const path = `${route}/${key}`
    await firebase.database()
        .ref(path)
        .update(data).then((snpashot) => {
            console.log("updateEntityDatabase data: ", snpashot);
            return {snpashot, error: null}
        }).catch((err) => {
            console.log("Error saving: ", err)
            return {snpashot: null, error: err}
        })
}
export async function setEntityDatabaseKey(route: string,key:string, data: any) {
    //let key = firebase.database().ref().push().key
    data.CreatedAt = firebase.firestore.FieldValue.serverTimestamp();
    data.CreateDate = moment().format("YYYY-MM-DD")
    data.CreateTime = moment().format("HH:mm:ss")
    const path = `${route}/${key}`
    await firebase.database()
        .ref(route)
        .child(key)
        .set(data).then((snpashot) => {
            return {snpashot, error: null}
        }).catch((err) => {
            return {snpashot: null, error: err}
        })
}
export async function setEntityDatabaseKey2(route: string,key:string,innerKey:string, data: any) {
    //let key = firebase.database().ref().push().key
    data.CreatedAt = firebase.firestore.FieldValue.serverTimestamp();
    data.CreateDate = moment().format("YYYY-MM-DD")
    data.CreateTime = moment().format("HH:mm:ss")
    const path = `${route}/${key}`
    await firebase.database()
        .ref(route)
        .child(key)
        .child(innerKey)
        .set(data).then((snpashot) => {
            return {snpashot, error: null}
        }).catch((err) => {
            return {snpashot: null, error: err}
        })
}

export async function setEntityDatabase(route: string, data: any) {
    data.CreatedAt = firebase.firestore.FieldValue.serverTimestamp();
    data.CreateDate = moment().format("YYYY-MM-DD")
    data.CreateTime = moment().format("HH:mm:ss")
    let key = firebase.database().ref().push().key
    const path = `${route}/${key}`
    await firebase.database()
        .ref(path)
        .set(data).then((snpashot) => {
            console.log("Saved data: ", snpashot);
            return {snpashot, error: null}
        }).catch((err) => {
            console.log("Error saving: ", err)
            return {snpashot: null, error: err}
        })
}

export function logoutUser() {
    return firebase.auth().signOut();
}

export const getUserLoginInfo=async (username:string)=>{
    let root = "users";
    let ref = firebase.database().ref(root);
    return ref.once("value", function (snapshot) {
        let allData = snapshot.val();
        return allData;
    }, function (errorObject) {
        console.log("The read failed: " + errorObject);
    });
}

export async function getCurrentUser() {
    return new Promise((resolve, reject) => {
        const unsubscribe = firebase.auth().onAuthStateChanged(function (user) {
            if (user) {
                resolve(user);
            } else {
                resolve(null)
            }
            unsubscribe();
        })
    })
    //return firebase.auth().currentUser
}

export async function loginUser(username: string, password: string) {

    try {
        const res = await firebase.auth().signInWithEmailAndPassword(username, password)
        console.log("Auth success: ", res)
        return res;
    } catch (e) {
        console.log("auth error: ", e)
        await toast(e.message, 4000)
    }
    // authentication with firebase
    // if present, show dashboard
    // if not, show error
}

export async function registerUser(username: string, password: string, fullName: string, phone: string) {
    try {
        const res = await firebase.auth().createUserWithEmailAndPassword(username, password);
        let key = firebase.database().ref().push().key
        console.log("Register feedback: ", res)
        // @ts-ignore
        await setEntityDatabaseKey("users", key,{
            Username: username,
            FullName: fullName,
            Phone: phone,
            Status: "active",
            Role: "agent",
            Code:key
        })
        return true
    } catch (e) {
        console.log("register error: ", e)
        await toast(e.message, 4000)
        return false
    }
    /**
     * Create also the user object
     */

}

export default firebase;

