import {
    SET_PROFILE,
    SET_USER_STATE,
    SET_AUTH_ENTITY_DATA,
    SET_LOAD_COUNTRY,
    SET_SIM_TARGET, SET_DASHBOARD_DATA,
    SET_LANGUAGE_STATE
} from "../constants/constant-redux";

export const setLanguageState =(payload:any)=>{
    return {type: SET_LANGUAGE_STATE,payload}
}

export const setUserState =(payload:any)=>{
    return {type: SET_USER_STATE,payload}
}
export const setProfile =(payload:any)=>{
    return {type: SET_PROFILE,payload}
}
export const setEntityData =(payload:any)=>{
    return {type: SET_AUTH_ENTITY_DATA,payload}
}
export const setCountries =(payload:any)=>{
    return {type: SET_LOAD_COUNTRY,payload}
}
export const setSimTarget=(payload:any)=>{
    return{type:SET_SIM_TARGET,payload}
}
export const setDashboardValue=(payload:any)=>{
    return{type:SET_DASHBOARD_DATA,payload}
}





