// @ts-ignore
import {SubmitRequestProcessFieldEntry} from "../mocks/types"
import firebase from "../configures/firebase-init";
import moment from "moment";
import {ConvertStringToBase64, escapeEmailAddress} from "./general-functions";
import {saveDataIntoOneRef, saveDataIntoTwoRef,fetchingOnceDataFromDatabase,removeDataFromDb,saveDataIntoThreeRef,saveDataIntoZeroRef} from "./submit-common";


export const SubmitRequestProcessPipeline=async (infoSubmit:SubmitRequestProcessFieldEntry)=>{

    let entity="CcProcessPipelineData";
    let username = escapeEmailAddress(infoSubmit.Username);
    let queueRef = infoSubmit.QueueRef;
    let currentDate = moment().format("YYYY-MM-DD");
    let currentTime = moment().format("HH:mm:SS");
    const appendDataTimeTotal=(inRecord:any,total:number)=>{
        inRecord.LastUpdatedDate = currentDate;
        inRecord.LastUpdatedTime = currentTime;
        inRecord.Total = total;
        return inRecord
    }

    /**
     * let save into our process history data realtime
     */
    infoSubmit.CreatedDate = moment().format("YYYY-MM-DD");
    infoSubmit.CreatedTime = moment().format("HH:mm:ss");
    await saveDataIntoZeroRef(entity,infoSubmit)
    /**
     * let save into firestore
     */
    let targetCcProcessPipelineDataRef2 = firebase.firestore().collection(`${entity}`);
    await targetCcProcessPipelineDataRef2.add(infoSubmit)
    /**
     *  let make dashboard daily agent all submitted
     */
    let keyRef = `CcProcessPipelineReportAgentDaily/${username}/${currentDate}`;
    await fetchingOnceDataFromDatabase(keyRef,async (dataOut:any)=>{
        if(dataOut!==null){
            dataOut  = appendDataTimeTotal(dataOut,parseInt(dataOut.Total) + 1)
            await saveDataIntoTwoRef("CcProcessPipelineReportAgentDaily",username,currentDate,dataOut)
        }else{
            dataOut={}
            dataOut  = appendDataTimeTotal(dataOut,1)
            dataOut.Username = currentDate;
            await saveDataIntoTwoRef("CcProcessPipelineReportAgentDaily",username,currentDate,dataOut)
        }
    })
    /**
     *  let make dashboard daily agent queue submitted
     */
    keyRef = `CcProcessPipelineReportAgentQueue/${username}/${queueRef}`;
    await fetchingOnceDataFromDatabase(keyRef,async (dataOut:any)=>{
        if(dataOut!==null){
            dataOut  = appendDataTimeTotal(dataOut,parseInt(dataOut.Total) + 1)
            await saveDataIntoTwoRef("CcProcessPipelineReportAgentQueue",username,queueRef,dataOut)
        }else{
            dataOut={}
            dataOut  = appendDataTimeTotal(dataOut,1)
            dataOut.Username = currentDate;
            await saveDataIntoTwoRef("CcProcessPipelineReportAgentQueue",username,queueRef,dataOut)
        }
    })
    /**
     *  let make dashboard daily agent queue submitted
     */
    keyRef = `CcProcessPipelineReportAgentQueueDaily/${username}/${queueRef}/${currentDate}`;
    await fetchingOnceDataFromDatabase(keyRef,async (dataOut:any)=>{
        if(dataOut!==null){
            dataOut  = appendDataTimeTotal(dataOut,parseInt(dataOut.Total) + 1)
            await saveDataIntoThreeRef("CcProcessPipelineReportAgentQueueDaily",username,queueRef,currentDate,dataOut)
        }else{
            dataOut={}
            dataOut  = appendDataTimeTotal(dataOut,1)
            dataOut.Username = currentDate;
            await saveDataIntoThreeRef("CcProcessPipelineReportAgentQueueDaily",username,queueRef,currentDate,dataOut)
        }
    })
    /**
     *  let make dashboard queue ref daily submitted
     */
    keyRef = `CcProcessPipelineReportQueueRefDaily/${queueRef}/${currentDate}`;
    await fetchingOnceDataFromDatabase(keyRef,async (dataOut:any)=>{
        if(dataOut!==null){
            dataOut  = appendDataTimeTotal(dataOut,parseInt(dataOut.Total) + 1)
            await saveDataIntoTwoRef("CcProcessPipelineReportQueueRefDaily",queueRef,currentDate,dataOut)
        }else{
            dataOut={}
            dataOut  = appendDataTimeTotal(dataOut,1)
            dataOut.Username = currentDate;
            await saveDataIntoTwoRef("CcProcessPipelineReportQueueRefDaily",queueRef,currentDate,dataOut)
        }
    })
    /**
     *  let make dashboard  queue ref all submit
     */
    keyRef = `CcProcessPipelineReportQueueRefAll/${queueRef}`;
    await fetchingOnceDataFromDatabase(keyRef,async (dataOut:any)=>{
        if(dataOut!==null){
            dataOut  = appendDataTimeTotal(dataOut,parseInt(dataOut.Total) + 1)
            await saveDataIntoOneRef("CcProcessPipelineReportQueueRefAll",queueRef,dataOut)
        }else{
            dataOut={}
            dataOut  = appendDataTimeTotal(dataOut,1)
            dataOut.Username = currentDate;
            await saveDataIntoOneRef("CcProcessPipelineReportQueueRefAll",queueRef,dataOut)
        }
    })

    /**
     * todo let look for queue pipeline listening waiting for this case
     */
    keyRef = `CcQueuePipelineSetting`;
    await fetchingOnceDataFromDatabase(keyRef,async (dataOut:any)=>{
        if(dataOut!==null){
            for(let i in dataOut){
                let row=dataOut[i];
                if(row.Organization!==infoSubmit.Organization){continue}
                if(row.Agency!==infoSubmit.Agency){continue}
                if(row.Pipeline !==infoSubmit.Pipeline){continue}
                if(row.Phase !==infoSubmit.Phase){continue}
                //let save our queue subscriber data
                let innerRef = ConvertStringToBase64(`${row.Organization}#${row.Agency}#${row.Pipeline}#${row.Phase}#${row.Ref}#${infoSubmit.Ref}`);
                await saveDataIntoTwoRef(`CcQueuePipelineData`,row.Ref,innerRef,infoSubmit)
                //let update total provider
                row.Total = parseInt(row.Total)+1
                row.LastUpdatedDate = currentDate;
                row.LastUpdatedTime =currentTime;
                row.LastUpdateUser = infoSubmit.Username;
                await saveDataIntoOneRef(keyRef,row.Ref,row)
            }
        }

    });

    /**
     *  let remove this lead from worker handle for this agent
     */
    await removeDataFromDb("CcWorkerHandlePipelineData",escapeEmailAddress(infoSubmit.Username));



}

