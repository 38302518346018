import React, {useState} from "react";
import {IonContent, IonLabel, IonLoading, IonPage} from "@ionic/react";

const CommonHomePage: React.FC = () => {
    const [busy, setBusy] = useState<boolean>(false);
    return(
        <IonPage>
            <IonLoading message={"Veuillez patienter s'il vous plaît..."} duration={0} isOpen={busy}/>
            <IonContent fullscreen className={"ion-padding"}>
                <div style={styles.container}>
                <IonLabel style={styles.titleComponent}>
                    Home page
                </IonLabel>
                <IonLabel>
                    Select your menu to continue
                </IonLabel>
                </div>

            </IonContent>
        </IonPage>
    )
}

export default CommonHomePage;

const styles={
    container:{
        display:"flex",
        flex:1,
        flexDirection: "column",
        justifyContent:"center",
        alignItems: "center",
        marginTop: 50,
        minWidth:"100%",
        minBodyHeight:"100%"
    },
    titleComponent: {
        color: "#3880ff",
        fontSize: 18,
        fontWeight: "bold",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        marginTop: 10,
        marginBottom:10,
    }

}
