import React, {useEffect, useState} from "react";
import {IonIcon, IonLabel, IonList, IonLoading} from "@ionic/react";
import {
    buildAddButton, buildAddInfo,
    buildAddInput,
    buildAddInputSelect, cancelNew, ConvertStringToBase64,
    findDisplayKeyInArray,
    findUserLanguage,
    requestEdit,
    SubmitFormToFirebase,
    SyncData
} from "../../services/general-functions";
import {addCircle, closeCircleSharp} from 'ionicons/icons';
import {PipelineSettings, propsColumns, QueueEntry, SelectOption} from "../../mocks/types";
import {InfoLang, menus as ListMenus} from "../../mocks/language";
import {DefaultFormInputFields, GetInputData, OnChangeInput} from "../../services/form-inputs";
import moment from "moment";
import TableDataView from "../../components/common/TableDataView";
import {InvitationFieldEntry} from "../../../functions/src/services/types";
import store from "../../redux/store";

type QueueFilterType = {
    Organization:string,
    Agency:string,
    Code: string,
    Ref: string,
    Name: string,
}

const WorkerCheckinPage: React.FC = () => {
    const [currentLanguage, setCurrentLanguage] = useState("en")
    const [busy, setBusy] = useState<boolean>(false);
    const [IsEdit, setIsEdit] = useState<boolean>(false);
    const [isSync, setIsSync] = useState<boolean>(false);
    const [data, setData] = useState([]);
    const [dataOrganization, setDataOrganization] = useState([]);
    const [dataAgency, setDataAgency] = useState([]);
    const [dataInvitationField, setDataInvitationField] = useState<InvitationFieldEntry[]>([]);
    const [dataInvitationPipelines, setDataInvitationPipelines] = useState<InvitationFieldEntry[]>([]);
    const [dataQueueFields, setDataQueueFields] = useState<QueueEntry[]>([]);
    const [dataQueuePipelines, setDataQueuePipelines] = useState<QueueEntry[]>([]);
    const [inputData, setInputData] = useState<Object>({});

    const [InputFieldChannel, setInputFieldChannel] = useState<string>("");
    const [InputFieldQueue, setInputFieldQueue] = useState<string>("");
    const [InputFieldComment, setInputFieldComment] = useState<string>("");


    //findUserLanguage(setCurrentLanguage, null);
    let info =ListMenus["fr"]// InfoLang(currentLanguage)
    const menus = info.invitation.all;

    const entityNameFirebase = "CcWorkerCheckin";
    const title = menus.pageTitle;
    const titleList = menus.tableListTitle;
    const titleForm = menus.submitNewRecordText;

    const onChangeInput = (value: any, key: string) => {
        OnChangeInput(value, key, inputData, setInputData)
    }
    const getInputData = (key: string) => {
        return GetInputData(key, inputData)
    }
    const cleanInputs = () => {
        setBusy(false)
    }
    const getQueueOption = (): SelectOption[] => {
        let ls: SelectOption[] = [];

        if (InputFieldChannel === "field") {
            for (let i in dataInvitationField) {
                let row = dataInvitationField[i];
                let key: string = JSON.stringify(row)
                let val: string = findDisplayKeyInArray(row.Queue, dataQueueFields, "UniqueRef", "Name");
                ;
                ls.push({
                    key: key,
                    val: val
                })
            }
        }

        if (InputFieldChannel === "pipeline") {
            for (let i in dataInvitationPipelines) {
                let row = dataInvitationPipelines[i];
                let key: string = JSON.stringify(row)
                let val: string = findDisplayKeyInArray(row.Queue, dataQueuePipelines, "UniqueRef", "Name");
                ls.push({
                    key: key,
                    val: val
                })
            }
        }

        return ls
    }

    const optionsCategories: SelectOption[] = [
        {key: "field", val: "Field Agent"},
        {key: "pipeline", val: "Pipeline"},
        /*{key:"batch",val:"Batch Csv"},*/
    ];
    const optionsQueue: SelectOption[] = [];


    let columns: propsColumns[];
    columns = [
        DefaultFormInputFields("#", onChangeInput, getInputData, false, true, "text",
            [], "Action", ""),
        DefaultFormInputFields(menus.fieldOrganizationLabel, onChangeInput, getInputData, true, false,
            "option", [], "Organization", menus.fieldOrganizationError),
        DefaultFormInputFields(menus.fieldAgencyLabel, onChangeInput, getInputData, true, false,
            "option", [], "Agency", menus.fieldAgencyError),
        DefaultFormInputFields(menus.fieldChannelLabel, onChangeInput, getInputData, true, false,
            "option", optionsCategories, "Channel", menus.fieldChannelError),
        DefaultFormInputFields(menus.fieldQueueLabel, onChangeInput, getInputData, true, false,
            "option", optionsQueue, "Queue", menus.fieldQueueError),
        DefaultFormInputFields(menus.fieldCreateDateLabel, onChangeInput, getInputData, false, true,
            "text", [], "CreateDate", menus.fieldCreateDateError),
        DefaultFormInputFields(menus.fieldCreateTimeLabel, onChangeInput, getInputData, false, true,
            "text", [], "CreateTime", menus.fieldCreateTimeError),
        DefaultFormInputFields(menus.fieldCommentLabel, onChangeInput, getInputData, false, true,
            "text", [], "Comment", menus.fieldCommentError),
    ];

    const submitForm = async () => {

        let q: InvitationFieldEntry = JSON.parse(InputFieldQueue)
        console.log("submitForm checkIn > ", q)
        // @ts-ignore
        let id = moment().format("YYYYMMDD|HHmmSS")
        let ref = `${q.Organization}-${q.Agency}-${q.Queue}-${InputFieldChannel}-${InputFieldComment}-${q.Username}-${q.UserFullName}--${id}`;
        ref = ConvertStringToBase64(ref)
        let username: string = ConvertStringToBase64(q.Username)

        let record = {
            Code: ref,
            Organization: q.Organization,
            Agency: q.Agency,
            Queue: q.Queue,
            Channel: InputFieldChannel,
            Comment: InputFieldComment,
            Ref: ref,
            UniqueRef: ref,
            Username: q.Username,
            FullName: q.UserFullName,
        }

        // @ts-ignore
        await SubmitFormToFirebase(record, columns, entityNameFirebase, ref, cleanInputs, menus.submitConfirmMessage)

        await SubmitFormToFirebase(record, columns, `${entityNameFirebase}Current`, username, cleanInputs, menus.submitConfirmMessage)
        setIsEdit(false)
    }
    const prepareData = () => {
        const infoLogin = store.getState();
        let username = infoLogin.auth.user.username;
        let tmpData: any[] = [];
        for (let i in data) {
            let row = data[i];
            let rowToUpload = row;

            let queueInfos: any[] = [];
            let channel = "";
            // @ts-ignore
            if (typeof row.Channel !== "undefined") {
                // @ts-ignore
                channel = row.Channel
            }

            if (channel === "field") {
                queueInfos = dataQueueFields;
            }
            if (channel === "pipeline") {
                queueInfos = dataQueuePipelines;
            }
            // @ts-ignore
            if (rowToUpload.Username !== username) {
                continue
            }

            // @ts-ignore
            let org = findDisplayKeyInArray(row.Organization, dataOrganization, "Code", "Name");

            // @ts-ignore
            let agency = findDisplayKeyInArray(row.Agency, dataAgency, "Code", "Name");
            // @ts-ignore
            let queueName = findDisplayKeyInArray(row.Queue, queueInfos, "Ref", "Name");

            let record = {};
            // @ts-ignore
            record.Comment = row.Comment;
            // @ts-ignore
            record.CreateDate = row.CreateDate;
            // @ts-ignore
            record.CreateTime = row.CreateTime;
            // @ts-ignore
            record.Channel = row.Channel;

            // @ts-ignore
            record.Organization = org;
            // @ts-ignore
            record.Agency = agency
            // @ts-ignore
            record.Queue = queueName
            // @ts-ignore

            tmpData.push(record)
        }
        return tmpData;
    }
    const ListBuilder = () => {
        let dataStore = prepareData();
        let store = {columns: columns, data: dataStore}
        // @ts-ignore
        // @ts-ignore
        return (
            <TableDataView
                {...store}
                tableTitle={"Données  (" + data.length + ") "}
                otherFunctions={[]}
                options={{
                    pageSize: 20,
                }}
                noAddButton={true}
            />
        )
    }
    const localSyncData = () => {
        SyncData(setData, `${entityNameFirebase}Current`, "Ref", cleanInputs, styles.aIcon, true);
        SyncData(setDataOrganization, "CcOrganizations", "Code", cleanInputs, styles.aIcon, true)
        SyncData(setDataAgency, "CcAgency", "Code", cleanInputs, styles.aIcon, true)

        SyncData(setDataInvitationField, "CcQueueFieldInvitations", "Code", cleanInputs, styles.aIcon, false)
        SyncData(setDataInvitationPipelines, "CcQueuePipelineInvitations", "Code", cleanInputs, styles.aIcon, false)
        SyncData(setDataQueueFields, "CcQueueFieldSetting", "Code", cleanInputs, styles.aIcon, false)
        SyncData(setDataQueuePipelines, "CcQueuePipelineSetting", "Code", cleanInputs, styles.aIcon, false)

        setIsSync(true);
    }

    useEffect(() => {
        if (!isSync) {
            localSyncData();
        }
    }, []);

    console.log("dataInvitationPipelines > ",dataInvitationPipelines)


    return (
        <div style={styles.container}>
            <IonLoading message={"please wait.."} duration={0} isOpen={busy}/>
            {IsEdit ?
                <div style={styles.container}>
                    <h1 style={{color: "#3880ff"}}>
                        <u>New Subscription!</u>
                        <a onClick={() => cancelNew(setIsEdit)}
                           style={styles.cancelNewPipeline}
                        >
                            <IonIcon slot="start" ios={closeCircleSharp} md={closeCircleSharp}/>
                        </a>
                    </h1>
                    {buildAddInfo("Date", moment().format("YYYY-MM-DD"), styles.buildAddInfoField, styles.buildAddInfoValue)}
                    {buildAddInfo("Time", moment().format("HH:mm:SS"), styles.buildAddInfoField, styles.buildAddInfoValue)}

                    {buildAddInputSelect(InputFieldChannel, "Channel", "Channel", setInputFieldChannel, "option", true, optionsCategories)}
                    {buildAddInputSelect(InputFieldQueue, "Queue", "Queue", setInputFieldQueue, "option", true, getQueueOption())}
                    {buildAddInput(InputFieldComment, "Comment", "Comment", setInputFieldComment, "text", true)}

                    {buildAddButton("Submit New Queue", "secondary", "#3880ff", submitForm)}
                </div> :
                <>


                    <IonList>
                        <IonLabel style={styles.titleComponent}>
                            Subscription List
                            <a
                                onClick={() => requestEdit(setIsEdit)}
                                style={styles.aIcon}

                            >
                                <IonIcon icon={addCircle} color={"primary"}>ADD NEW</IonIcon>
                            </a>
                        </IonLabel>
                    </IonList>
                    <ListBuilder/>
                </>
            }
        </div>
    )
}


export default WorkerCheckinPage;

const styles = {
    buildAddInfoField: {
        color: "red",
        fontSize: 12,
        marginLeft: 10
    },
    buildAddInfoValue: {
        color: "gray",
        fontSize: 12,
        marginLeft: 10
    },
    titleComponent: {
        color: "#3880ff",
        fontSize: 18,
        fontWeight: "bold",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        marginTop: 20
    },
    requestNewPipeline: {
        float: "right",
        color: "blue",
        fontSize: 19,
        fontWeight: "bold"
    },
    cancelNewPipeline: {
        float: "right",
        color: "red",
        fontSize: 19,
        fontWeight: "bold",
        cursor: "pointer"
    },
    aIcon: {
        fontSize: 24,
        cursor: "pointer"
    },
    container: {
        minWidth: "100%",
        paddingRight: 20,

    }
}




