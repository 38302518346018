import React, { useState } from 'react';
import { IonPopover, IonButton } from '@ionic/react';
import {PropsPopupComment, PropsPopupProcessField, PropsPopupReminder, SelectOption} from "../../mocks/types";
import './my.css'
import {buildAddInputSelect} from "../../services/general-functions";
import { Input } from 'antd';
import CalendarSelector from "../common/CalendarSelector";

const { TextArea } = Input;

const sampleCommentTitle:SelectOption[]=[
    {key:"Busy",val:"Busy"},
    {key:"No answer",val:"No answer"},
    {key:"Network unavailable",val:"Not unavailable"},
    {key:"Out of range",val:"OUt of range"},
    {key:"Not Interested",val:"Not Interested"},
]
export const PopupAgentComment= (props:PropsPopupComment) => {
    const [InputFieldComment,setInputFieldComment]=useState("");
    let {showPopover,onClose,OptionsComment,setComment,event,submitProcess}=props;

    if(typeof OptionsComment==="undefined"){
        OptionsComment = sampleCommentTitle
    }
    if(OptionsComment.length===0){
        OptionsComment = sampleCommentTitle
    }

    const setCommentInput=(value:string)=>{
        setInputFieldComment(value);
        // @ts-ignore
        setComment(value)
    }
    const onSubmit=async ()=>{
        if(InputFieldComment=="")return alert("Sorry comment can't be empty!");
        submitProcess()
    }

    return (
        <>
            <IonPopover
                cssClass='popoverProcessField'
                event={event}
                isOpen={showPopover}
                onDidDismiss={() => {
                    //setShowPopover({ showPopover: false, event: undefined })
                    onClose()
                }}
                mode={"md"}
            >
                <div style={styles.container}>
                    <p style={{textAlign:"center"}}>Saisir toute autre note ici!</p>
                    {/*{buildAddInputSelect(InputFieldComment, "Note", "Make Note", setCommentInput,
                        "option", true, OptionsComment)}*/}
                    <TextArea
                        rows={4}
                        showCount
                        maxLength={250}
                        onChange={(e)=>setCommentInput(e.target.value)}
                    />

                    <IonButton title={"CLose"} onClick={()=>onSubmit()} style={styles.btn} color={"secondary"}>Soumettre</IonButton>
                </div>

            </IonPopover>

        </>
    );
};

const styles={
    btn:{
        minWidth:"100%",
        borderRadius:10,
        borderWidth:10,
        backgroundColor:"red"
    },
    container:{
        minWidth:"90%",
        /*backgroundColor:"green",*/
        padding:10,
    }
}