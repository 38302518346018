

import React from "react";
import {IonButton, IonIcon, IonInput} from "@ionic/react";
import {logIn} from "ionicons/icons";


export default (props:any)=>{
    let {name,textColor,backgroundColor,btnOnClick,borderRadius,expand,icon,iconSlot}=props;

    return(

            <IonButton
                style={{
                    backgroundColor:backgroundColor,
                    minWidth:"100%",
                    borderWidth:5,
                    borderRadius:borderRadius,
                    color:"whtie",
                    fontWeight:"bold"
                }}
                color={textColor}
                onClick={btnOnClick}
                expand={expand}
            >
                {icon?
                    <IonIcon icon={icon} slot={iconSlot}/>:null}
                {name}
            </IonButton>

    )
}

const styles={
    containerFormBtn: {
        display: "flex",
        justifyContent: "center",
        backgroundColor: "black",
        /*minWidth:"100%"*/
    },
    wrapInput: {
        backgroundColor: "rgba(255, 255, 255, .8)",
        borderRadius: 20,
        marginBottom: 26,
        boxShadow: "0 10px 30px 0px rgba(57, 71, 82, 0.12)"
    },
};
