import {removeEntityDatabase, toast} from "./firebase-functions";
import {propsColumns, SelectOption} from "../mocks/types";

export const DefaultFormInputFields = (label: string, onChangeInput: any, getInputData: any, required: boolean, isNotFormEntry: boolean,
                                       dataType: string, options: SelectOption[], id: string, errorMsg: string) => {
    let data: propsColumns = {
        dataType,
        id,
        label,
        getValue: getInputData,
        onChange: onChangeInput,
        isNotFormEntry,
        options,
        required,
        errorMsg
    }
    return data;
}
export const DeleteData = async (row: any, entityNameFirebase: string, cleanInputs: any) => {
    if (!window.confirm("Are you sure to delete this Site: " + row.Name + "?")) {
        return
    }
    await removeEntityDatabase(entityNameFirebase, row.Name)
    await toast('Organization deleted  successfully!');
    cleanInputs();
}
export const GetInputData = (key: string, inputData: object) => {
    // @ts-ignore
    let tmp = inputData[key]
    if (typeof tmp === "undefined") {
        return ""
    }
    return tmp
}

export const OnChangeInput = (value: any, key: string, inputData: object, setInputData: any) => {
    let tmp: object = inputData;
    // @ts-ignore
    tmp[key] = value
    setInputData(tmp)
}
