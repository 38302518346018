import {ConvertStringToBase64, escapeEmailAddress} from "./general-functions";
import firebase from "../configures/firebase-init";
import {AttendantEntry, WorkerCheckinEntry, WorkerHandleDataEntry} from "../../functions/src/services/types";
import moment from "moment";
import {QueueInvitation} from "../mocks/types";


/*const findYourCheckinInfo = async (username: string, fn: any) => {
    let usernameRef = escapeEmailAddress(username);
    await firebase.database().ref(`CcWorkerCheckinCurrent`).once('value', function (snapshot: any) {
        let innerRecord = snapshot.val();
        console.log("loadHandleData Y --> ", innerRecord)
        if (innerRecord !== null) {
            let myKey = ConvertStringToBase64(username)
            let rec = innerRecord[myKey] as WorkerCheckinEntry
            if (typeof rec !== "undefined") {
                /!**
                 * let arrange our agency
                 *!/
                let arrCode= rec.Queue.split("-");
                if(arrCode.length>=2){
                    rec.Agency = arrCode[1]
                }
                console.log("let arrange our agency > ",rec)

                fn(rec)
            }
        } else {
            fn(null)
        }
    })
}*/

export const RequestQueuePipelineData = async (invite: QueueInvitation, fn: any) => {
    let checkinObj: QueueInvitation = invite;
    /**
     * let request fetch available data
     */
    let tableQueueQuery = "CcQueuePipelineData";
    let refLead = await firebase.database().ref(`${tableQueueQuery}/${checkinObj.Queue}`).limitToFirst(1).get();
    let keyExist = refLead.exists()
    if (!keyExist) {
        return null
    }
    let info = refLead.val();
    let dataRecord = info[Object.keys(info)[0]];
    let innerRef = Object.keys(info)[0];

    let infoIn: WorkerHandleDataEntry = {
        Channel: checkinObj.Category,
        CreatedDate: moment().format("YYYY-MM-DD"),
        CreateTime: moment().format("HH:mm:SS"),
        Data: dataRecord,
        ExtRef: innerRef,
        InRef: innerRef,
        QueueRef: checkinObj.Queue,
        Username: checkinObj.Username,
    }

    /**
     * let save our request record as used handle
     */
    await saveRecordWorkerHandleData(infoIn);

    /**
     * let remove now this request from queue
     */
    await removeFromQueuePipeline(checkinObj.Queue, innerRef);

    /**
     * let make our history fetching
     */
    await makeRequestQueuePipelineHistoryStore(infoIn)

    /**
     * let make entry request history into realtime
     */
    await makeRequestQueuePipelineHistoryRealtime(infoIn)
    /**
     * let make entry request history into realtime daily requested
     */
    await makeRequestQueuePipelineDailyRealtime(infoIn)
    /**
     * let notify our queue that the user take a lead
     */
    await makeRequestQueuePipelineTakenRealtime(infoIn)


    console.log("HHHHHH&--> ", refLead);
    console.log("RequestQueuePipelineData F!", 4, refLead.val())

    fn(refLead)

    return refLead
}
export const makeRequestQueuePipelineDailyRealtime = async (infoIn: WorkerHandleDataEntry) => {
    let entity = "CcWorkerHandlePipelineDataRequestedDaily";
    let username = escapeEmailAddress(infoIn.Username);
    let date = moment().format("YYYY-MM-DD");
    let tot = 1;
    let path = `${entity}/${username}/${date}`;
    const res = await firebase.database().ref(path);
    //todo increment
    res.once('value', function (dashboardSnapshot) {
        if (dashboardSnapshot.val() !== null) {
            let record = dashboardSnapshot.val();
            // @ts-ignore
            res.update({
                ...record,
                Total: record.Total + 1,
                LastUpdatedTime: moment().format("HH:mm:SS")
            });
        } else {
            res.set({
                Username: infoIn.Username,
                Total: tot,
                Date: date,
                LastUpdatedTime: moment().format("HH:mm:SS"),
            })
        }
    });
}
export const makeRequestQueuePipelineHistoryRealtime = async (infoIn: WorkerHandleDataEntry) => {
    let entity = "CcWorkerHandlePipelineDataRequested";
    let username = escapeEmailAddress(infoIn.Username);
    let queueRef = infoIn.QueueRef;
    let path = `${entity}/${username}/${queueRef}`;
    const res = await firebase.database().ref(path);
    res.once('value', function (dashboardSnapshot) {
        console.log(" makeRequestQueuePipelineHistoryRealtime ZZZZZZZ---> dashboardSnapshot:  ", dashboardSnapshot.val())
        if (dashboardSnapshot.val() !== null) {
            let record = dashboardSnapshot.val();
            // @ts-ignore
            res.update({
                ...record,
                Total: record.Total + 1,
                LastUpdatedDate: moment().format("YYYY-MM-DD"),
                LastUpdatedTime: moment().format("HH:mm:SS"),
            });
        } else {
            res.set({
                Username: infoIn.Username,
                QueueRef: infoIn.QueueRef,
                Total: 1,
                LastUpdatedDate: moment().format("YYYY-MM-DD"),
                LastUpdatedTime: moment().format("HH:mm:SS"),
            })
        }
    });
}
export const makeRequestQueuePipelineTakenRealtime = async (infoIn: WorkerHandleDataEntry) => {
    let entity = "CcQueuePipelineSetting";
    let queueRef = infoIn.QueueRef;
    let path = `${entity}/${queueRef}`;
    const res = await firebase.database().ref(path);
    res.once('value', async function (dashboardSnapshot) {
        let record = dashboardSnapshot.val();

        if (record !== null) {

            // @ts-ignore
            let newRecord = {
                ...record,
                Taken: parseInt(record.Taken) + 1,
                LastUpdatedTakenDate: moment().format("YYYY-MM-DD"),
                LastUpdatedTakenTime: moment().format("HH:mm:SS"),
                LastUpdatedUser: infoIn.Username
            };
            await firebase.database().ref(entity).child(queueRef).update(newRecord)

            console.log("zzzzx---> ", record, " > ", newRecord)
        }
    });
}
//
export const makeRequestQueuePipelineHistoryStore = async (infoIn: WorkerHandleDataEntry) => {
    await firebase.firestore()
        .collection("CcWorkerHandlePipelineData")
        .add(infoIn)
}
const removeFromQueuePipeline = async (queueRef: string, innerRef: string) => {
    await firebase.database().ref(`CcQueuePipelineData/${queueRef}/${innerRef}`).remove();
}
const saveRecordWorkerHandleData = async (infoIn: WorkerHandleDataEntry) => {
    let usernameRef = escapeEmailAddress(infoIn.Username);
    let entity = "CcWorkerHandlePipelineData";

    console.log("CcWorkerHandlePipelineData infoIn: ZZZZZZZ---> ", infoIn);

    await firebase.database()
        .ref(entity)
        .child(usernameRef)
        .set(infoIn).then((snpashot) => {
            console.log("CcWorkerHandlePipelineData Saved data: ZZZZZZZ---> ", snpashot);
            return {snpashot, error: null}
        }).catch((err) => {
            console.log("CcWorkerHandlePipelineData Error saving: ZZZZZZZ---> ", err)
            return {snpashot: null, error: err}
        })

}