import React from "react";
import {
    IonAlert,
    IonButton,
    IonContent, IonIcon, IonItem, IonLabel,
    IonList,
    IonPage,
} from '@ionic/react';
import {add, addCircle, closeCircle, send, trashBinOutline,saveSharp} from "ionicons/icons";
import TextInput from "../ControllerInput/TextInput";
import InputButton from "../ControllerInput/InputButton";
import InputSelect from "../ControllerInput/InputSelect";
import TableDataView from "./TableDataView";

type propsList = {
    titleList: string
    titleForm: string,
    title: string,
    showList: boolean,
    columns: propsColumns[],
    submitForm: any,
    changeShowList: any,
    data: any[],
    btnSubmitText:string
}
type propsColumns = {

    id: string,
    label: string,
    dataType: string,
    options: [],
    onChange: any,
    errorMsg: string,
    getValue: any
    required:boolean,
    isNotFormEntry:boolean,
}


const ListBuilder = (props: propsList) => {
    let {data, columns, titleList, titleForm, title} = props;
    let store = {columns: columns, data: data}
    return (
        <TableDataView
            {...store}
            tableTitle={"Données  (" + data.length + ") "}
            otherFunctions={[]}
            options={{
                pageSize: 20,
            }}
            noAddButton={true}
        />
    )
}
const FormBuilder = (props: propsList) => {
    let {columns, title,submitForm,btnSubmitText} = props;

    return (
        <div style={styles.form}>
            {
                columns.map(row => {
                    if(row.isNotFormEntry){
                        return null;
                    }
                    if (row.dataType === "text") {
                        return (
                            <TextInput
                                name={row.id}
                                placeholder={ row.label}
                                setValue={row.onChange}
                                typeInput={row.dataType}
                                required={row.required}
                            />
                        )
                    } else if (row.dataType === "option") {
                        return (
                            <InputSelect
                                name={row.id}
                                label={ row.label}
                                value={row.getValue()}
                                options={row.options}
                                onChange={row.onChange}
                                typeInput={row.dataType}
                                required={row.required}
                            />
                        )
                    }

                    //default control
                    return (
                        <TextInput
                            name={row.id}
                            placeholder={ row.label}
                            setValue={row.onChange}
                            typeInput={"text"}
                        />
                    )
                })
            }

            <InputButton
                name={btnSubmitText}
                textColor={"secondary"}
                backgroundColor={"#3880ff"}
                btnOnClick={submitForm}
                borderRadius={25}
                expand={"full"}
                icon={saveSharp}
                iconSlot={"start"}
            />
        </div>
    )


}
const FormBuilders: (props: propsList) => (JSX.Element) = (props: propsList) => {
    let {titleList, title, titleForm, showList, columns, submitForm, changeShowList, data,btnSubmitText} = props;
    if (showList) {
        return (
            <IonList>
                <IonLabel style={styles.titleComponent}>
                    {titleList}
                    <a
                        onClick={() => changeShowList(false)}
                        style={styles.aIcon}

                    >
                        <IonIcon icon={addCircle} color={"primary"}>ADD NEW</IonIcon>
                    </a>
                </IonLabel>
                <ListBuilder
                    data={data}
                    columns={columns}
                    changeShowList={changeShowList}
                    showList
                    submitForm={submitForm}
                    titleForm={titleForm}
                    title={title}
                    titleList={titleList}
                    btnSubmitText={btnSubmitText}
                />
            </IonList>
        )
    }
    // @ts-ignore
    return (
        <IonList>
            <IonLabel style={styles.titleComponent}>
                {titleForm}
                <a
                    onClick={() => changeShowList(true)}
                    style={styles.aIcon}
                >
                    <IonIcon icon={closeCircle} color={"danger"}></IonIcon>
                </a>
            </IonLabel>
            <FormBuilder
                changeShowList={changeShowList}
                showList={showList}
                columns={columns}
                title={title}
                titleList={titleList}
                titleForm={titleForm}
                submitForm={submitForm}
                data={data}
                btnSubmitText={btnSubmitText}
            />
        </IonList>
    )
}

export default FormBuilders;


const styles = {
    form:{
      minWidth:"98%",
        maxWidth:"98%"
    },
    biIcon: {
        marginRight: 20,
        color: "red",
        cursor: "pointer",
    },
    aIcon: {
        marginLeft: 50,
        fontSize: 40,
        cursor: "pointer"
    },
    titleComponent: {
        color: "#3880ff",
        fontSize: 18,
        fontWeight: "bold",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        marginTop: 20
    }
}


