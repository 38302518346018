// const agency="SDY";
// const org="Swaly";
// const queueRef="BIACIBENGA01TEST"
import firebase from "../configures/firebase-init";
import {AttendantEntry, AttendantOriginEntry} from "../mocks/types";
import {fetchRecords, setEntityDatabaseKey2, updateEntityDatabase} from "./general-functions";

export const IncludeHistoryQueueField = async (queueRef: string, sourceOrg: string, sourceAgency: string) => {
    type shortQueueEntry={
        OrganizationFA:string,
        AgencyFA:string,
        Ref:string,
        Total:number
    }

    let dataProvider:AttendantOriginEntry[]=[];
    let table = await firebase.database()
        .ref("Attendances")
        .orderByChild("Organization").equalTo(sourceOrg)
        .once("value",snapshot => {
            const infos = snapshot.val()
            if(infos!==null){
                for(let code in infos){
                    let row:AttendantOriginEntry = infos[code] as AttendantOriginEntry;
                    if(row.Agence === sourceAgency){
                        dataProvider.push(row)
                    }
                }
            }
        }).then(r=>{});


    /**
     * let find our target queue settings
     */

    for(let i in dataProvider){
        let entry = dataProvider[i];
        /**
         * let update now our summary
         */
        await updateEntityDatabase("CcQueueFieldSetting", queueRef, {Total :dataProvider.length} )
        /**
         * let now saving information on our queue data
         */
        await setEntityDatabaseKey2("CcQueueFieldData",queueRef,entry.Code,entry)
    }

    console.log("IncludeHistoryQueueField > ", dataProvider)

}