import {
    SET_PROFILE,
    SET_USER_STATE,
    SET_SYNC_DATA,
    SET_AUTH_ENTITY_DATA,
    SET_LOAD_COUNTRY,
    SET_SIM_TARGET, SET_DASHBOARD_DATA, SET_LANGUAGE_STATE
} from "../constants/constant-redux";

const defaultState={
    user:{username:""},
    profile:{
        hasProfile:false,
        data:null,
        key:"",
    },
    isSync:false,
    groups:null,
    agents:null,
    SimTargets:null,
    stocks:null,
    profiles:null,
    countries:null,
    dashboard:{
        agentTargetDaily:0,
        agentTargetMonthly:0,
        agentActualDaily:0,
        agentActualMonthly:0,
        syncData:false,
        agentId:"",
        agentGroup:"",
    },
    global:{
        language:"en",
    },
}

// @ts-ignore
export default function rootReducer(     state=defaultState ,     {type, payload}: {type: string, payload: any}):any{
    let newState = state;
    switch (type) {
        case SET_LANGUAGE_STATE:
            newState.global.language = payload;
            return Object.assign({}, newState);
        case SET_AUTH_ENTITY_DATA:
            const entity = payload.entity;
            const data = payload.data;
            // @ts-ignore
            newState[entity] = data;
            return Object.assign({}, newState);
        case SET_DASHBOARD_DATA:
            for(let i in payload){
                const keyState = i;
                const valState =payload[i];
                // @ts-ignore
                newState.dashboard[keyState]=valState;
            }

            return Object.assign({}, newState);
        case SET_USER_STATE:
            newState.user.username = payload;
            return Object.assign({}, newState);
        case SET_PROFILE:
            newState.profile.hasProfile = payload.hasProfile;
            newState.profile.data = payload.data;
            newState.profile.key = payload.key;
            return Object.assign({}, newState);
        case SET_SYNC_DATA:
            newState.user.username = payload;
            return Object.assign({}, newState);
        case SET_LOAD_COUNTRY:
            newState.countries = payload;
            return Object.assign({}, newState);
        case SET_SIM_TARGET:
            newState.SimTargets = payload;
            return Object.assign({}, newState);
    }
    return state
}
